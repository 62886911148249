import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiFillLike } from 'react-icons/ai';
import { BiLike } from 'react-icons/bi';

import api from '../../../../../../../../services/api';

const LikeButton = ({
  idComment,
  liked,
  like_id,
  setShowLoadModal,
  setShowNoAccessModal,
  handleError,
  loadListComment,
}) => {
  const user = useSelector((state) => state.User);
  const [likeId, setLikeId] = useState(like_id);
  const [commentIsLiked, setCommentIsLiked] = useState(liked);

  const likeComment = async () => {
    if (!user.accessToken || !user.id) {
      setShowNoAccessModal(true);
      return;
    }

    setShowLoadModal(true);
    await api
      .post('/likes', {
        entity_id: idComment,
      })
      .then((response) => {
        setLikeId(response.data.id);
        setShowLoadModal(false);
        setCommentIsLiked(true);
        loadListComment(true, false);
      })
      .catch((error) => handleError(error));
  };

  const dislikeComment = async () => {
    setShowLoadModal(true);

    await api
      .delete(`/dislikes/${likeId}`)
      .then((response) => {
        setShowLoadModal(false);
        setCommentIsLiked(false);
        loadListComment(true, false);
      })
      .catch((error) => handleError(error));
  };

  const handleClick = () => {
    if (commentIsLiked) {
      dislikeComment();
    } else {
      likeComment();
    }
  };

  const renderText = () => {
    if (commentIsLiked) {
      return <AiFillLike size={14} />;
    }
    return <BiLike size={14} />;
  };

  return <span onClick={() => handleClick()}>{renderText()}</span>;
};

export default LikeButton;
