import styled from 'styled-components';


export const Container = styled.header`
  width: 100%;
  height: 5.4075rem;

  position: fixed;
  top: 0;
  z-index: 9999;

  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  .header__wrapper{
    width: 98%;
    max-width: 75.75rem;
    height: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.3rem;
  }

  .header__logo-wrapper{
    width: 100%;
    max-width: 14.6875rem;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__logo{
    width: 9.75rem;
    height: 1.529375rem;
  }

  .header__user-wrapper{
    width: 100%;
    max-width: 14.875rem;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__user-anchor{
    width: 9.0625rem;
    height: 2.25rem;

    display: block;

    background-color: var(--purpleA1);
    border-radius: 1.125rem;
    text-decoration: none;
    transition: filter 0.3s ease;

    &:hover{
      filter: brightness(1.2);
    }
  }

  .header__user-anchor-text{
    height: 100%;

    font-size: 0.8125rem;
    line-height: 2.25rem;
    text-align: center;
    color: #fff;
  }

  .header__user-image{
    width: 3rem;
    height: 3rem;

    border-radius: 50%;
    border: 2px solid var(--dark06);
    overflow: hidden;
  }
`;
