import React, { useState, useRef } from 'react';

import { BsEye, BsEyeSlash } from 'react-icons/bs';

import { Container, FieldInput, IconLeft, IconRight } from './styles';

const Input = ({
  iconLeft,
  iconRight,
  error = false,
  showEye = false,
  showPassword = false,
  id = '',
  setShowPassword = () => {},
  onClickIconRight = () => {},
  ...props
}) => {
  const inputRef = useRef();

  function renderIconLeft() {
    if (iconLeft) {
      return <IconLeft src={iconLeft} />;
    }
  }

  function renderIconRight() {
    if (iconRight) {
      return <IconRight onClick={onClickIconRight} src={iconRight} />;
    }
  }

  function renderShowEye() {
    if (showEye) {
      if (showPassword) {
        return (
          <div onClick={() => setShowPassword(false)}>
            <BsEye size={20} />
          </div>
        );
      }

      return (
        <div onClick={() => setShowPassword(true)}>
          <BsEyeSlash size={20} />
        </div>
      );
    }
  }

  return (
    <Container className="input" error={error}>
      {renderIconLeft()}
      <FieldInput id={id} ref={inputRef} error={error} {...props} />
      {renderIconRight()}
      {renderShowEye()}
    </Container>
  );
};

export default Input;
