import styled from 'styled-components';

export const Container = styled.div`
  .ant-select-selector {
    font-size: 18px !important;
    color: #007e00;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 28px !important;
    width: 200px !important;
  }
`;
