import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: rendering 0.2s;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 10px;
  text-align: center;

  label {
    font-family: 'Roboto-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    margin-left: 20px;
  }

  @media (max-width: 1080px) {
    width: 96%;
    padding: 16px;
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: #007e00;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  width: 920px;

  @media (max-width: 1080px) {
    max-width: 100%;
  }
`;

export const Text = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 1.6rem;
  color: #202125;
  padding: 20px;
  margin: 40px 0px;
  width: 920px;
  max-height: 360px;
  overflow: auto;
  text-align: left;
  white-space: break-spaces;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8430ca;
    border-radius: 20px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: 20px;
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #48e25b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 20px auto;
`;
