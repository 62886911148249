import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  text-align: center;

  > img {
    margin-top: 20px;
    cursor: pointer;
  }

  @media (max-width: 1140px) {
    padding: 20px;
    width: 94%;
    max-height: 94vh;
    height: fit-content;
    overflow: auto;
  }
`;

export const Row = styled.div`
  display: flex;

  @media (max-width: 1140px) {
    flex-direction: column;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  width: 500px;
  text-align: left;

  > div:nth-of-type(1) {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 1.6rem;
    color: #5c5c5c;
    margin-top: 20px;
  }

  @media (max-width: 1140px) {
    text-align: center;
  }

  @media (max-width: 620px) {
    width: 100%;
  }
`;

export const Disclaimer = styled.div`
  font-family: 'Aller-Light';
  font-size: 1.4rem;
  color: #5c5c5c;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
`;

export const Form = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > button:nth-of-type(1) {
    font-size: 1.8rem;
  }

  @media (max-width: 620px) {
    width: 100%;

    > div {
      width: 100%;
    }

    > button:nth-of-type(1) {
      width: 100%;
    }
  }
`;

export const RowShareButton = styled.div`
  display: flex;
  gap: 15px;

  > div:nth-of-type(1) {
    width: 260px;
  }

  button {
    font-size: 1.2rem;
    width: 160px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
      }
    }
  }

  @media (max-width: 620px) {
    justify-content: center;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const DescriptionAfterTheCodeHasBeenGenerated = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.4rem;
  color: #165011;
  margin-top: 10px;

  button {
    margin: 20px auto -20px auto;
    height: 40px;
    width: 300px;
    font-size: 1.2rem;
  }
`;
