import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import PlanCard from '../../components/PlanCard';
import AddCreditCard from './components/AddCreditCard';
import AddCardModal from './components/AddCardModal';
import GenerateCodeModal from './components/GenerateCodeModal';
import MessageModal from '../../components/MessageModal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import LoadModal from '../../components/LoadModal';
import Footer from '../../components/Footer';

import gift from '../../assets/giftFriend/gift.png';

import {
  Container,
  PlanDescription,
  PlanContainer,
  Form,
  InputRow,
  Title,
  GradientBackground,
  ChoosePaymentMethod,
} from './styles';

const GiftFriendPayment = () => {
  const history = useHistory();

  const [plan, setPlan] = useState({ id: '', name: '', price: 0 });
  const [plans, setPlans] = useState([]);
  const [showModalPlanMessage, setShowModalPlanMessage] = useState(false);
  const [showFormDataCreditCard, setShowFormDataCreditCard] = useState(false);
  const [showModalSuccessPayment, setShowModalSuccessPayment] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [userHasPaymentOptions, setUserHasPaymentOptions] = useState(false);
  const [paymentOptionChosen, setPaymentOptionChosen] = useState('');
  const [informations, setInformations] = useState({
    number: '',
    name: '',
    duedate: '',
  });
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showGenerateCodeModal, setShowGenerateCodeModal] = useState(false);
  const [userPlan, setUserPlan] = useState({});
  const [creditCardToken, setCreditCardToken] = useState('');

  const fetchUserPlan = async () => {
    try {
      const response = await api.get(`/users/plans`);
      setUserPlan(response.data);
    } catch (error) {}
    setShowLoadModal(false);
  };

  async function loadPlanSelected() {
    const planID = localStorage.getItem('YogaCO@ChoosedPlan');
    if (planID === null) {
      history.push('/presenteie-um-amigo');
      return;
    }

    setShowLoadModal(true);

    try {
      const response = await api.get(`/plan-types?isVisible=true`);
      if (response) {
        setPlans(response.data);

        let foundPlan = false;
        response.data.map((plan) => {
          if (plan.id === planID) {
            foundPlan = true;
            setPlan(plan);
            if (plan.name === 'Plano Anual') {
              setUserHasPaymentOptions(true);
            }
          }
        });

        if (!foundPlan) {
          history.push('/presenteie-um-amigo');
        }
      }
    } catch (error) {}

    setShowLoadModal(false);
  }

  function renderMethodPayment() {}

  function renderModalPlanMessage() {
    if (showModalPlanMessage) {
      if (plan.name === 'Plano Mensal') {
        return (
          <MessageModal
            title="Você escolheu o pagamento Mensal."
            description="Você escolheu o Plano Mensal,  este plano é recorrente,
        caso escolha pagar com cartão de crédito, esse pagamento
        será cobrado automaticamente todo mês.

        Mas não se preocupe, você poderá cancelar sua
        assinatura a qualquer momento!"
            showOkButton={true}
            showVisible={showModalPlanMessage}
            setVisible={setShowModalPlanMessage}
          />
        );
      }

      return (
        <MessageModal
          title="Você escolheu o pagamento Anual a vista."
          description="Esta cobrança será anual, ou seja,
      nesta mesma data porém no ano que vem você receberá
      novamente  a cobrança de R$ 299,90 para continuar
      aproveitando nosso conteúdo da Yoga Co.

      Mas não se preocupe, você poderá cancelar sua
      assinatura a qualquer momento!"
          showOkButton={true}
          showVisible={showModalPlanMessage}
          setVisible={setShowModalPlanMessage}
        />
      );
    }
  }

  function handleNumberCreditCard(event) {
    const number = event.target.value;
    if (number.length <= 16) {
      setInformations({
        ...informations,
        number,
      });
    }
  }

  function renderModalSuccessPayment() {
    if (showModalSuccessPayment) {
      return (
        <MessageModal
          type="success"
          iconColor="green"
          title="Prontinho!"
          subtitle="Seu pagamento foi aprovado"
          setVisible={setShowModalSuccessPayment}
        />
      );
    }
  }

  const renderAddCardModal = () => {
    if (showAddCardModal) {
      return (
        <AddCardModal
          setVisible={setShowAddCardModal}
          setShowLoadModal={setShowLoadModal}
          userPlan={userPlan}
          setShowGenerateCodeModal={setShowGenerateCodeModal}
          setCreditCardToken={setCreditCardToken}
        />
      );
    }
  };

  const renderGenerateCodeModal = () => {
    if (showGenerateCodeModal) {
      return (
        <GenerateCodeModal
          setVisible={setShowGenerateCodeModal}
          creditCardToken={creditCardToken}
          setShowLoadModal={setShowLoadModal}
        />
      );
    }
  };

  useEffect(() => {
    loadPlanSelected();
    fetchUserPlan();

    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.async = true;

    document.body.appendChild(script);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GradientBackground />
      <Header />
      <Content>
        <div>
          <Title>
            <img src={gift} alt="Ícone de um presente" />
            <div>
              <span>Presenteie</span>
              <span>UM AMIGO</span>
            </div>
          </Title>
          {renderMethodPayment()}
          {/* <ChoosePaymentMethod>
            <p>Escolha uma forma de pagamento</p>
            <Button>Usar cartão já cadastrado</Button>
          </ChoosePaymentMethod> */}
        </div>
        <AddCreditCard
          plan={plan}
          userHasPaymentOptions={userHasPaymentOptions}
          paymentOptionChosen={paymentOptionChosen}
          setShowModalSuccessPayment={setShowModalSuccessPayment}
          setShowLoadModal={setShowLoadModal}
          setShowFormDataCreditCard={setShowFormDataCreditCard}
          informations={informations}
          setShowAddCardModal={setShowAddCardModal}
          setShowGenerateCodeModal={setShowGenerateCodeModal}
        />
      </Content>
      <Footer />
      {renderModalPlanMessage()}
      {renderModalSuccessPayment()}
      {renderAddCardModal()}
      {renderGenerateCodeModal()}
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default GiftFriendPayment;
