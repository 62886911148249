import styled from 'styled-components';

export const Container = styled.div`
  width: 11rem;
  height: 11rem;
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: #fff;
  border-radius: 50%;
  outline: 0.73625rem solid var(--green0F);
  border: 2px solid var(--green44);
  background-color: var(--green0F);
  filter: drop-shadow(10px 10px 10px rgba(18, 56, 2, 0.24));
  transform: rotate(-10deg);

  .monthly-plan-tag__text{
    margin-top: -1rem;
    font-family: "Aller-Bold";
    font-size: 1.125rem;
  }

  .monthly-plan-tag__b{
    font-family: "Aller-Light";
    font-size: 0.6875rem;
  }
`;
