import Content from '../../../../components/Content';
import Card from './components/Card';

import { Container, ListCards } from './styles';

const MyNotes = ({ myNotes, myNotesSearched }) => {
  const renderNotes = () => {
    if (myNotesSearched.length > 0) {
      return myNotesSearched.map((note) => <Card note={note} />);
    }
    return myNotes.map((note) => <Card note={note} />);
  };

  return (
    <Container>
      <Content>
        <ListCards>{renderNotes()}</ListCards>
      </Content>
    </Container>
  );
};

export default MyNotes;
