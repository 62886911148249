import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 4.83125rem 0 4.9625rem;

  background: rgb(132,48,202);
  background: linear-gradient(0deg, rgba(132,48,202,0.3113620448179272) 0%, rgba(234,246,234,0.09007352941176472) 32%, rgba(255,252,252,0.028448879551820738) 67%, rgba(255,255,255,0) 100%);

  .recorded-content__title{
    width: 90%;
    max-width: 57.5625rem;
    margin: 0 auto;

    text-align: center;
    text-transform: uppercase;
    font-family: "Aller-Light";
    font-size: 1.875rem;
  }

  .recorded-content__title-b{
    color: var(--green24);
    text-transform: lowercase;
    font-family: "Aller-Regular";
    font-size: 4.8125rem;
  }

  .recorded-content__slider{
    width: 100%;
    height: fit-content;
    margin: 3.83125rem auto 0;

    position: relative;
  }

  .recorded-content__slider-item{
    width: 27.47625rem;
    margin-bottom: 0.5rem;


    border: 0;
    outline: none;

    aspect-ratio: 560 / 315;
    transform: scale(0.75);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .slick-current{
    .recorded-content__slider-item{
      transform: scale(1);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    }
  }

  .slick-arrow{
    width: 1.078125rem;

    position: absolute;
    z-index: 10;

    &:before{
      display: none;
    }
  }

  .slick-prev{
    left: 5.2875rem;
  }

  .slick-next{
    right: 5.2875rem;
  }

  .recorded-content__anchor{
    width: 90%;
    max-width: 31.518125rem;
    height: 4.75rem;
    margin: 3.70625rem auto 0;

    display: block;

    background-color: var(--purpleA1);
    text-decoration: none;
    text-align: center;
    font-size: 1.8125rem;
    line-height: 4.75rem;
    border-radius: 0.5rem;
    transition: filter 0.3s ease;

    &:hover{
      filter: brightness(1.2);
    }
  }

  .recorded-content__anchor-text{
    color: #fff;
  }
`;
