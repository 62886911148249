import styled from "styled-components";

export const Container = styled.article`
  width: 37.5rem;
  height: fit-content;
  margin: 0 auto;
  padding: 2.65625rem 1.5625rem 1.75rem;

  flex: 63;

  border-radius: 1.25rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  background-color: #fff;

  .yoga-calendar__article-calendar__table{
    width: 95%;
    height: 13.543125rem;
    margin: 0 auto;
  }

  .yoga-calendar__article-calendar-header{
    display: flex;
    align-items: center;
    gap: 1.75rem;
  }

  .yoga-calendar__article-calendar-header__tab{
    width: 1.438125rem;
    height: 1.27875rem;
  }

  .yoga-calendar__article-calendar-header__date{
    font-family: "Aller-Light";
    font-size: 1.1875rem;
  }

  .yoga-calendar__article-calendar-header__year{
    font-family: "Aller-Bold";
  }

  .yoga-calendar__article-calendar__tbody{
    text-align: center;

    td {
      position: relative;
    }
  }
  .yoga-calendar__article-calendar__td-bullet--green:before,
  .yoga-calendar__article-calendar__td-bullet--yellow:before{
    content: '•';

    position: absolute;
    bottom: -0.4rem;
    left: calc(50% - 0.25rem);

    font-size: 1.7rem;
  }

  .yoga-calendar__article-calendar__td-bullet--green:before{
    color: var(--green4F);
  }

  .yoga-calendar__article-calendar__td-bullet--yellow:before{
    color: var(--yellowFF);
  }

  .yoga-calendar__article-calendar__td--selected{
    position: relative;
  }

  .yoga-calendar__article-calendar__td-container--selected{
      width: 3.816875rem;
      height: 3.816875rem;

      display: block;

      position: absolute;
      bottom: -0.5rem;
      left: calc(50% - 1.90625rem);

      color: #fff;
      background-color: var(--green4F);
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0,0,0,0.30);

      > p{
        width: 100%;
        height: 100%;

        position: relative;

        line-height: 3rem;
        font-family: "Aller-Bold";

        &:before{
          content: "Ter";

          position: absolute;
          bottom: -0.6rem;

          font-family: "Aller-Regular";
          font-size: 0.8rem;
        }
      }
    }

  .yoga-calendar__article-calendar__image{
    margin-top: 0.875rem;
  }

  .yoga-calendar__article-calendar__info{
    width: 80%;
    margin: 1.275rem auto 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .yoga-calendar__article-calendar__info-day{
    color: var(--greyBF);
    font-size: 1rem;

    &:before{
      content: '•';
      margin-right: 2.0875rem;

      color: var(--green4F);
      font-size: 1.6rem;

      vertical-align: middle;
    }
  }

  .yoga-calendar__article-calendar__info-hour{
    width: 4.3925rem;
    height: 2.636875rem;

    text-align: center;
    line-height: 2.636875rem;
    border-radius: 0.5625rem;
    color: #fff;
    background-color: var(--green4F);
  }
`
