import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 10px;
  position: relative;
  height: 180px;
  width: 320px;

  > div:nth-of-type(1) {
    position: absolute;
    margin-top: -10px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 375px) {
    margin: 10px 10px;
  }

  @media (max-width: 374px) {
    height: 140px;
    width: 250px;
  }
`;

export const Thumbnail = styled.img`
  height: 180px;
  width: 320px;
  cursor: pointer;
  border-radius: 20px;
  opacity: 0;
  transition: all 1s;
  max-width: 320px;
  object-fit: cover;

  @media (max-width: 374px) {
    height: 140px;
    width: 250px;
  }
`;

export const Informations = styled.div`
  //max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const ClassAttendedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.assistedClass ? '#19b441' : '#fff')};
  border-style: solid;
  border-width: 2px;
  border-color: #19b441;
  transition: 0.2s all;

  @media (max-width: 1024px) {
    height: 33px;
    width: 33px;
    margin-right: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  cursor: pointer;

  > div {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #165011;
    margin-left: 10px;
  }

  @media (max-width: 1024px) {
    > div {
      margin: 10px auto;
      text-align: center;
    }
  }
`;

export const Description = styled.div`
  > div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    > img {
      height: 30px;
    }

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.4rem;
      color: #5c5c5c;
      margin-left: 10px;
    }
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 1.2rem;
    color: #5c5c5c;
    margin-top: 10px;
  }

  @media (max-width: 1024px) {
    > div {
      justify-content: center;
    }

    > p {
      text-align: center;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: none;
  }

  > img {
    display: block;
    height: 30px;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;

    > div {
      display: flex;
    }

    > img:nth-of-type(1) {
      margin-right: 20px;
    }
  }
`;

export const CardSkeleton = styled(Skeleton)`
  height: 180px;
  width: 320px !important;
  cursor: pointer;
  border-radius: 20px !important;

  @media (max-width: 374px) {
    height: 140px;
    width: 250px;
  }
`;
