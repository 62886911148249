import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import Button from '../../../../components/Button';
import MessageModal from '../../../../components/MessageModal';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import AddCardModal from './components/AddCardModal';
import Card from './components/Card';
import ListCards from './components/ListCards';

import cardExample from '../../../../assets/profile/card-example.png';
import bankSlip from '../../../../assets/profile/bank-slip.png';

import { Container, Row, AddButton, AddCard, Tooltip } from './styles';

const Payment = ({ setShowLoadModal, userPlan }) => {
  const history = useHistory();
  const externalID = useSelector((state) => state.User.external_id);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [listCards, setListCards] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');
  const [messageConfirmationModal, setMessageConfirmationModal] = useState('');
  const [confirmAction, setConfirmAction] = useState({});
  const [invertedButtonColorModal, setInvertedButtonColorModal] =
    useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  async function loadListCards() {
    setShowLoadModal(true);

    try {
      const response = await api.get(`/cards`);

      if (response) {
        const cards = response.data;

        const sortedCards = cards.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setListCards(sortedCards);
      }

      setListCards(response.data);
    } catch (error) {}

    setShowLoadModal(false);
  }

  function renderAddCardModal() {
    if (showAddCardModal) {
      return (
        <AddCardModal
          userPlan={userPlan}
          setVisible={setShowAddCardModal}
          setShowLoadModal={setShowLoadModal}
        />
      );
    }
  }

  function renderShowMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          setVisible={setShowMessageModal}
          type="success"
          iconColor="green"
          title={title}
          description={description}
        />
      );
    }
  }

  function renderConfirmationModal() {
    if (showConfirmationModal) {
      return (
        <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          message={messageConfirmationModal}
          confirmAction={confirmAction}
          invertedButtonColorModal={invertedButtonColorModal}
          paymentMethod={paymentMethod}
        />
      );
    }
  }

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  const changePaymentMethodClick = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    setMessageConfirmationModal(
      `Deseja realmente trocar a forma de pagamento do seu plano para ${
        paymentMethod === 'credit_card'
          ? 'Cartão de Crédito'
          : 'Boleto bancário'
      }?`,
    );

    setInvertedButtonColorModal(false);
    if (paymentMethod === 'bank_slip') {
      setConfirmAction(() => changePaymentMethodToBankSlip);
    }

    setShowConfirmationModal(true);
  };

  const changePaymentMethodToBankSlip = async () => {
    setShowLoadModal(true);
    try {
      const response = await api.patch(`/plans/${userPlan.id}/payment-method`, {
        payable_with: 'bank_slip',
      });
      setTitle('Método de pagamento do plano atual alterado para: Boleto');
      setDescription(``);
      setShowMessageModal(true);
    } catch (error) {
      setErrorMensage(error.response.data?.message);
    }

    setShowLoadModal(false);
  };

  const setCardAsDefault = async (id) => {
    setShowLoadModal(true);
    try {
      const response = await api.patch(`cards/${id}`, {
        isDefault: true,
      });

      if (response) {
        loadListCards();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoadModal(false);
    }
  };

  useEffect(() => {
    loadListCards();
  }, []);

  return (
    <Container>
      <Row onClick={() => setShowAddCardModal(true)}>
        <AddButton>
          <span>+</span>
        </AddButton>
        <AddCard>Adicionar Cartão</AddCard>
      </Row>
      <ListCards cards={listCards} setCardAsDefault={setCardAsDefault} />
      <Button
        iconLeft={bankSlip}
        onClick={() =>
          history.push({
            pathname: 'confirmar-endereco',
            operation: 'changePaymentMethod',
            state: { plan: { id: '' }, userPlan },
          })
        }
      >
        Pagar por boleto bancário
      </Button>
      {renderAddCardModal()}
      {renderErrorMensage()}
      {renderShowMessageModal()}
      {renderConfirmationModal()}
    </Container>
  );
};

export default Payment;
