import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';

import Phone from '../../assets/images/phone.png';

import { Container } from './styles';

export function AppAbout() {
  return (
    <Container>
      <div className="app-about__phone">
        <img src={Phone} alt="App" />
      </div>

      <article className="app-about__article">
        <div className="app-about__article-card">
          Experimente por 7 dias grátis
        </div>

        <h2 className="app-about__article-about">
          <span>baixe as aulas e assista </span>
          <span>
            sem interrupções com o{' '}
            <b className="app-about__article-about-b"> app</b>
          </span>
        </h2>

        <p className="app-about__article-paragraph">
          Assista também na TV, tablet ou smartphone{' '}
          <b className="app-about__article-paragraph-b">
            sem propaganda e sem interrupções
          </b>
        </p>

        <div className="app-about__tv">
          <div className="app-about__video-container">
            <iframe
              className="app-about__article-video"
              src="https://www.youtube.com/embed/HgWgJAEWh2c"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <SecondaryButton href={'/escolha-um-plano'}>
          Assine agora e experimente!
        </SecondaryButton>
      </article>
    </Container>
  );
}
