import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';

import LetterIcon from '../../assets/ContactUs/letter.png';

import {
  Container,
  GradientBackground,
  Title,
  MissionCard,
  Divisor,
} from './styles';

const AboutUs = () => {
  return (
    <>
      <Container>
        <GradientBackground />
        <Header />
        <Content>
          <Title>Fale conosco</Title>
          <p>Estamos aqui sempre para você!</p>

          <MissionCard href="mailto:ajuda@yogaco.com.br">
            <img src={LetterIcon} alt="ícone de carta" />
            <span>E-mail: ajuda@yogaco.com.br</span>
          </MissionCard>
        </Content>
        <Divisor />
        <Footer />
      </Container>
    </>
  );
};

export default AboutUs;
