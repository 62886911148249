import styled from 'styled-components';

export const Container = styled.section`
  > div:nth-of-type(3) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1100px) {
    > div:nth-of-type(3) {
      flex-direction: column;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.6rem;
  color: #165011;
  width: fit-content;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 40px;
`;

export const Events = styled.div`
  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;
