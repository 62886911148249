import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  max-width: 527px;
  margin-left: 110px;

  .input,
  input {
    background-color: #eef0f1;
  }

  .uf__select {
    width: 80%;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px lightgrey;

    .ant-select-selector {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      min-height: 60px;
      padding: 5px 20px;

      font-family: 'Aller-Regular';
      font-size: 1.5rem;
      color: #a2a2a2;
      text-align: left;

      background-color: #eef0f1;
      border-radius: 10px;

      .ant-select-selection-search-input {
        display: flex;
        align-items: center;

        height: 100%;
        padding: 5px 8px;
      }
    }

    min-height: 60px;
    background-color: #eef0f1;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    margin-top: 40px;
  }

  @media (max-width: 1260px) {
    width: 100%;
    max-width: unset;

    margin-top: 20px;
    margin-left: 0%;

    > div {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
    }

    > div:nth-of-type(4) {
      width: 100%;
    }

    > button {
      width: 100%;
    }

    .uf__select {
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.6rem;
  color: #165011;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;

  > div:nth-of-type(1) {
    width: 54%;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(2) {
    width: 20%;
    margin-left: 28px;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  @media (max-width: 1260px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
      margin-left: 0px;
    }
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  label {
    font-family: 'Roboto-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
  }
`;

export const BackButton = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 1.5rem;
  color: #a2a2a2;
  margin-top: 20px auto 0px auto;
  text-align: center;
  max-width: 420px;

  cursor: pointer;
`;
