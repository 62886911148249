import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 8.85rem 0 11.9125rem;

  display: flex;

  background: rgb(132, 48, 202);
  background: var(--green-gradient);

  img {
    width: 100%;
  }
  .yoga-calendar-wrapper {
    width: 97%;
    max-width: 70.925625rem;
    height: fit-content;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 4.5rem;

    @media (min-width: 1096px) {
      flex-direction: row;
    }
  }

  .yoga-calendar__article-content {
    margin: 0 auto;
    flex: 47;
  }

  .yoga-calendar__article-content__icon {
    width: 100%;
    max-width: 3.344375rem;
    height: 3.5375rem;
  }

  .yoga-calendar__article-content__title {
    margin-top: 1.43125rem;

    color: var(--green2F);
    font-size: 3rem;
    font-family: 'Bartolomeo-Regular';
  }

  .yoga-calendar__article-content__title-b {
    color: var(--grey9E);
    font-size: 2rem;
    font-family: 'Aller-Light';
  }

  .yoga-calendar__article-content__paragraph {
    width: 100%;
    max-width: 26.8125rem;
    height: fit-content;
    margin-top: 2.1875rem;

    color: var(--dark70);
    font-family: 'Aller-Light';
    font-size: 1.7rem;
    line-height: 2.3125rem;
  }

  .yoga-calendar__article-content__paragraph-b {
    color: var(--green24);
  }

  .yoga-calendar__article-content__card {
    width: 100%;
    max-width: 29.155rem;
    height: auto;
    aspect-ratio: 466.48 / 151.68;
    margin-top: 3.2rem;
  }
`;
