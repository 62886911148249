import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import socketio from 'socket.io-client';

import api from '../../../../services/api';

import NotificationModal from './components/NotificationModal';
import NoAccessModal from '../../../../components/NoAccessModal';

import bellImg from '../../../../assets/header/bell.png';
import bellSelected from '../../../../assets/header/bellSelected.png';
import newNotification from '../../../../assets/header/newNotification.png';

import { Container, NewNotificationIcon } from './styles';

const NotificationBell = () => {
  const history = useHistory();
  const user = useSelector((state) => state.User);
  const [componentIsFocused, setComponentIsFocused] = useState(false);
  const [showNotificationIcon, setShowNotificationIcon] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);

  useEffect(() => {
    getInformations();
  }, []);

  const handleClickNotification = () => {
    history.push('/notificacoes');
  };

  const getInformations = async () => {
    const userHasAccess = await verifyIfUserHasAccess();

    if (userHasAccess) {
      fetchNotifications();
      fetchIfHaveNewNotification();
    }
  };

  const socket = useMemo(
    () =>
      socketio(process.env.REACT_APP_API, {
        query: {
          user_id: user.id,
        },
      }),
    [user.id],
  );

  useEffect(() => {
    socket.on('notification', (notification) => {
      setNotifications([notification, ...notifications].slice(0, 4));
      setShowNotificationIcon(true);
    });
  }, [socket, notifications]);

  const fetchNotifications = async () => {
    await api
      .get('/notifications', { params: { limit: 4 } })
      .then((res) => {
        setNotifications(res.data.results);
      })
      .catch((err) => {});
  };

  const fetchIfHaveNewNotification = async () => {
    await api
      .get('/new-notifications')
      .then((res) => {
        setShowNotificationIcon(res.data.has_notification);
      })
      .catch((err) => {
        setShowNotificationIcon(false);
      });
  };

  async function updateNotificationStatus() {
    await api
      .put('/new-notifications')
      .then((res) => {})
      .catch((err) => {});
  }

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const verifyIfUserHasAccess = async () => {
    if (!user.id.trim() || !user.accessToken.trim()) {
      return false;
    }

    const userHasAccess = await fetchIfUserHasPlanActive();

    return userHasAccess;
  };

  const handleClickNotificationIcon = async () => {
    const userHasAccess = await verifyIfUserHasAccess();

    if (userHasAccess) {
      setComponentIsFocused(!componentIsFocused);
      if (showNotificationIcon) {
        setShowNotificationIcon(false);
        updateNotificationStatus();
      }

      return;
    }

    setShowNoAccessModal(true);
  };

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return (
        <NoAccessModal
          show={showNoAccessModal}
          setShow={setShowNoAccessModal}
        />
      );
    }
  };

  const renderBellIcon = () => {
    if (componentIsFocused) {
      return (
        <img
          onClick={() => handleClickNotificationIcon()}
          src={bellSelected}
          alt="Sino de notificações"
        />
      );
    }

    return (
      <img
        onClick={() => handleClickNotificationIcon()}
        src={bellImg}
        alt="Sino de notificações"
      />
    );
  };

  const renderNewNotificationIcon = () => {
    if (showNotificationIcon) {
      return (
        <NewNotificationIcon
          src={newNotification}
          alt="Ícone de novas notificações"
        />
      );
    }
  };

  return (
    <Container>
      {renderBellIcon()}
      {renderNewNotificationIcon()}
      <NotificationModal
        componentIsFocused={componentIsFocused}
        notifications={notifications}
        handleClickNotification={handleClickNotification}
      ></NotificationModal>
      {renderNoAccessModal()}
    </Container>
  );
};

export default NotificationBell;
