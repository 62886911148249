import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logoutUserAutomatically from '../../utils/logoutUserAutomatically';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import ListClass from './components/ListClass';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import TooltipClipboard from '../../components/TooltipClipboard';
import Scheduling from '../../components/Scheduling';
import NoAccessModal from '../../components/NoAccessModal';
import FavoriteButton from '../../components/FavoriteButton';
import WarningCookie from '../../components/WarningCookie';

import exampleThumbnail from '../../assets/serie/example-thumbnail.png';
import iconTV from '../../assets/serie/icon-tv.png';
import favorite from '../../assets/serie/favorite.png';
import share from '../../assets/serie/share.png';
import time from '../../assets/serie/time.png';
import scheduleClass from '../../assets/serie/schedule-class.png';
import numberClass from '../../assets/serie/number-class.png';
import level from '../../assets/serie/level.png';
import intention from '../../assets/serie/intention.png';
import calendar from '../../assets/class/calendar.png';

import {
  Container,
  GradientBackground,
  Thumbnail,
  Row,
  Description,
  IconTV,
  TitleContainer,
  Divider,
  DataAboutSerie,
  ScheduleSerieButton,
  Spinner,
  ShowMoreLessons,
} from './styles';

const Serie = () => {
  const user = useSelector((state) => state.User);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showLoadModalLessons, setShowLoadModalLessons] = useState(false);
  const [serieInformations, setSerieInformations] = useState({
    title: '',
    description: '',
    thumbnail: '',
    theme: '',
    duration: 0,
  });
  const [listLessons, setListLessons] = useState([]);
  const [showTooltipClipboard, setShowTooltipClipboard] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [showSchedulingComponent, setShowSchedulingComponent] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showLoadNextLessons, setShowLoadNextLessons] = useState(false);

  function getEndpointSerieInformations() {
    const { pathname } = location;
    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      return '/podcast-series/published';
    } else {
      return '/series/published';
    }
  }

  function getEndpointSerieLessons(addPage) {
    const { pathname } = location;

    let pagination = page;
    if (addPage) {
      pagination++;
      setPage(pagination);
    }

    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      return `/podcast-series/${id}/episodes/published?page=${pagination}&limit=10000`;
    } else {
      return `/series/${id}/lessons/published?page=${pagination}&limit=10000`;
    }
  }

  const loadMoreLessons = async () => {
    setShowLoadNextLessons(true);

    try {
      const endpoint = getEndpointSerieLessons(true);
      const response = await api.get(endpoint);

      if (response) {
        if (
          location.pathname.includes('podcast') ||
          location.pathname.includes('meditacao')
        ) {
          setListLessons([...listLessons, ...response.data.episodes]);
        } else {
          setListLessons([...listLessons, ...response.data.lessons]);
        }
      }
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadNextLessons(false);
  };

  const loadSerieInformations = async () => {
    setShowLoadModal(true);

    if (!id.trim()) {
      history.push('/');
      return;
    }

    try {
      const endpoint = getEndpointSerieInformations();
      const response = await api.get(endpoint);
      if (response) {
        for (let serie of response.data) {
          if (serie.id === id) {
            setSerieInformations(serie);
          }
        }
      }
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadModal(false);
    setShowLoadModalLessons(true);

    try {
      const endpoint = getEndpointSerieLessons();
      const response = await api.get(endpoint);

      if (response) {
        setTotal(response.data.total);

        if (pathname.includes('podcast') || pathname.includes('meditacao')) {
          setListLessons([...listLessons, ...response.data.episodes]);
        } else {
          setListLessons([...listLessons, ...response.data.lessons]);
        }
      }
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadModalLessons(false);
  };

  function renderSchedulingComponent() {
    if (showSchedulingComponent) {
      return (
        <Scheduling
          type="série"
          show={showSchedulingComponent}
          setShow={setShowSchedulingComponent}
          postId={serieInformations.post_id}
        />
      );
    }
  }

  function renderNumberLessons() {
    if (listLessons.length === 0) {
      return <span>Nenhuma aula cadastrada</span>;
    } else if (listLessons.length === 1) {
      return <span>1 aula</span>;
    }

    return <span>{listLessons.length} aulas</span>;
  }

  function renderListLessons() {
    if (showLoadModalLessons) {
      return <Spinner />;
    }

    if (total > 0) {
      return (
        <>
          <ListClass
            lessons={listLessons}
            pathname={pathname}
            setShowLoadModal={setShowLoadModal}
            setShowNoAccessModal={setShowNoAccessModal}
            checkIfUserHasAnActivePlan={checkIfUserHasAnActivePlan}
          />
          {renderShowMoreLessons()}
        </>
      );
    }
  }

  function renderShowMoreLessons() {
    if (showLoadNextLessons) {
      return <Spinner />;
    }

    if (listLessons.length < total) {
      return (
        <ShowMoreLessons onClick={() => loadMoreLessons()}>
          Carregar mais aulas
        </ShowMoreLessons>
      );
    }
  }

  function renderSerieDuration() {
    const { duration } = serieInformations;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <li>
            <img src={time} alt="Ícone de duração em horas da série" />
            <span>Duração: {duration} minutos</span>
          </li>
        );
      } else {
        const hours = Math.floor(duration / 60);
        const minutes = duration - 60 * hours;
        return (
          <li>
            <img src={time} alt="Ícone de duração em horas da série" />
            <span>
              Duração: {hours} {hours === 1 ? 'hora' : 'horas'} e {minutes}{' '}
              minutos
            </span>
          </li>
        );
      }
    }
  }

  function copyToClipboard() {
    const link = `https://yogaco.com.br/#/serie/${id}`;
    navigator.clipboard.writeText(link).then(function () {
      setShowTooltipClipboard(true);
      setTimeout(function () {
        setShowTooltipClipboard(false);
      }, 2000);
    });
  }

  const handleThumbnailClick = () => {
    let numberLessons;
    let route;
    let id;
    if (location.pathname.includes('podcast')) {
      numberLessons = listLessons.episodes?.length;

      if (numberLessons && numberLessons !== 0) {
        route = '/podcast/aula/';

        id = listLessons.episodes[0].id;
      } else {
        return;
      }
    } else if (location.pathname.includes('meditacao')) {
      numberLessons = listLessons.episodes?.length;

      if (numberLessons && numberLessons !== 0) {
        route = '/meditacao/aula/';

        id = listLessons.episodes[0].id;
      } else {
        return;
      }
    } else {
      numberLessons = listLessons.lessons?.length;

      if (numberLessons && numberLessons !== 0) {
        route = '/aula/';

        id = listLessons.lessons[0].id;
      } else {
        return;
      }
    }

    if (numberLessons > 0) {
      history.push(`${route}${id}`);
    }
  };

  const checkIfUserHasAnActivePlan = async () => {
    try {
      const response = await api.get(`/users/plans`);
      const userPlan = response.data;
      if (userPlan.status === 'pending' || userPlan.status === 'suspended') {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSchedule = async () => {
    if (!user.id.trim() || !user.accessToken.trim()) {
      setShowLoadModal(false);
      setShowNoAccessModal(true);
      return;
    }

    setShowLoadModal(true);

    const userHasAccess = await checkIfUserHasAnActivePlan();

    setShowLoadModal(false);

    if (userHasAccess) {
      setShowSchedulingComponent(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  function renderNoAccessModal() {
    if (setShowNoAccessModal) {
      return (
        <NoAccessModal
          show={showNoAccessModal}
          setShow={setShowNoAccessModal}
        />
      );
    }
  }

  useEffect(() => {
    loadSerieInformations();
    window.scrollTo(0, 0);
    if (logoutUserAutomatically()) {
      dispatch({ type: 'yoga-adm/user/logout' });
      history.push('/login');
    }
  }, []);

  return (
    <Container>
      <Header />
      <GradientBackground />
      <Content>
        <Thumbnail
          onLoad={() => setShowThumbnail(true)}
          show={showThumbnail}
          onClick={handleThumbnailClick}
          src={serieInformations.thumbnail_url}
          alt="Thumbnail da série"
        />
        <Description>
          <TitleContainer>
            <div>
              <h1>{serieInformations.title}</h1>
              <ul>
                <li>
                  <FavoriteButton
                    post_id={serieInformations.post_id}
                    is_favorite={serieInformations.is_favorite}
                    favorite_id={serieInformations.favorite_id}
                    setShowLoadModal={setShowLoadModal}
                  />
                </li>
                <li onClick={() => copyToClipboard()}>
                  <img src={share} alt="Ícone de compartilhar série" />
                  <TooltipClipboard showTooltip={showTooltipClipboard}>
                    Série copiada
                  </TooltipClipboard>
                </li>
              </ul>
            </div>
            {/* <IconTV src={iconTV} alt="Ícone de TV" /> */}
          </TitleContainer>
          <Row>
            <p>{serieInformations.description}</p>
            <ScheduleSerieButton onClick={() => handleSchedule()}>
              <img src={calendar} alt="Ícone de calendário" />
              Agendar série
            </ScheduleSerieButton>
          </Row>
          <Divider />
          <DataAboutSerie>
            <ul>
              <li>
                <img
                  src={numberClass}
                  alt="Ícone de número de aulas dentro da série"
                />
                {renderNumberLessons()}
              </li>
              {renderSerieDuration()}
            </ul>
          </DataAboutSerie>
          <Divider />
        </Description>
        {renderListLessons()}
      </Content>
      <Footer />
      <LoadModal show={showLoadModal} />
      {renderSchedulingComponent()}
      {renderNoAccessModal()}
      <WarningCookie />
    </Container>
  );
};

export default Serie;
