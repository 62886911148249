import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  width: 100%;
  background-color: #f2f4f7;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #b3b1b1;
  }
`;
