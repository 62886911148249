import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;

  > div {
    align-items: center;
  }

  > div:nth-of-type(1) {
    cursor: pointer;
  }

  @media (max-width: 680px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const AddButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #8430ca;
  text-align: center;

  > span {
    display: block;
    font-family: 'Aller-Light';
    font-size: 3rem;
    color: #fff;
    margin-left: 0px;
    margin-top: -8px;
  }
`;

export const AddCard = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  margin-left: 10px;
`;

export const Informations = styled.div`
  width: 500px;
  height: 300px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.creditCardBannerSelected ? '#8430CA' : '#E2E2E2'};
  margin-top: 40px;
  padding: 20px;
  transition: 1s all;

  > div > div {
    height: 40px;
    border-radius: 12px;
    > input {
      background-color: #fff;
    }
  }

  > div:nth-of-type(1) {
    justify-content: flex-end;
    > div {
      width: 40%;
    }
  }

  > div:nth-of-type(2) {
    justify-content: space-between;
    > div {
      width: 22%;
    }
  }

  > div:nth-of-type(3) {
    justify-content: space-between;
    > div:nth-of-type(1) {
      width: 50%;
      height: 32px;
    }
    > div:nth-of-type(2) {
      width: 22%;
      height: 32px;
    }
  }

  img {
    height: 40px;
    margin-bottom: 40px;
  }

  @media (max-width: 1080px) {
    width: 360px;
    height: 240px;
    padding: 10px;
  }

  @media (max-width: 680px) {
    width: 100%;

    > div:nth-of-type(2) {
      > div {
        padding: 0 4px;
      }
    }

    > div:nth-of-type(3) {
      > div:nth-of-type(1) {
        padding: 0 4px;
      }
      > div:nth-of-type(2) {
        padding: 0 4px;
      }
    }
  }
`;
