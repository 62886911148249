import Card from './components/Card';

import { Container, Title } from './styles';

const FavoriteLessons = ({ lessons, pathname, setShowLoadModal, search }) => {
  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const renderCards = (lessonsFiltered) => {
    return lessonsFiltered.map((item) => (
      <Card
        lesson={item}
        pathname={pathname}
        setShowLoadModal={setShowLoadModal}
      />
    ));
  };

  const renderFavoriteLessons = () => {
    let lessonsFiltered = [];
    if (search.trim() !== '') {
      let title;
      let searchFormatted = formatString(search);
      lessons.map((item) => {
        title = formatString(item.post.lesson.name);
        if (title.indexOf(searchFormatted) >= 0) {
          lessonsFiltered.push(item);
        }
      });
    } else {
      lessonsFiltered = lessons;
    }

    if (lessonsFiltered.length > 0) {
      return (
        <>
          <Title>Suas aulas favoritas</Title>
          <Container>{renderCards(lessonsFiltered)}</Container>
        </>
      );
    }
  };

  return <>{renderFavoriteLessons()}</>;
};

export default FavoriteLessons;
