import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 450px) {
    > div:nth-of-type(3) {
      > div:nth-of-type(1) {
        width: 100%;
      }
    }
  }
`;

export const IconDiary = styled.img`
  margin: 0 auto;
  display: block;
  width: 260px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1060px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 450px) {
    button {
      width: 100%;
    }
  }
`;

export const GreenBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
export const YellowBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(250, 207, 88, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const PurpleBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(132, 48, 202, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
