import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: #165011;

  > img {
    margin-right: 8px;
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const ListNotifications = styled.ul`
  width: 380px;
  z-index: 10;
  list-style: none;
  background-color: #fff;
  border-radius: 17px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 8px 8px;
  margin-top: 40px;
  margin-bottom: 100px;

  li {
    cursor: pointer;
  }

  li:nth-child(4n + 1) {
    background-color: rgba(161, 108, 205, 0.1);
    svg {
      color: #a16ccd;
    }
  }

  li:nth-child(4n + 2) {
    background-color: rgba(47, 183, 47, 0.1);
    svg {
      color: #007d00;
    }
  }

  li:nth-child(4n + 3) {
    background-color: rgba(255, 252, 227, 1);
    svg {
      color: #d3b909;
    }
  }

  li:nth-child(4n + 4) {
    background-color: rgba(254, 233, 233, 1);
    svg {
      color: #ff9999;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const Notification = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 14px 8px;
  margin: 8px 0px;
`;

export const UserPhotoContainer = styled.div`
  position: relative;

  > img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  background-color: #fff;
  padding: 4px;
  width: fit-content;
  border-radius: 50%;
  bottom: -2px;
  right: -6px;
  box-shadow: 0px 0px 6px 0px lightgrey;
`;

export const Informations = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #707070;
  margin-left: 8px;
  overflow-wrap: anywhere;

  > span {
    font-family: 'Aller-Bold';
  }
`;

export const Time = styled.div`
  font-family: 'Aller-Regular';
  color: #b3b1b1;
  font-size: 0.6rem;
  position: absolute;
  bottom: 4px;
  right: 20px;
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #48e25b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const ShowMore = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  margin: 20px auto;
  text-align: center;
  cursor: pointer;
`;
