import CalendarTabs from '../../assets/icons/calendar-tabs.svg';
import YogaForKids from '../../assets/images/yoga-for-kids.png';

import { Container } from './styles';

export default function Calendar() {
  return (
    <Container>
      <table className="yoga-calendar__article-calendar__table">
        <thead>
          <tr>
            <th colSpan={7}>
              <div className="yoga-calendar__article-calendar-header">
                <div className="yoga-calendar__article-calendar-header__tab">
                  <img src={CalendarTabs} alt="Calendar tabs" />
                </div>
                <h2 className="yoga-calendar__article-calendar-header__date">
                  MARÇO{' '}
                  <b className="yoga-calendar__article-calendar-header__year">
                    2022
                  </b>
                </h2>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="yoga-calendar__article-calendar__tbody">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>01</td>
            <td>02</td>
          </tr>
          <tr>
            <td>03</td>
            <td>04</td>
            <td>05</td>
            <td>06</td>
            <td>07</td>
            <td>08</td>
            <td>09</td>
          </tr>
          <tr>
            <td className="yoga-calendar__article-calendar__td-bullet--green">
              10
            </td>
            <td>11</td>
            <td className="yoga-calendar__article-calendar__td--selected">
              12
              <div className="yoga-calendar__article-calendar__td-container--selected">
                <p>12</p>
              </div>
            </td>
            <td className="yoga-calendar__article-calendar__td-bullet--yellow">
              13
            </td>
            <td className="yoga-calendar__article-calendar__td-bullet--green">
              14
            </td>
            <td>15</td>
            <td>16</td>
          </tr>
        </tbody>
      </table>
      <div className="yoga-calendar__article-calendar__image">
        <img src={YogaForKids} alt="Yoga for kids" />
      </div>
      <div className="yoga-calendar__article-calendar__info">
        <h3 className="yoga-calendar__article-calendar__info-day">
          Ter 12, 2022
        </h3>
        <span className="yoga-calendar__article-calendar__info-hour">
          18:00
        </span>
      </div>
    </Container>
  );
}
