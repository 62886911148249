import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../../../services/api';

import Button from '../../../../components/Button';
import Card from './components/Card';
import Input from '../../../../components/Input';

import bankSlip from '../../../../assets/profile/bank-slip.png';
import creditCards from '../../../../assets/profile/credit-cards.png';

import gift from '../../../../assets/general/gift.png';

import {
  Container,
  Row,
  AddButton,
  AddCard,
  CreditCardBanners,
  Tooltip,
  TooltipPaymentOption,
  IWasPresented,
  GiftedRow,
} from './styles';

const AddCreditCard = ({
  plan,
  informations,
  setShowFormDataCreditCard,
  setShowLoadModal,
  userHasPaymentOptions,
  setShowModalSuccessPayment,
  paymentOptionChosen,
}) => {
  const history = useHistory();

  const user = useSelector((state) => state.User);

  const [errorMensage, setErrorMensage] = useState('');
  const [errorChosePaymentOption, setErrorChosePaymentOption] = useState('');

  const [code, setCode] = useState('');

  const [showMessageModal, setShowMessageModal] = useState(false);

  const handleAddCard = () => {
    setErrorChosePaymentOption('');
    setShowFormDataCreditCard((prevState) => !prevState);
  };

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  function renderErrorChosePaymentOption() {
    if (errorChosePaymentOption) {
      return (
        <TooltipPaymentOption>{errorChosePaymentOption}</TooltipPaymentOption>
      );
    }
  }

  const iWasPresented = async () => {
    if (user.accessToken.trim() === '' || user.id.trim() === '') {
      setErrorMensage(
        'Para usar um código gerado, você deve primeiro estar logado',
      );
      return;
    }

    if (code.trim() === '') {
      setErrorMensage('Digite o código corretamente');
      return;
    }

    setErrorMensage('');
    setShowLoadModal(true);

    await api
      .post('/gift-code/apply', {
        code,
      })
      .then((response) => {
        setShowLoadModal(false);
        setShowMessageModal(true);

        setTimeout(function () {
          setShowLoadModal(false);
          history.push('/');
        }, 3000);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMensage(message);
  };

  return (
    <Container>
      <Row onClick={() => handleAddCard()}>
        <AddButton>
          <span>+</span>
        </AddButton>
        <AddCard>Adicionar Cartão</AddCard>
      </Row>
      {renderErrorChosePaymentOption()}
      <Card informations={informations} />
      <CreditCardBanners
        src={creditCards}
        alt="Bandeira da Visa, MasterCard, Elo e HiperCard"
      />

      <Button
        iconLeft={bankSlip}
        onClick={() =>
          history.push({
            pathname: '/confirmar-endereco',
            state: { plan, operation: 'signPlan', userPlan: { id: '' } },
          })
        }
      >
        Pagar por boleto bancário
      </Button>

      <IWasPresented>
        <img src={gift} alt="Ícone de presente" />
        <div>
          Inserir código
          <GiftedRow>
            <Input
              value={code}
              onInput={(event) => setCode(event.target.value)}
            />
            <button onClick={() => iWasPresented()}>ok</button>
          </GiftedRow>
        </div>
      </IWasPresented>

      {renderErrorMensage()}
    </Container>
  );
};

export default AddCreditCard;
