import { Calendar } from '../../molecules/Calendar';

import HeartCalendar from '../../assets/icons/heart-calendar-icon.svg';
import NovemberCard from '../../assets/images/november-card.png';

import { Container } from './styles';

export function YogaCalendar() {
  return (
    <Container>
      <div className="yoga-calendar-wrapper">
        <article className="yoga-calendar__article-content">
          <div className="yoga-calendar__article-content__icon">
            <img src={HeartCalendar} alt="Calendar" />
          </div>
          <h2 className="yoga-calendar__article-content__title">
            Calendário{' '}
            <b className="yoga-calendar__article-content__title-b">YOGA CO.</b>
          </h2>
          <p className="yoga-calendar__article-content__paragraph">
            Pratique em um clique com o{' '}
            <b className="yoga-calendar__article-content__paragraph-b">
              calendário
            </b>{' '}
            onde eu escolho as aulas e tema do mês. Aqui você encontra
            inspiração para começar, manter e voltar à prática!
          </p>
          <div className="yoga-calendar__article-content__card">
            <img src={NovemberCard} alt="November inspiration" />
          </div>
        </article>
        <Calendar />
      </div>
    </Container>
  );
}
