import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Store from './redux/';
import Routes from './routes';
import GlobalStyle from './styles/global';

import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Routes />
      <GlobalStyle />
    </Provider>
    <ToastContainer autoClose={4000} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// var initializeCastApi = function () {
//   window.cast.framework.CastContext.getInstance().setOptions({
//     receiverApplicationId:
//       window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
//     autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
//   });
// };

// window['__onGCastApiAvailable'] = function (isAvailable) {
//   if (isAvailable) {
//     initializeCastApi();
//   }
// };
