import styled from 'styled-components';

export const Container = styled.div`
  > div:nth-of-type(3) {
    animation: rendering 1s;
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(132, 48, 202, 1) 0%,
    rgba(171, 112, 218, 1) 40%,
    rgba(255, 252, 252, 1) 80%
  );
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    justify-content: center;

    > h1 {
      display: none;
    }
  }
`;

export const UserPhoto = styled.img`
  width: 206px;
  height: 208px;
  border-radius: 50%;
`;

export const Title = styled.h1`
  font-family: 'Aller-Bold';
  font-size: 3rem;
  color: #fff;
  text-align: center;
  margin-left: 40px;

  > span {
    display: block;
    font-family: 'Bartolomeo-Regular';
    font-size: 3.4rem;
    color: #fff;
  }
`;

export const UpdateUserPhoto = styled.div`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: #b3b1b1;
  margin-top: 20px;
  cursor: pointer;

  > img {
    margin-left: 20px;
  }

  @media (max-width: 1024px) {
    text-align: center;
  }

  > input[type='file'] {
    display: none;
  }
`;

export const Name = styled.div`
  font-family: 'Aller-Bold';
  font-size: 2rem;
  color: #165011;
  margin-top: 20px;
`;

export const Options = styled.div`
  margin-top: 20px;
  > ul {
    display: flex;
    > li {
      padding-bottom: 10px;
      font-family: 'Aller-Regular';
      font-size: 1.4rem;
      color: #165011;
      margin-right: 20px;
      list-style: none;
      border-style: solid;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 2px;
      border-left-width: 0px;
      transition: 1s all;
      cursor: pointer;
    }

    > li:nth-of-type(1) {
      border-color: ${(props) =>
        props.chosenMenu === 'Personal Data' ? '#a16ccd' : '#B3B1B1'};
    }

    > li:nth-of-type(2) {
      border-color: ${(props) =>
        props.chosenMenu === 'Payment' ? '#a16ccd' : '#B3B1B1'};
    }

    > li:nth-of-type(3) {
      border-color: #b3b1b1;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
`;
