import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: auto;
  margin-top: 20px;
`;

export const Thumbnail = styled.img`
  max-width: 500px;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  object-fit: cover;
  cursor: pointer;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

export const DateDescription = styled.div`
  font-family: 'Roboto-Regular';
  color: #bfbfbf;
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  background-color: #a16ccd;
  height: 10px;
  width: 10px;
  border-radius: 50%50%;
  margin-right: 20px;
`;

export const Hour = styled.div`
  font-family: 'Roboto-Regular';
  color: #fff;
  background-color: #a16ccd;
  padding: 4px 12px;
  border-radius: 8px;
`;

export const Card = styled.div`
  animation: rendering 1s;
  min-width: 470px;
  margin-bottom: 10px;

  &:nth-child(even) {
    margin-left: 20px;
  }
`;
