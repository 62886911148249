import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  .DraftEditor-root {
    box-shadow: 0px 0px 6px 0px lightgrey;
    height: 400px;
    overflow: auto;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 80px;
  width: 1190;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #f2f4f7;
  box-shadow: 0px 0px 6px 0px lightgrey;

  > div:nth-of-type(1) {
    img {
      margin-right: 40px;
    }
  }

  > div:nth-of-type(2) {
    span {
      font-family: 'Roboto-Regular';
      font-size: 2rem;
      color: #b3b1b1;
      margin-right: 40px;
    }

    img {
      height: 44px;
    }
  }
`;

export const TextArea = styled.textarea`
  font-family: 'Aller-Light';
  font-size: 1.6rem;
  color: #5c5c5c;
  width: 100%;
  height: 400px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border: 0;
`;
