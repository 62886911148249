import { Container } from './_hero';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';

export const Hero = () => {
  return (
    <Container className="hero">
      <div className="hero__content">
        <div className="hero__caption">
          <h1 className="hero__title">
            <span>
              <b className="hero__title-b">Olá, </b>{' '}
              <b className="hero__title-b--second ">Olá!</b>
            </span>
            <span className="hero__title-message">
              Que bom que você está aqui
            </span>
          </h1>

          <legend className="hero__description">
            A Yoga CO. é o nosso oásis online para uma vida melhor
          </legend>

          <PrimaryButton href={'/escolha-um-plano'}>Eu quero!</PrimaryButton>
        </div>

        <div className="hero__video-wrapper">
          <div className="hero__video">
            <iframe
              src="https://www.youtube.com/embed/SXl-Ahw2oLE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </Container>
  );
};
