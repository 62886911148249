import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const SearchContainer = styled.div`
  animation: rendering 1s;
  background-color: #f2f4f7;

  > div {
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > div:nth-of-type(1) {
      margin-right: 20px;
    }

    @media (min-width: 720px) {
      flex-direction: column;

      /* > div:nth-of-type(1) {
        width: 100%;
        margin-right: 0px;
      } */
    }
  }

  @media (max-width: 810px) {
    > div > div:nth-of-type(1) {
      margin-right: 0px;
    }
  }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  background-color: #fff;
  border-radius: 40px;
  padding: 20px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 6px 0px lightgrey;

  > img {
    margin-right: 20px;
  }
`;

export const ContainerInput = styled.div`
  position: relative;

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    > div:nth-of-type(1) {
      width: 520px;
    }
  }

  > div:nth-of-type(2) {
    max-width: 520px;
  }

  @media (max-width: 810px) {
    > div:nth-of-type(1) {
      width: 100%;
      flex-direction: column;

      > div:nth-of-type(1) {
        width: 100%;
      }
    }
    > div:nth-of-type(2) {
      max-width: 520px;
    }
  }
`;

export const ButtonShowFilters = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  padding: 14px;
  width: fit-content;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  height: fit-content;
  margin-left: 20px;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 10px;
  }

  @media (max-width: 810px) {
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div:nth-of-type(1) {
    div {
      background-color: #ff9999;
    }
  }

  > div:nth-of-type(2) {
    div {
      background-color: #74df80;
    }
  }

  > div:nth-of-type(3) {
    div {
      background-color: #b86ef6;
    }
  }

  > div:nth-of-type(4) {
    div {
      background-color: #ffe08c;
    }
  }

  @media (max-width: 700px) {
    margin-bottom: -20px;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -60px;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  margin: 10px 0px;
  span {
    font-family: 'Aller-Regular';
    font-size: 1.6rem;
    color: #165011;
    margin: 20px;
  }
  div {
    width: 80px;
    height: 2px;
    margin: 10px auto 0px auto;
  }

  ${(props) =>
    props.code === props.chosenMenu &&
    css`
      transform: scale(1.1);
      box-shadow: 0px 0px 6px 0px lightgrey;
      padding: 10px 0px;
      border-radius: 20px;
      transition: all 0.5s;
      margin: 0px 10px;
    `}
`;
