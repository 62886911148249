import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Logo from './components/Logo';
import Content from '../../components/Content';
import Search from './components/Search';
import FilterSelect from './components/FilterSelect';
import FilteredContent from './components/FilteredContent';
import LoadModal from '../../components/LoadModal';
import NoAccessModal from '../../components/NoAccessModal';
import MessageModal from '../../components/MessageModal';
import Footer from '../../components/Footer';

import { Container, Title } from './styles';

const Mylessons = () => {
  const user = useSelector((state) => state.User);
  const [historic, setHistoric] = useState({
    series: [],
    podcastSeries: [],
    meditationSeries: [],
    lessons: [],
    podcasts: [],
    meditations: [],
  });
  const [filterOption, setFilterOption] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [search, setSearch] = useState('');
  const [showNoAcessModal, setShowNoAccessModal] = useState(false);
  const [error, setError] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);

  const fetchHistoric = async () => {
    await api
      .get('/history')
      .then((response) => {
        setHistoric(response.data);
      })
      .catch((error) => handleError(error));
  };

  const handleUserAccess = () => {
    if (!user.id.trim() || !user.accessToken.trim()) {
      setShowNoAccessModal(true);
      return;
    }

    fetchHistoric();
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    if (userHasPlanActive) {
      setError(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  useEffect(() => {
    handleUserAccess();
  }, []);

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAcessModal) {
      return <NoAccessModal show={showNoAcessModal} blockedPage={true} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  return (
    <Container id="favorites">
      <Header />
      <Logo />
      <Content>
        <Search setSearch={setSearch} />
        <FilterSelect setFilterOption={setFilterOption} />
        <FilteredContent
          historic={historic}
          filterOption={filterOption}
          setShowLoadModal={setShowLoadModal}
          search={search}
        />
      </Content>
      <Footer />
      {renderLoadModal()}
      {renderNoAccessModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default Mylessons;
