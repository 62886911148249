import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 4.2125rem 0 6.1rem;

  position: relative;

  background: rgb(72, 226, 91);
  background: linear-gradient(
    0deg,
    rgba(72, 226, 91, 0.5242471988795518) 0%,
    rgba(161, 237, 169, 0.3981967787114846) 18%,
    rgba(234, 246, 234, 0.09) 42%,
    rgba(255, 252, 252, 0.03) 65%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    max-width: 100%;
  }
  .app-about__phone {
    width: 38.704375rem;
    height: 50.984375rem;

    display: none;

    position: absolute;
    top: -1.5rem;
    left: -10rem;

    @media (min-width: 1079px) {
      display: block;
    }
  }

  .app-about__article {
    width: 95%;
    margin: 0 auto;

    @media (min-width: 1079px) {
      width: calc(95% - 30rem);
      margin: 0 0 0 30rem;
    }
  }

  .app-about__article-card {
    width: 100%;
    max-width: 27.5625rem;
    height: 3.9375rem;
    margin: 0 auto;

    color: var(--green16);
    text-align: center;
    font-size: 1.3125rem;
    line-height: 3.9375rem;
    border-radius: 2.4375rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .app-about__article-about {
    width: 100%;
    max-width: 30.5rem;
    margin: 1.89375rem auto 0;

    font-family: 'Aller-Light';
    font-size: 1.875rem;
    text-transform: uppercase;
  }

  .app-about__article-about-b {
    color: var(--green24);
    font-family: 'Aller-Regular';
    font-size: 3rem;
    text-transform: lowercase;
  }

  .app-about__article-paragraph {
    width: 100%;
    max-width: 37.5rem;
    margin: 1.78125rem auto 0;

    font-family: 'Aller-Light';
    font-size: 1.875rem;
  }

  .app-about__article-paragraph-b {
    color: var(--green24);
    font-family: 'Aller-Bold';
  }

  .app-about__article-video {
    width: 33.4375rem;
    height: auto;
    margin: 3.26875rem auto 0;
    aspect-ratio: 560 / 315;

    display: block;
  }

  .app-about__article-anchor {
    width: 100%;
    max-width: 33.8125rem;
    height: 4.75rem;
    margin: 2.2125rem auto 0;

    display: block;

    text-align: center;
    text-decoration: none;
    font-size: 1.8125rem;
    line-height: 4.75rem;
    background-color: var(--green74);
    border-radius: 0.5rem;
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .app-about__article-anchor-text {
    color: #fff;
  }
`;
