import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
`;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: #165011;
`;

export const MissionCard = styled.div`
  position: absolute;
  top: 250px;

  max-width: fit-content;
  padding: 2% 5% 3% 16.2%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;

  p {
    font-family: 'Aller-Light';
    font-size: 1.1rem;
    letter-spacing: 0.18rem;
    text-align: left;
    text-transform: uppercase;
    color: #000000;
    margin-top: 40px;
    max-width: 660px;
  }

  div {
    display: flex;
    align-items: baseline;
    span {
      font-size: 2.25rem;
      color: #24b424;
      margin-left: 26px;
    }
  }

  @media (max-width: 760px) {
    position: unset;
  }
`;

export const IconTitle = styled.div`
  justify-content: center;
  display: flex;
  align-items: baseline;
  margin: 80px 0;

  span {
    font-size: 2.25rem;
    color: #24b424;
    margin-left: 18px;
  }

  img {
    max-width: 70px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 110px;

  img {
    width: 100%;
    max-height: 1000px;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`;

export const Paragraph = styled.div`
  max-width: 935px;
  margin: 0 auto;

  p {
    font-family: 'Aller-Light';
    font-size: 1.8rem;
    color: #6c6c6c;
    margin-bottom: 48px;

    a {
      color: #24b424 !important;
    }
  }

  b {
    font-family: 'Aller-Bold';
  }
`;

export const Promote = styled.div`
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    margin: 0 auto 65px;
    max-width: 500px;
  }

  @media (max-width: 590px) {
    img {
      width: 300px;
    }

    button {
      width: 100%;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
