import styled from 'styled-components';

import logo from '../../assets/icons/yoga-icon.svg';

export const Container = styled.section`
  &.plan-offers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    padding: 3em 15px;

    background: var(--purple-gradient);

    &.plan-offers--green-background {
      background: var(--green-gradient);

      .plan-offers__paragraph {
        b {
          color: var(--green24);
        }
      }
    }

    .plan-offers__title {
      width: 100%;
      max-width: 700px;

      color: var(--dark70);
      font-size: 1.8rem;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1.5px;

      b {
        color: var(--green24);
      }
    }

    .plan-offers__call-to-action {
      color: var(--dark70);
      font-size: 1.2rem;
      text-align: center;
      text-transform: uppercase;
    }

    .plan-offers__cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 1em;

      padding: 2em 0;
    }

    .plan-offers__plan-card {
      display: flex;
      flex-direction: column;

      width: 280px;
      height: 394px;

      color: var(--purpleA1);

      background-color: #fff;

      border-radius: 16px;
      box-shadow: 0 3px 6px rgb(0 0 0/16%);

      transform: scale(0.8);
    }

    .plan-offers__plan-card--yearly {
      position: relative;
      color: var(--green24);

      transform: unset;
    }

    .plan-offers__price {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      height: 65%;

      padding: 0 1em;

      color: #fff;
      font-size: 2.3rem;
      font-weight: bold;

      border-radius: 16px 16px 0 0;
      background: linear-gradient(
        to bottom,
        var(--purpleA1) -20%,
        #421865 160%
      );

      span {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;

        text-align: right;
        line-height: 2.1rem;
        margin-top: 20px;
      }
    }

    .plan-offers__price--yearly {
      background: linear-gradient(to bottom, #48e25b 60%, var(--green24) 120%);
    }

    .plan-offers__small {
      width: 90%;

      color: #fff;
      font-size: 1.3rem;
      font-weight: lighter;
      text-align: end;
      letter-spacing: 1px;

      margin-left: auto;
    }

    .plan-offers__small--free {
      margin-top: 10px;

      color: #165011;
      font-size: 1.1rem;
      text-transform: uppercase;
    }

    .plan-offers__price-tag {
      width: 90px;
      height: 80px;

      margin-bottom: 15px;

      border-radius: 0 0 16px 16px;

      background: no-repeat center;
      background-size: 44px;
      background-color: #fff;
      background-image: url(${logo});
    }

    .plan-offers__plan-name {
      display: inline-flex;
      flex-direction: column;

      margin-top: 45px;

      font-size: 5rem;
      font-family: 'Bartolomeo-regular';
      text-align: center;
      line-height: 1rem;

      small {
        font-size: 1.5rem;
        font-family: 'Aller-regular';
        text-transform: uppercase;
        letter-spacing: 5px;
      }
    }

    .plan-offers__advantage-tag {
      position: absolute;
      top: 40%;
      right: -90px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 17vw;
      height: 17vw;

      min-width: 110px;
      min-height: 110px;

      max-width: 155px;
      max-height: 155px;

      padding: 5px;

      color: #fff;
      font-size: clamp(0.8rem, 1.5vw, 1rem);
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2rem;

      border: 1px solid #fff;

      border-radius: 60%;
      background-color: #8430ca;

      transform: rotate(20deg);

      box-shadow: 0 0px 0px 4px #8430ca, 0 3px 20px rgb(0 0 0/70%);

      small {
        margin-top: 5px;
        font-size: 0.6rem;
      }

      @media (max-width: 800px) {
        top: -10%;
        right: 0;
      }
    }

    .plan-offers__paragraph {
      width: 100%;
      max-width: 650px;

      color: var(--dark70);
      font-size: 1.25rem;
      text-align: center;

      b {
        color: var(--purpleA1);
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .plan-offers__invite {
      color: var(--green24);
      font-size: 5rem;
      font-family: 'Bartolomeo-regular';
    }

    .primary-anchor,
    .secondary-anchor {
      margin: 1em auto;
      max-width: 500px !important;
    }
  }
`;
