import Slider from 'react-slick';

import { items } from './utils/sliderContent';

import Icon from '../../assets/icons/yoga-icon.svg';

import { Container } from './styles';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';

export function CommunityRating({ showButton, greenBackground }) {
  return (
    <Container
      className={`community-rating ${
        greenBackground && 'community-rating--green-background'
      }`}
    >
      <div className="community-rating__icon">
        <img src={Icon} alt="Yoga Co." />
      </div>
      <h2 className="community-rating__title">
        Veja o que nossa comunidade
        <b className="community-rating__b-title">está falando:</b>
      </h2>

      <div>
        <Slider
          infinite
          accessibility
          arrows={false}
          dots
          draggable
          slidesToScroll={1}
          customPaging={() => <div className="community-rating__slider-dot" />}
          speed={500}
          autoplay
        >
          {items.map((item) => (
            <div key={item.id}>
              <div className="community-rating__slider-item">
                <article className="community-rating__slider-article">
                  <div className="community-rating__slider-article__image-wrapper">
                    <img
                      src={item.image}
                      alt="User"
                      className="community-rating__slider-article__image"
                    />
                  </div>
                  <div className="community-rating__slider-article__content">
                    <div className="community-rating__slider-article__username-container">
                      <p className="community-rating__slider-article__user">
                        <span className="community-rating__slider-article__username">
                          {item.name}
                        </span>
                      </p>
                    </div>
                    <p className="community-rating__slider-article__commentary">
                      "{item.commentary}"
                    </p>
                  </div>
                </article>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {showButton && greenBackground && (
        <PrimaryButton href={'/escolha-um-plano'}>
          Experimente grátis por 7 dias
        </PrimaryButton>
      )}

      {showButton && !greenBackground && (
        <SecondaryButton href={'/escolha-um-plano'}>
          Experimente grátis por 7 dias
        </SecondaryButton>
      )}
    </Container>
  );
}
