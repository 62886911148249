import React, { useEffect, useState } from 'react';

import api from '../../../../services/api';

import Content from '../../../../components/Content';
import Slider3D from './components/Slider3D';
import CardLatestPodcastSerieReleased from './components/CardLatestPodcastSerieReleased';

import {
  Container,
  Spinner,
  TitleContainer,
  ExclusiveContent,
  Description,
  ContainerSlider,
  TextMostRecentEpisodes,
  ContainerLatestSeries,
} from './styles';

const ExclusivePodcasts = ({ allPodcastsAndMeditations }) => {
  const [featuredPodcast, setFeaturedPodcast] = useState({});
  const [featuredPodcastLessons, setFeaturedPodcastLessons] = useState([
    {
      id: '',
      name: '',
      url: '',
      podcast_id: '',
      description: '',
      duration: 0,
      thumbnail: '',
    },
  ]);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadPodcastLessons = async (idPodcastSerie) => {
    setShowSpinner(true);
    try {
      const response = await api.get(
        `/podcast-series/${idPodcastSerie}/episodes/published?limit=1000`,
      );

      setFeaturedPodcastLessons(response.data.episodes);
    } catch (error) {}
    setShowSpinner(false);
  };

  const loadFeaturedPodcast = () => {
    if (allPodcastsAndMeditations.length > 0) {
      let vectorReverse = allPodcastsAndMeditations.reverse();
      for (let item of vectorReverse) {
        if (
          item.type === 'podcast' &&
          item.id !== process.env.REACT_APP_EXCLUSIVE_PODCAST_EXCLUSIVE
        ) {
          setFeaturedPodcast(item);
          loadPodcastLessons(item.id);
          break;
        }
      }
    }
  };

  function renderTitle() {
    if (JSON.stringify(featuredPodcast) !== '{}') {
      return (
        <TitleContainer>
          <div>PODCAST</div>
          <div>{featuredPodcast.title}</div>
        </TitleContainer>
      );
    }
  }

  useEffect(() => {
    loadFeaturedPodcast();
  }, [allPodcastsAndMeditations]);

  return (
    <Container>
      <Content>
        <ExclusiveContent>CONTEÚDO EXCLUSIVO</ExclusiveContent>
        {renderTitle()}
        <Description>Um novo episódio toda Sexta-Feira.</Description>
      </Content>
      <ContainerSlider>
        <Content>
          <CardLatestPodcastSerieReleased
            featuredPodcast={featuredPodcast}
            featuredPodcastLessons={featuredPodcastLessons}
          />
          <ContainerLatestSeries id="podcast-session">
            <TextMostRecentEpisodes>
              EPISÓDIOS MAIS RECENTES
            </TextMostRecentEpisodes>
            <Slider3D lessons={featuredPodcastLessons} loading={showSpinner} />
          </ContainerLatestSeries>
        </Content>
      </ContainerSlider>
    </Container>
  );
};

export default ExclusivePodcasts;
