import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import Button from '../../../../components/Button';
import Card from './components/Card';

import bankSlip from '../../../../assets/profile/bank-slip.png';
import creditCards from '../../../../assets/profile/credit-cards.png';

import {
  Container,
  Row,
  AddButton,
  AddCard,
  CreditCardBanners,
  Tooltip,
  TooltipPaymentOption,
} from './styles';

const AddCreditCard = ({
  plan,
  informations,
  setShowFormDataCreditCard,
  setShowLoadModal,
  userHasPaymentOptions,
  setShowModalSuccessPayment,
  paymentOptionChosen,
  setShowAddCardModal,
  setShowGenerateCodeModal,
}) => {
  const history = useHistory();
  const [errorMensage, setErrorMensage] = useState('');
  const [errorChosePaymentOption, setErrorChosePaymentOption] = useState('');

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  function renderErrorChosePaymentOption() {
    if (errorChosePaymentOption) {
      return (
        <TooltipPaymentOption>{errorChosePaymentOption}</TooltipPaymentOption>
      );
    }
  }

  return (
    <Container>
      <Row onClick={() => setShowAddCardModal(true)}>
        <AddButton>
          <span>+</span>
        </AddButton>
        <AddCard>Pagar com cartão</AddCard>
      </Row>
      {renderErrorChosePaymentOption()}
      <Card informations={informations} />
      <CreditCardBanners
        src={creditCards}
        alt="Bandeira da Visa, MasterCard, Elo e HiperCard"
      />
      {/* <Button
        iconLeft={bankSlip}
        onClick={() =>
          history.push({
            pathname: '/confirmar-endereco',
            state: { plan, operation: 'signPlan', userPlan: { id: '' } },
          })
        }
      >
        Pagar por boleto bancário
      </Button> */}
      {renderErrorMensage()}
    </Container>
  );
};

export default AddCreditCard;
