import { Container, Title, Description, Promotion } from './_productCard';

export const ProductCard = () => {
  // const handleSelectedPlan = (id) => {
  //   localStorage.setItem('YogaCO@ChoosedPlan', id);
  //   setSelectedPlan(id);
  // };

  return (
    <Container>
      <Title>
        A MELHOR MENTORIA
        <span>da sua vida!</span>
      </Title>

      <Description>
        <div>
          De <s>R$ 2200,00</s>
        </div>
      </Description>
      <Promotion>
        <div>Por apenas:</div>
        <div>R$ 770,00</div>
        <span>ou</span>
        <div>11x de R$ 77,00</div>
      </Promotion>
    </Container>
  );
};
