import React, { useState } from 'react';

import FavoriteButton from '../../../../../../components/FavoriteButton';
import CheckIfClassCompleted from '../../../../../../components/CheckIfClassCompleted';
import Scheduling from '../../../../../../components/Scheduling';

import thumbnail1 from '../../../../../../assets/carousel/thumbnail1.png';
import checked from '../../../../../../assets/myLessons/checked-circle.png';
import durationIcon from '../../../../../../assets/favorites/duration.png';
import favourite from '../../../../../../assets/myLessons/favourite-heart-icon.png';
import calendar from '../../../../../../assets/myLessons/calendar-icon.png';

import {
  CardContainer,
  Thumbnail,
  Description,
  Info,
  Options,
  MenuMobile,
} from './styles';

const LessonCard = ({ lesson, setShowLoadModal }) => {
  const [showSchedulingComponent, setShowSchedulingComponent] = useState(false);

  function renderDuration() {
    const { duration } = lesson.post.lesson;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <span>
            <img src={durationIcon} alt="Ícone de tempo da aula" />
            {duration} min
          </span>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <span>
            <img src={durationIcon} alt="Ícone de tempo da aula" />
            {hours} {hours === 1 ? 'hora' : 'horas'}
            {minutes > 0 && ` e ${minutes} minutos`}
          </span>
        );
      }
    }
  }

  function renderSchedulingComponent() {
    if (showSchedulingComponent) {
      return (
        <Scheduling
          type="aula"
          show={showSchedulingComponent}
          setShow={setShowSchedulingComponent}
          postId={lesson.post.id}
        />
      );
    }
  }

  return (
    <CardContainer>
      <Thumbnail>
        <img src={lesson.post.lesson.thumbnail_url} />
      </Thumbnail>
      <Description>
        <Info>
          <b>
            <CheckIfClassCompleted
              completed={lesson.completed}
              post_id={lesson.post.id}
              setShowLoadModal={setShowLoadModal}
            />
            <h1>{lesson.post.lesson.name}</h1>
          </b>
          <MenuMobile>
            <CheckIfClassCompleted
              completed={lesson.completed}
              post_id={lesson.post.id}
              setShowLoadModal={setShowLoadModal}
            />
            <FavoriteButton
              post_id={lesson.post.id}
              is_favorite={lesson.is_favorite}
              favorite_id={lesson.favorite_id}
              setShowLoadModal={setShowLoadModal}
            />
            <img
              src={calendar}
              onClick={() => setShowSchedulingComponent(true)}
            />
          </MenuMobile>
          {renderDuration()}
        </Info>

        <Options>
          <FavoriteButton
            post_id={lesson.post.id}
            is_favorite={lesson.is_favorite}
            favorite_id={lesson.favorite_id}
            setShowLoadModal={setShowLoadModal}
          />
          <img
            src={calendar}
            onClick={() => setShowSchedulingComponent(true)}
          />
        </Options>
      </Description>
      {renderSchedulingComponent()}
    </CardContainer>
  );
};

export default LessonCard;
