import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Switch,
  Router,
  useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import history from '../utils/history';

import Route from './Route';

import { LandingPage } from '../pages/LandingPage/LandingPage';
import { NewHomePage } from '../pages/NewHomePage/NewHomePage';

import AboutUs from '../pages/AboutUs';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ChangePassword from '../pages/ChangePassword';
import SignUp from '../pages/SignUp';
import Profile from '../pages/Profile';
import Plans from '../pages/Plans';
import ChangePlan from '../pages/ChangePlan';
import Serie from '../pages/Serie';
import Class from '../pages/Class';
import ChoosePlan from '../pages/ChoosePlan';
import Payment from '../pages/Payment';
import Congratulations from '../pages/Congratulations';
import NotFound from '../pages/NotFound';
import Sunflower from '../pages/SunflowerStudent';
import DataToPayWithBankSlip from '../pages/DataToPayWithBankSlip';
import MyCalendar from '../pages/MyCalendar';
import FrequentlyAskedQuestions from '../pages/FrequentlyAskedQuestions';
import Favorites from '../pages/Favorites';
import MyLessons from '../pages/MyLessons';
import GiftFriend from '../pages/GiftFriend';
import GiftFriendPayment from '../pages/GiftFriendPayment';
import ContactUs from '../pages/ContactUs';
import UserConfiguration from '../pages/UserConfiguration';
import CreateMyDiary from '../pages/CreateMyDiary';
import MyDiary from '../pages/MyDiary';
import EditMyDiary from '../pages/EditMyDiary';
import Notifications from '../pages/Notifications';
import TermsOfUseAndPrivacy from '../pages/TermsAndConditions';
import { MentorshipPayment } from '../pages/MentorshipPayment/MentorshipPayment';
import { MentorshipThankPage } from '../pages/MentorshipThankPage/MentorshipThankPage';

const Routes = () => {
  // const appHistory = createBrowserHistory();

  return (
    // <Router history={history}>
    <HashRouter>
      <Switch>
        {/* <Route component={LandingPage} path="/" exact /> */}
        <Route component={AboutUs} path="/sobre-nos" isPrivateAndPublic />
        <Route component={ContactUs} path="/contato" isPrivateAndPublic />
        <Route component={UserConfiguration} path="/configuracao" isPrivate />
        <Route path="/" component={NewHomePage} exact />
        <Route component={Home} path="/home" isPrivate exact />
        <Route component={Login} path="/login" />
        <Route component={ChangePassword} path="/alterar-senha" />
        <Route component={SignUp} path="/cadastro" />
        <Route component={Profile} path="/perfil" isPrivate />
        <Route component={Plans} path="/meu-plano" isPrivate />
        <Route component={ChangePlan} path="/alterar-plano" isPrivate />
        <Route component={Serie} path="/serie/:id" isPrivateAndPublic />
        <Route component={Serie} path="/podcast/:id" isPrivateAndPublic exact />
        <Route
          component={Serie}
          path="/meditacao/:id"
          isPrivateAndPublic
          exact
        />
        <Route component={Class} path="/aula/:id" isPrivateAndPublic />
        <Route component={Class} path="/podcast/aula/:id" isPrivateAndPublic />
        <Route
          component={Class}
          path="/meditacao/aula/:id"
          isPrivateAndPublic
        />
        <Route
          component={ChoosePlan}
          path="/escolha-um-plano"
          isPrivateAndPublic
        />
        <Route component={Payment} path="/pagamento" isPrivate />
        <Route
          component={Congratulations}
          path="/parabens"
          isPrivateAndPublic
        />
        <Route component={Sunflower} path="/usuario-girassol" />
        <Route
          component={DataToPayWithBankSlip}
          path="/confirmar-endereco"
          isPrivate
        />
        <Route component={MyCalendar} path="/calendario" isPrivate />
        <Route
          component={FrequentlyAskedQuestions}
          path="/perguntas-frequentes"
          isPrivateAndPublic
        />
        <Route component={Favorites} path="/favoritos" isPrivate />
        <Route component={MyLessons} path="/minhas-aulas" isPrivate />
        <Route component={GiftFriend} path="/presenteie-um-amigo" isPrivate />
        <Route
          component={GiftFriendPayment}
          path="/pagamento-presenteie-um-amigo"
          isPrivate
        />
        <Route
          component={CreateMyDiary}
          path="/criar-meu-diario"
          isPrivateAndPublic
        />
        <Route component={MyDiary} path="/meu-diario" isPrivate />
        <Route
          component={EditMyDiary}
          path="/editar-meu-diario"
          isPrivate
          exact
        />
        <Route
          component={EditMyDiary}
          path="/editar-meu-diario/:idNote"
          isPrivate
        />
        <Route component={Notifications} path="/notificacoes" isPrivate />
        <Route
          component={TermsOfUseAndPrivacy}
          path="/termos-de-uso-e-privacidade"
          isPrivateAndPublic
        />
        <Route
          component={MentorshipPayment}
          path="/mentoria"
          isPrivateAndPublic
        />
        <Route
          component={MentorshipThankPage}
          path="/parabens-pelo-grande-passo"
          isPrivateAndPublic
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </HashRouter>
    // </Router>
  );
};

export default Routes;
