import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import {
  Container,
  Notification,
  UserPhotoContainer,
  IconContainer,
  Informations,
  Time,
} from './styles';

const NotificationModal = ({
  componentIsFocused,
  notifications,
  handleClickNotification,
}) => {
  const renderIcon = (notification, index) => {
    if (notification.type === 'like') {
      if(notification.is_admin){
        return <FaHeart size={10} />;
      }
      return <AiFillLike size={10} />;
    } else {
      return <FaCommentDots size={10} />;
    }
  };

  const renderNotifications = () => {
    return notifications.map((notification, index) => (
      <Notification
        key={notification.id}
        onClick={() => handleClickNotification()}
      >
        <UserPhotoContainer>
          <img src={notification.avatar_url} alt="Foto de perfil do usuário" />
          <IconContainer>{renderIcon(notification, index)}</IconContainer>
        </UserPhotoContainer>
        <Informations>
          <span>{notification.name}</span> {notification.content}
        </Informations>
        <Time>
          {formatDistance(new Date(notification.created_at), new Date(), {
            locale: ptBR,
          })}
        </Time>
      </Notification>
    ));
  };

  return (
    <Container componentIsFocused={componentIsFocused}>
      {renderNotifications()}
    </Container>
  );
};

export default NotificationModal;
