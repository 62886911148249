import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import TermsAndConditions from '../../components/TermsAndConditions';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';

import backIcon from '../../assets/choosePlan/backIcon.png';
import birthDateMask from '../../utils/birthDateMask';

import {
  Container,
  GradientBackground,
  FreeTrialMessage,
  Row,
  Tooltip,
} from './styles';

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    birthDate: '',
    confirmPassword: '',
  });
  const [differentPasswords, setDifferentPasswords] = useState(false);
  const [checkedTermsOfUse, setCheckedTermsOfUse] = useState(false);
  const [visibleTermsAndConditions, setVisibleTermsAndConditions] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    comparePasswordAndPasswordConfirmation();
    window.scrollTo(0, 0);
  }, [formData.password, formData.confirmPassword]);

  function renderTermsAndConditions() {
    if (visibleTermsAndConditions) {
      return (
        <TermsAndConditions
          setChecked={setCheckedTermsOfUse}
          checked={checkedTermsOfUse}
          setVisible={setVisibleTermsAndConditions}
        />
      );
    }
  }

  function comparePasswordAndPasswordConfirmation() {
    const password = formData.password;
    const confirmPassword = formData.confirmPassword;

    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password !== confirmPassword
    ) {
      setDifferentPasswords(true);
    } else {
      setDifferentPasswords(false);
    }
  }

  function renderTooltip() {
    if (errorMessage !== '') {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (differentPasswords) {
      setErrorMessage(
        'Os campos de senha e confirmação da senha estão diferentes',
      );
      return;
    }

    if (!checkedTermsOfUse) {
      setErrorMessage('Você precisa ler e concordar com os termos de uso');
      return;
    }

    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.password.trim() ||
      !formData.confirmPassword.trim() ||
      !formData.birthDate.trim()
    ) {
      setErrorMessage('Preencha os campos corretamente');
      return;
    }

    setErrorMessage('');
    // setShowLoadModal(true);

    const year = Number(formData.birthDate.slice(6, 10));
    const month = Number(formData.birthDate.slice(3, 5)) - 1;
    const day = Number(formData.birthDate.slice(0, 2));

    const birthDateIsoString = new Date(year, month, day).toISOString();

    try {
      const response = await api.post('/users', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        birthday: birthDateIsoString,
      });

      if (response) {
        dispatch({
          type: 'yoga-adm/user/get-user-data',
          payload: response.data,
        });

        history.push('/pagamento');
      }
    } catch (error) {
      const message = error.response.data?.message || error.toString();
      setErrorMessage(message);
    } finally {
      setShowLoadModal(false);
    }
  };

  return (
    <Container>
      <Header />
      <GradientBackground />
      <Content>
        <FreeTrialMessage>TESTE GRÁTIS POR 7 DIAS</FreeTrialMessage>
        <h1>Criar conta</h1>
        <form onSubmit={(event) => handleSubmit(event)}>
          <Input
            placeholder="Nome completo"
            required
            onInput={(event) =>
              setFormData({ ...formData, name: event.target.value })
            }
          />

          <Input
            type="email"
            placeholder="Email"
            required
            onInput={(event) =>
              setFormData({ ...formData, email: event.target.value })
            }
          />

          <Input
            placeholder="Data de nascimento"
            required
            value={formData.birthDate}
            onInput={(event) =>
              setFormData({
                ...formData,
                birthDate: birthDateMask(event.target.value),
              })
            }
          />

          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            required
            onInput={(event) =>
              setFormData({ ...formData, password: event.target.value })
            }
            error={differentPasswords}
            showEye={true}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />

          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirmar Senha"
            required
            onInput={(event) =>
              setFormData({ ...formData, confirmPassword: event.target.value })
            }
            error={differentPasswords}
            showEye={true}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
          <span
            onClick={() =>
              setVisibleTermsAndConditions(!visibleTermsAndConditions)
            }
          >
            Ler Termos de uso
          </span>
          <Row>
            <Checkbox
              checked={checkedTermsOfUse}
              setChecked={setCheckedTermsOfUse}
            />
            <label>Li e concordo com os termos de uso</label>
          </Row>
          {renderTooltip()}
          <Button>Finalizar cadastro e pagar</Button>
        </form>
        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
      </Content>
      <Footer />
      {renderTermsAndConditions()}
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default SignUp;
