import RightArrow from '../../../assets/icons/slider-arrow-right.svg';
import LeftArrow from '../../../assets/icons/slider-arrow-left.svg';

export const settings = {
  infinite: true,
  accessibility: true,
  arrows: true,
  touchMove: true,
  centerMode: true,
  className: 'faq__slider',
  slidesToShow: 1,
  speed: 500,
  autoplay: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  nextArrow: (
    <button type="button">
      <img src={RightArrow} alt="Slider arrow" />
    </button>
  ),
  prevArrow: (
    <button type="button">
      <img src={LeftArrow} alt="Slider arrow" />
    </button>
  ),
};
