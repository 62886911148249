import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 380px;
  padding: 35px 0;
  margin: 84px auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: 'Aller-Light';
    font-size: 1.7rem;
    color: #24B424;
    letter-spacing: 4.65px;
    text-transform: uppercase;
    margin: 0 auto;
    margin-bottom: 42px;
  }

  img {
    margin: 0 auto;
    max-width: 170px;
  }
`;

export const Content = styled.div`
  max-width: 935px;
  margin: 0 auto;

  h1 {
    font-family: 'Aller-Bold';
    color: #24B424;
  }

  p {
    font-family: 'Aller-Light';
    color: #6C6C6C;
    font-size: 1.875rem;
  }
`;
