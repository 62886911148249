import React, { useState, useEffect } from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import Class from '../Card';

import { Container, Pagination, NextClass } from './styles';

const ListCard = ({
  currentLesson,
  nextLessons,
  pathname,
  setShowLoadModal,
  setShowNoAccessModal,
  checkIfUserHasAnActivePlan,
}) => {
  const [lessons, setLessons] = useState([]);

  const loadNextClass = () => {
    let foundNextLesson = false;
    let foundTheCurrentClass = false;
    let lessonsVector = [];
    nextLessons.map((item) => {
      if (foundTheCurrentClass && foundNextLesson) {
        lessonsVector.push(item);
      }

      if (foundTheCurrentClass) {
        foundNextLesson = true;
      }

      if (item.id === currentLesson.id) {
        foundTheCurrentClass = true;
      }
    });

    setLessons(lessonsVector);
  };

  useEffect(() => {
    loadNextClass();
  }, [currentLesson, nextLessons]);

  return (
    <Container>
      {lessons.length > 0 && <div>Próximos</div>}
      {lessons.map((item) => (
        <Class
          pathname={pathname}
          lesson={item}
          setShowLoadModal={setShowLoadModal}
          setShowNoAccessModal={setShowNoAccessModal}
          checkIfUserHasAnActivePlan={checkIfUserHasAnActivePlan}
        />
      ))}
    </Container>
  );
};

export default ListCard;
