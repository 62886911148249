import styled from 'styled-components';

import tv_mockup from '../../assets/images/tv_mockup.png';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 4.2125rem 0 6.1rem;

  position: relative;

  background: rgb(72, 226, 91);
  background: var(--purple-gradient);

  img {
    max-width: 100%;
  }
  .app-about__phone {
    width: 38.704375rem;
    height: 50.984375rem;

    display: none;

    position: absolute;
    top: 1.2rem;
    left: -7rem;

    transform: rotate(-10deg);

    @media (min-width: 1079px) {
      display: block;
    }
  }

  .app-about__article {
    width: 95%;
    margin: 0 auto;

    @media (min-width: 1079px) {
      width: calc(95% - 30rem);
      margin: 0 0 0 30rem;
    }
  }

  .app-about__article-card {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 500px;
    min-height: 3rem;

    margin: 0 auto;

    color: var(--purpleA1);
    text-align: center;
    font-size: 1.3125rem;
    text-transform: uppercase;
    letter-spacing: 2px;

    border-radius: 12px;
    border: 1px solid var(--purpleA1);
  }

  .app-about__article-about {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    /* max-width: 30.5rem; */

    margin: 1.89375rem auto 0;

    color: var(--dark70);
    font-family: 'Aller-Light';
    font-size: 1.875rem;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3rem;
  }

  .app-about__article-about-b {
    display: inline-block;

    color: var(--green24);
    font-family: 'Bartolomeo-regular';
    font-size: 5rem;
    text-transform: lowercase;

    transform: rotate(-15deg);
  }

  .app-about__article-paragraph {
    width: 100%;
    max-width: 37.5rem;
    margin: 1.78125rem auto 0;

    font-family: 'Aller-Light';
    font-size: 1.875rem;
    text-align: center;
  }

  .app-about__article-paragraph-b {
    color: var(--green24);
    font-family: 'Aller-Bold';
  }

  .app-about__tv {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 300px;
    margin: 1em auto 0;
    background: no-repeat top;
    background-size: 92%;
    background-image: url(${tv_mockup});
  }

  .app-about__video-container {
    position: absolute;
    width: 88.1%;
    padding-top: 50.25%;
    overflow: hidden;

    margin: 0 auto;

    top: 2.2%;
    left: 4.7%;
    right: 5.2%;

    @media (max-width: 500px) {
      top: 1.6%;
    }
  }

  .app-about__article-video {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
  }

  .secondary-anchor {
    font-size: 1.8rem;
    max-width: 500px;

    margin: 2rem auto;

    @media (max-width: 400px) {
      margin-top: -3em;
    }
  }

  .app-about__article-anchor-text {
    color: #fff;
  }
`;
