import { Container, Title, Answer } from './styles';

const Question = ({ title, answer }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Answer>{answer}</Answer>
    </Container>
  );
};

export default Question;
