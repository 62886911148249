import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import AllerBold from '../assets/fonts/AllerBold.ttf';
import AllerLight from '../assets/fonts/AllerLightThin.ttf';
import AllerRegular from '../assets/fonts/AllerRegular.ttf';
import BartolomeoRegular from '../assets/fonts/BartolomeoRegular.ttf';
import BartolomeoBold from '../assets/fonts/BartolomeoBold.ttf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import AquawaxBold from '../assets/fonts/aquawax-bold.ttf';
import AquawaxRegular from '../assets/fonts/Aquawax_Regular-trial.ttf';

export default createGlobalStyle`
:root{
  --dark06: #060606;
  --dark70: #707070;
  --green6E: #6EE27C;
  --green74: #74DF80;
  --green44: #44CF52;
  --green4F: #4FCE5C;
  --green24: #24B424;
  --green2F: #2FB72F;
  --green00: #007E00;
  --green16: #165011;
  --green0F: #0F3601;
  --greyBF: #BFBFBF;
  --grey9E: #9E9E9E;
  --redFE: #FE7171;
  --yellowF8: #F8C845;
  --yellowFF: #FFC270;
  --purple84: #8430CA;
  --purpleA1: #A16CCD;
  --purple-gradient:   linear-gradient(
    0deg,
    rgba(132, 48, 202, 0.35898109243697474) 0%,
    rgba(234, 246, 234, 0.09) 10%,
    rgba(255, 252, 252, 0.03) 65%,
    rgba(255, 255, 255, 0) 100%
  );
  --green-gradient: linear-gradient( 0deg, 
    rgba(72,226,91,0.5242471988795518) -10%, 
    rgba(161,237,169,0.3981967787114846) 6%, 
    rgba(234,246,234,0.09) 19%, 
    rgba(255,252,252,0.03) 65%, 
    rgba(255,255,255,0) 100% )
}

  * {
    margin: 0;
    padding: 0;
    
    box-sizing: border-box;
    outline-color: transparent !important;
  }

  a {
    text-decoration: none !important;
    color: inherit !important;
  }

  html {
    font-size: 16px;
    color: var(--dark70);


    @media (max-width: 1080px) {
        font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }

    @media (max-width: 561px) {
      font-size: 75%;
    }

    @media (max-width: 520px) {
      font-size: 70%;
    }

    @media (max-width: 460px) {
      font-size: 55%;
    }

    @media (max-width: 380px) {
      font-size: 45%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }



    /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E9E9E9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #24B424;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #24B424;
}


  @font-face {
    font-family: Aller-Bold;
    src: local('Aller-Bold'), url(${AllerBold});
  }

  @font-face {
    font-family: Aller-Light;
    src: local('Aller-Light'), url(${AllerLight});
  }

  @font-face {
    font-family: Aller-Regular;
    src: local('Aller-Regular'), url(${AllerRegular});
  }

  @font-face {
    font-family: Bartolomeo-Regular;
    src: local('Bartolomeo-Regular'), url(${BartolomeoRegular});
  }

  @font-face {
    font-family: Bartolomeo-Bold;
    src: local('Bartolomeo-Bold'), url(${BartolomeoBold});
  }

  @font-face {
    font-family: Roboto-Regular;
    src: local('Roboto-Regular'), url(${RobotoRegular});
  }

  @font-face {
    font-family: Aquawax-Bold;
    src: local('Aquawax-Bold'), url(${AquawaxBold});
  }

  @font-face {
    font-family: Aquawax-Regular;
    src: local('Aquawax-Regular'), url(${AquawaxRegular});
  }

  @keyframes rendering{
    from{
      opacity:0
      }
    to{
        opacity:1
    }
  }

  @keyframes hero_slide{
    from{
      margin-right: -94%;
      }
    to{
      margin-right: 0;
    }
  }
  
`;
