import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logoutUserAutomatically from '../../utils/logoutUserAutomatically';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import PersonalData from './components/PersonalData';
import Payment from './components/Payment';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';

import editIcon from '../../assets/profile/edit.png';

import {
  Container,
  GradientBackground,
  Row,
  UserPhoto,
  Title,
  UpdateUserPhoto,
  Name,
  Options,
  Menu,
  Tooltip,
} from './styles';

const Profile = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.User.id);
  const history = useHistory();
  let inputUpdateAvatarPhoto = useRef();
  const [chosenMenu, setChosenMenu] = useState('Personal Data');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');
  const [userData, setUserData] = useState({
    avatar_url: '',
    name: '',
    email: '',
  });
  const [userPlan, setUserPlan] = useState({});

  function renderChosenMenu() {
    switch (chosenMenu) {
      case 'Personal Data':
        return <PersonalData userData={userData} setUserData={setUserData} />;
      case 'Payment':
        if (
          JSON.stringify(userPlan) === '{}' ||
          userPlan.status === 'pending' ||
          userPlan.status === 'suspended'
        ) {
          history.push('/escolha-um-plano');
          return;
        }
        return (
          <Payment userPlan={userPlan} setShowLoadModal={setShowLoadModal} />
        );
    }
  }

  function checkIfTheFileIsAnImage() {
    const file = inputUpdateAvatarPhoto.files[0];
    return file.type.includes('image');
  }

  async function uploadProfilePhoto(event) {
    if (!checkIfTheFileIsAnImage()) {
      setErrorMensage(
        'Para alterar a foto de perfil selecione um arquivo do tipo imagem',
      );
      return;
    }

    setErrorMensage('');
    setShowLoadModal(true);

    const formData = new FormData();
    formData.append('avatar', inputUpdateAvatarPhoto.files[0]);

    try {
      const response = await api.patch(`users/${id}/avatar`, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });
      dispatch({
        type: 'yoga-web/user/get-user-profile-photo',
        payload: response.data,
      });
      const newAvatarURL = response.data.avatar_url;
      setUserData({ ...userData, avatar_url: newAvatarURL });
    } catch (error) {}

    setShowLoadModal(false);
  }

  const loadUserInformations = async () => {
    if (!id.trim()) {
      return;
    }

    setShowLoadModal(true);

    try {
      const response = await api.get(`/users/${id}`);
      setUserData(response.data);
      checkIfUserHasAnActivePlan();
    } catch (error) {
      setShowLoadModal(false);
    }
  };

  const checkIfUserHasAnActivePlan = async () => {
    try {
      const response = await api.get(`/users/plans`);
      setUserPlan(response.data);
    } catch (error) {}
    setShowLoadModal(false);
  };

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  const handleClickPlansOption = () => {
    if (JSON.stringify(userPlan) === '{}' || userPlan.status === 'pending') {
      history.push('/escolha-um-plano');
      return;
    } else {
      history.push('/meu-plano');
    }
  };

  useEffect(() => {
    if (logoutUserAutomatically()) {
      dispatch({ type: 'yoga-adm/user/logout' });
      history.push('/login');
    }
    loadUserInformations();

    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.async = true;

    document.body.appendChild(script);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <GradientBackground />
      <Content>
        <Row>
          <UserPhoto src={userData.avatar_url} alt="Foto do usuário" />
          <Title>
            meu <span>perfil</span>
          </Title>
        </Row>
        <UpdateUserPhoto onClick={() => inputUpdateAvatarPhoto.click()}>
          Alterar foto de perfil
          <img src={editIcon} alt="Ícone de editar foto do perfil" />
          <input
            type="file"
            accept="image/*"
            ref={(input) => (inputUpdateAvatarPhoto = input)}
            onInput={(event) => uploadProfilePhoto(event)}
          />
        </UpdateUserPhoto>
        {renderErrorMensage()}
        <Menu>
          <div>
            <Name>{userData.name}</Name>
            <Options chosenMenu={chosenMenu}>
              <ul>
                <li onClick={() => setChosenMenu('Personal Data')}>
                  Dados pessoais
                </li>
                <li onClick={() => setChosenMenu('Payment')}>Pagamento</li>
                <li onClick={() => handleClickPlansOption()}>Planos</li>
              </ul>
            </Options>
          </div>
          {renderChosenMenu()}
        </Menu>
      </Content>
      <Footer />
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Profile;
