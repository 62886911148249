import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';

import CheckIfClassCompleted from '../../../../components/CheckIfClassCompleted';
import FavoriteButton from '../../../../components/FavoriteButton';
import Scheduling from '../../../../components/Scheduling';

import thumbnailClass from '../../../../assets/serie/thumbnail-class.png';
import time from '../../../../assets/serie/time.png';
import favorite from '../../../../assets/serie/favorite.png';
import calendar from '../../../../assets/serie/calendar.png';

import {
  Container,
  Thumbnail,
  Informations,
  ClassAttendedIcon,
  TitleContainer,
  Description,
  Options,
  ContainerCheckedIconDesktop,
  MenuMobile,
} from './styles';

const Class = ({
  lesson,
  pathname,
  setShowLoadModal,
  setShowNoAccessModal,
  checkIfUserHasAnActivePlan,
}) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const [assistedClass, setAssistedClass] = useState(false);
  const [showSchedulingComponent, setShowSchedulingComponent] = useState(false);

  const handleSchedule = async () => {
    if (!user.id.trim() || !user.accessToken.trim()) {
      setShowLoadModal(false);
      setShowNoAccessModal(true);
      return;
    }

    setShowLoadModal(true);

    const userHasAccess = await checkIfUserHasAnActivePlan();

    setShowLoadModal(false);

    if (userHasAccess) {
      setShowSchedulingComponent(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  function renderAssistedClassIcon() {
    return (
      <ContainerCheckedIconDesktop>
        <CheckIfClassCompleted
          completed={lesson.is_completed}
          post_id={lesson.post_id}
          setShowLoadModal={setShowLoadModal}
        />
      </ContainerCheckedIconDesktop>
    );
  }

  function renderDuration() {
    const { duration } = lesson;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>{duration} min</span>
          </div>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>
              {hours} {hours === 1 ? 'hora' : 'horas'}
              {minutes > 0 && ` e ${minutes} minutos`}
            </span>
          </div>
        );
      }
    }
  }

  const handleCard = () => {
    if (pathname.includes('podcast')) {
      history.push({
        pathname: `/podcast/aula/${lesson.id}`,
      });
    } else if (pathname.includes('meditacao')) {
      history.push({
        pathname: `/meditacao/aula/${lesson.id}`,
      });
    } else {
      history.push({
        pathname: `/aula/${lesson.id}`,
      });
    }
  };

  function renderSchedulingComponent() {
    if (showSchedulingComponent) {
      return (
        <Scheduling
          type="aula"
          show={showSchedulingComponent}
          setShow={setShowSchedulingComponent}
          postId={lesson.post_id}
        />
      );
    }
  }

  function renderCard() {
    if (lesson !== undefined) {
      return (
        <Container>
          <div>
            <Thumbnail
              onClick={() => handleCard()}
              src={lesson.thumbnail_url}
              alt="Thumbnail da aula"
            />
            <div>
              {renderAssistedClassIcon()}
              <Informations>
                <TitleContainer onClick={() => handleCard()}>
                  <div>{lesson.name}</div>
                </TitleContainer>
                <MenuMobile>
                  <CheckIfClassCompleted
                    completed={lesson.is_completed}
                    post_id={lesson.post_id}
                    setShowLoadModal={setShowLoadModal}
                  />
                  <FavoriteButton
                    post_id={lesson.post_id}
                    is_favorite={lesson.is_favorite}
                    favorite_id={lesson.favorite_id}
                    setShowLoadModal={setShowLoadModal}
                  />
                  <img src={calendar} onClick={() => handleSchedule()} />
                </MenuMobile>
                <Description>
                  {renderDuration()}
                  <p>{lesson.description}</p>
                </Description>
              </Informations>
            </div>
          </div>
          <Options>
            <FavoriteButton
              post_id={lesson.post_id}
              is_favorite={lesson.is_favorite}
              favorite_id={lesson.favorite_id}
              setShowLoadModal={setShowLoadModal}
            />
            <img src={calendar} onClick={() => handleSchedule()} />
          </Options>
          {renderSchedulingComponent()}
        </Container>
      );
    }
  }

  return <>{renderCard()}</>;
};

export default Class;
