import BackGround from '../../assets/images/benefits-background.png';
import Icon from '../../assets/icons/yoga-icon.svg';

import { items } from './utils/unorderedListItems';

import { Container } from './styles';

export function PlanBenefits() {
  return (
    <Container>
      <div className="plan-benefits__background">
        <img src={BackGround} alt="Yoga Co." />
      </div>
      <div className="plan-benefits__icon">
        <img src={Icon} alt="Yoga Co." />
      </div>
      <h2 className="plan-benefits__title">ASSINE A YOGA CO.</h2>
      <p className="plan-benefits__subtitle">
        Entre na nossa casa recheada de conteúdos de yoga que te ensinam a viver
        no momento presente
      </p>
      <div className="plan-benefits__card">
        <ul className="plan-benefits__card__ul">
          {items.map((item) => (
            <li key={item.text} className="plan-benefits__card__li">
              <img
                className="plan-benefits__card__image"
                src={item.icon}
                alt="List icon"
              />
              <h1 className="plan-benefits__card__text">{item.text}</h1>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
}
