import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Checkbox from '../../../../../../components/Checkbox';
import MessageModal from '../../../../../../components/MessageModal';

import api from '../../../../../../services/api';

import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import Card from './components/Card';

import creditCards from '../../../../../../assets/profile/credit-cards.png';
import iconBack from '../../../../../../assets/profile/icon-back.png';

import {
  Container,
  Title,
  Row,
  Form,
  InputRow,
  CreditCardBanners,
  Tooltip,
} from './styles';

const Modal = ({ setVisible, setShowLoadModal, userPlan }) => {
  const externalID = useSelector((state) => state.User.external_id);
  const form = useRef();
  const [informations, setInformations] = useState({
    name: '',
    number: '',
    cvv: '',
    duedate: '',
  });
  const [errorMensage, setErrorMensage] = useState('');
  const [recurringPayment, setRecurringPayment] = useState(true);
  const [showModalMessage, setShowModalMessage] = useState(false);

  const componentRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!componentRef.current.contains(e.target)) {
      setVisible(false);
      return;
    }
  };

  const createCardCredit = async (event) => {
    event.preventDefault();
    setShowLoadModal(true);

    const name = informations.name;
    const nameSplited = name.split(' ');
    const primaryName = nameSplited[0];
    let surname = '';
    for (let index = 1; index < nameSplited.length; index++) {
      surname += nameSplited[index];
      if (index < nameSplited.length - 1) {
        surname += ' ';
      }
    }

    try {
      window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT);

      const cc = window.Iugu.CreditCard(
        informations.number,
        informations.duedate.substring(0, 2),
        informations.duedate.substring(3, 7),
        primaryName,
        surname,
        informations.cvv,
      );

      if (!window.Iugu.utils.validateCreditCardNumber(informations.number)) {
        setErrorMensage('Preencha um número de cartão de crédito válido');
        setShowLoadModal(false);
        return;
      }

      const brand = window.Iugu.utils.getBrandByCreditCardNumber(
        informations.number,
      );

      if (!brand) {
        setErrorMensage('Não suportamos essa bandeira de cartão de crédito');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateCVV(informations.cvv, brand)) {
        setErrorMensage('Preencha um CVV válido');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateExpirationString(informations.duedate)) {
        setErrorMensage(
          'Preencha o campo “data de vencimento” no seguinte formato: MM/AAAA',
        );
        setShowLoadModal(false);
        return;
      }

      setErrorMensage('');

      window.Iugu.createPaymentToken(cc, function (response) {
        if (response.errors) {
          setShowLoadModal(false);

          const errorKeys = Object.keys(response.errors);
          const displayError = errorKeys[0];
          const genericError = 'verifique os dados e tente novamente';

          const error = {
            verification_value:
              'verifique se o CVV foi preenchido corretamente',
            number: 'verifique se o número foi preenchido corretamente',
            expiration: 'verifique se a data de expiração está correta',
            last_name: 'verifique se o sobrenome foi preenchido',
            first_name:
              'veirifique se o primeiro nome foi preenchido corretamente',
          };

          setErrorMensage(
            `Cartão inválido, ${error[displayError] || genericError}`,
          );
        } else {
          saveCreditCard(response.id);
        }
      });
    } catch (error) {
      setShowLoadModal(false);

      setErrorMensage('Houve um erro ao cadastrar seu cartão de crédito!');
    }
  };

  const saveCreditCard = async (tokenPayment) => {
    try {
      const response = await api.post(`/cards`, {
        description: 'Meu cartão de crédito',
        token: tokenPayment,
        // set_as_default: recurringPayment.toString(),
        set_as_default: true,
      });

      changePaymentMethod();
    } catch (error) {
      setShowLoadModal(false);

      setErrorMensage('Erro ao cadastrar seu cartão de crédito');
    }
  };

  const changePaymentMethod = async () => {
    try {
      const response = await api.patch(`/plans/${userPlan.id}/payment-method`, {
        payable_with: 'credit_card',
      });

      setShowModalMessage(true);
    } catch (error) {
      setErrorMensage(error.response.data?.message);
    }

    setShowLoadModal(false);
  };

  function renderModalMessage() {
    if (showModalMessage) {
      return (
        <MessageModal
          title="Cartão de Crédito cadastrado e método de pagamento alterado para usar o novo cartão cadastrado!"
          description=""
          showVisible={showModalMessage}
          setVisible={setShowModalMessage}
        />
      );
    }
  }

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  return (
    <Container ref={componentRef}>
      <Row>
        <Form ref={form} onSubmit={(event) => createCardCredit(event)}>
          <Title>Adicione os dados do seu cartão</Title>
          <Input
            placeholder="Nome do Titular"
            onInput={(event) =>
              setInformations({ ...informations, name: event.target.value })
            }
            required
          />
          <InputRow>
            <Input
              placeholder="Número do cartão"
              type="number"
              onInput={(event) =>
                setInformations({ ...informations, number: event.target.value })
              }
              required
            />
            <Input
              placeholder="CVV"
              type="number"
              onInput={(event) =>
                setInformations({ ...informations, cvv: event.target.value })
              }
              required
            />
          </InputRow>
          <Input
            placeholder="Mês/Ano de vencimento"
            onInput={(event) =>
              setInformations({ ...informations, duedate: event.target.value })
            }
            required
          />
          {renderErrorMensage()}
          <Button>Salvar Cartão</Button>
        </Form>
        <div>
          <Card informations={informations} />
          <CreditCardBanners
            src={creditCards}
            alt="Bandeira da Visa, MasterCard, Elo e HiperCard"
          />
        </div>
      </Row>
      <img
        onClick={() => setVisible(false)}
        src={iconBack}
        alt="Ícone de sair do modal de adicionar cartão"
      />
      {renderModalMessage()}
    </Container>
  );
};

export default Modal;
