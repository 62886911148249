import { AiFillLike } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import { FaHeart } from 'react-icons/fa';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import adminLike from '../../../../../../../../assets/notifications/admin-like.png';

import {
  Container,
  Notification,
  UserPhotoContainer,
  IconContainer,
  Informations,
  Time,
  Spinner,
  Row,
  AdminName,
} from './styles';

const LikesModal = ({ likes, showLoadingLikeList, setShowLikeList }) => {
  const renderName = (name, is_admin) => {
    if (!is_admin) {
      return <span>{name}</span>;
    }

    return (
      <AdminName>
        <img src={adminLike} alt="Íconde de curtida do admin" />
        {name}
      </AdminName>
    );
  };

  const renderIcon = (is_admin) => {
    if (is_admin) {
      return <FaHeart size={10} />;
    }

    return <AiFillLike size={10} />;
  };

  const renderLikes = () => {
    if (showLoadingLikeList) {
      return <Spinner />;
    }

    return likes.map((like, index) => (
      <Notification key={like.id}>
        <UserPhotoContainer>
          <img src={like.avatar_url} alt="Foto de perfil do usuário" />
          <IconContainer>{renderIcon(like.is_admin)}</IconContainer>
        </UserPhotoContainer>
        <Informations>
          {renderName(like.name, like.is_admin)} curtiu o comentário
        </Informations>
        <Time>
          {formatDistance(new Date(like.created_at), new Date(), {
            locale: ptBR,
          })}
        </Time>
      </Notification>
    ));
  };

  return (
    <Container>
      <Row>
        <RiCloseLine
          size={20}
          color="#707070"
          onClick={() => setShowLikeList(false)}
        />
      </Row>
      {renderLikes()}
    </Container>
  );
};

export default LikesModal;
