import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;

  > img {
    width: 30px;
    cursor: pointer;
  }

  > div:nth-of-type(2) {
    margin: 0 auto;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 640px) {
    > div:nth-of-type(2) {
      width: 100%;
    }
  }
`;

export const Description = styled.div`
  margin-top: 40px;
  width: 50%;

  > h1 {
    font-family: 'Bartolomeo-Regular';
    font-size: 4rem;
    color: #007e00;
  }

  > div:nth-of-type(1) {
    font-family: 'Aller-Light';
    font-size: 2.6rem;
    color: #707070;
  }

  > div:nth-of-type(2) {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    color: #9a9a9a;
    margin-top: 40px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    text-align: center;
  }
`;

export const Benefits = styled.div`
  display: flex;
  margin: 10px 0px;

  > div {
    display: flex;
    align-items: center;

    > img {
      height: 20px;
      width: 20px;
    }

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.4rem;
      color: #165011;
      margin: 0px 10px;
    }
  }

  @media (max-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: 640px) {
    margin: 0px;
    flex-direction: column;
    align-items: center;

    > div {
      margin: 10px 0px;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 50%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const GiftForFriend = styled.button`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #61e171;
  border-radius: 10px;
  outline: 0;
  background-color: #fff;
  padding: 20px;
  height: 120px;
  margin-top: 20px;

  > img {
    width: 80px;
    margin-right: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Bartolomeo-Regular';
    font-size: 2.4rem;
    color: #165011;

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.8rem;
      color: #707070;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
    height: 220px;
    padding: 10px;

    > img {
      margin-right: 0px;
    }
  }
`;
