import React, { useEffect, useState } from 'react';

import api from '../../../../services/api';

import Content from '../../../../components/Content';
import Slider3D from './components/Slider3D';

import {
  Container,
  Spinner,
  TitleContainer,
  ExclusiveContent,
  Description,
  ContainerSlider,
  TextMostRecentEpisodes,
  ContainerLatestSeries,
} from './styles';

const ExclusivePodcasts = ({ allPodcastsAndMeditations }) => {
  const [exclusiveMeditations, setExclusiveMeditations] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadExclusiveMeditations = async () => {
    setShowSpinner(true);
    try {
      const response = await api.get(
        `/podcast-series/${process.env.REACT_APP_SINGLE_MEDITATIONS}/episodes/published?limit=1000`,
      );
      setExclusiveMeditations(response.data.episodes);
    } catch (error) {}
    setShowSpinner(false);
  };

  useEffect(() => {
    loadExclusiveMeditations();
  }, [allPodcastsAndMeditations]);

  return (
    <Container>
      <Content>
        <ExclusiveContent>CONTEÚDO EXCLUSIVO</ExclusiveContent>
        <TitleContainer>
          <div>MEDITAÇÕES</div>
          <div>Exclusivas</div>
        </TitleContainer>
      </Content>
      <ContainerSlider id="meditation-session">
        <Content>
          <Slider3D lessons={exclusiveMeditations} loading={showSpinner} />
        </Content>
      </ContainerSlider>
    </Container>
  );
};

export default ExclusivePodcasts;
