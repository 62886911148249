import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;

  position: relative;

  background: rgb(72, 226, 91);
  background: linear-gradient(
    0deg,
    rgba(72, 226, 91, 0.5242471988795518) 0%,
    rgba(161, 237, 169, 0.3981967787114846) 18%,
    rgba(234, 246, 234, 0.09) 42%,
    rgba(255, 252, 252, 0.03) 65%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    max-width: 100%;
  }
  .annual-plan__background-icon {
    margin: 0 17rem 0 0;

    position: absolute;
    top: 2.85625rem;
    left: 13rem;
  }

  .annual-plan__host-image {
    width: 25.21375rem;
    height: 33.62125rem;

    position: relative;
    top: 5rem;

    margin: 0 auto;
  }

  .annual-plan__plan-card-container {
    width: fit-content;
    height: fit-content;

    position: absolute;
    right: calc(37vw - 14.5rem);
    top: 17rem;
    z-index: 10;

    @media (min-width: 1030px) {
      top: 14rem;
    }
  }

  .annual-plan__card-container {
    width: 85%;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    bottom: -4.625rem;

    &::after {
      content: ' ';
      height: 100%;
      width: 10rem;

      display: block;

      background-color: red;
    }
  }

  .annual-plan__card {
    width: 100%;
    max-width: 58.589375rem;
    height: fit-content;
    padding: 1.65625rem 2.51875rem 2.40625rem 2.8125rem;

    position: relative;

    background-color: #fff;
    box-shadow: 30px 1px 40px rgba(0, 0, 0, 0.28);
  }

  .annual-plan__card__p {
    height: fit-content;
    min-height: 5.3125rem;

    clear: right;

    display: inline;
    line-height: 2.5625rem;
    font-family: 'Aller-Light';
    font-size: 1.87rem;
    font-weight: lighter;
  }

  .annual-plan__card__b {
    font-family: 'Aller-Bold';
    color: var(--green24);
  }

  .annual-plan__card__high {
    height: fit-content;

    float: right;
    display: inline;

    font-size: 3.375rem;
    font-family: 'Aller-Bold';
    color: var(--green24);

    transform: rotate(-3deg);
  }

  .annual-plan__card__tag {
    width: fit-content;
    height: fit-content;

    position: absolute;
    bottom: 0;
    right: -9.5rem;
  }
`;
