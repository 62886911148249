import React, { useState, useEffect } from 'react';

import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { handleError } from '../../../../services/handleError';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import cardDueMask from '../../../../utils/cardDueMask';

import {
  Form,
  Title,
  InputRow,
  Tooltip,
  BackButton,
} from './_creditCardFormData';

export const CreditCardFormData = ({
  setShowLoadModal,
  setInformations,
  informations,
  handleNumberCreditCard,
  setShowFormDataSection,
  loading,
  handleDirectPayment,
}) => {
  const { Option } = Select;

  const testMode = process.env.REACT_APP_IUGU_TEST_MODE;

  const [errorMensage, setErrorMensage] = useState('');

  const [installments, setInstallments] = useState(1);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  function handleInstallments(value) {
    setInstallments(Number(value));
  }

  const handlePayment = async () => {
    setShowLoadModal(true);

    const name = informations.name;
    const nameSplited = name.split(' ');
    const primaryName = nameSplited[0];
    let surname = '';
    for (let index = 1; index < nameSplited.length; index++) {
      surname += nameSplited[index];
      if (index < nameSplited.length - 1) {
        surname += ' ';
      }
    }

    try {
      await window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT);

      if (testMode === 'true') {
        console.log('Iugu is currently on test mode');
        window.Iugu.setTestMode(true);
      }

      const cc = window.Iugu.CreditCard(
        informations.number,
        informations.duedate.substring(0, 2),
        informations.duedate.substring(3, 7),
        primaryName,
        surname,
        informations.cvv,
      );

      if (!window.Iugu.utils.validateCreditCardNumber(informations.number)) {
        setErrorMensage('Preencha um número de cartão de crédito válido');
        setShowLoadModal(false);
        return;
      }

      const brand = window.Iugu.utils.getBrandByCreditCardNumber(
        informations.number,
      );

      if (!brand) {
        setErrorMensage('Não suportamos essa bandeira de cartão de crédito');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateCVV(informations.cvv, brand)) {
        setErrorMensage('Preencha um CVV válido');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateExpirationString(informations.duedate)) {
        setErrorMensage(
          'Preencha o campo “data de vencimento” no seguinte formato: MM/AAAA',
        );
        setShowLoadModal(false);
        return;
      }

      setErrorMensage('');

      window.Iugu.createPaymentToken(cc, function (response) {
        if (response.id) {
          handleDirectPayment(response.id, installments);
        } else {
          const genericError = 'verifique os dados e tente novamente';

          if (response.errors)
            return setErrorMensage(`Cartão inválido, ${genericError}`);

          const errorKeys = Object.keys(response.errors);
          const displayError = errorKeys[0];

          const error = {
            verification_value:
              'verifique se o CVV foi preenchido corretamente',
            number: 'verifique se o número foi preenchido corretamente',
            expiration: 'verifique se a data de expiração está correta',
            last_name: 'verifique se o sobrenome foi preenchido',
            first_name:
              'veirifique se o primeiro nome foi preenchido corretamente',
          };

          setErrorMensage(
            `Cartão inválido, ${error[displayError] || genericError}`,
          );
        }
      });
    } catch (error) {
      setErrorMensage('Erro ao cadastrar seu cartão de crédito');
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setShowLoadModal(false);
    }
  };

  const creditCardInfoIsNotValid = () => {
    const { name, number, cvv, duedate } = informations;

    if (
      !name.length ||
      !name.includes(' ') ||
      number.length < 16 ||
      cvv.length < 3 ||
      duedate.length < 7
    ) {
      return true;
    }
    return false;
  };

  return (
    <Form>
      <Title>Adicione os dados do seu cartão</Title>
      <Input
        placeholder="Nome do Titular"
        onInput={(event) =>
          setInformations({ ...informations, name: event.target.value })
        }
      />
      <InputRow>
        <Input
          placeholder="Número do cartão"
          type="number"
          value={informations.number}
          onInput={(event) => handleNumberCreditCard(event)}
        />
        <Input
          placeholder="CVV"
          type="number"
          onInput={(event) =>
            setInformations({ ...informations, cvv: event.target.value })
          }
        />
      </InputRow>

      <Input
        placeholder="Mês/Ano de vencimento"
        value={informations.duedate}
        onInput={(e) =>
          setInformations({
            ...informations,
            duedate: cardDueMask(e.target.value),
          })
        }
      />

      <Select
        className="credit-card__select"
        defaultValue="1"
        bordered={false}
        onChange={handleInstallments}
      >
        <Option value="1">1x de R$ 770,00</Option>
        <Option value="11">11x de R$ 77,00</Option>
      </Select>

      {renderErrorMensage()}

      <Button
        type="button"
        onClick={() => handlePayment()}
        disabled={creditCardInfoIsNotValid() || loading}
      >
        {loading ? <LoadingOutlined /> : 'Finalizar Compra'}
      </Button>

      <BackButton onClick={() => setShowFormDataSection('register')}>
        Voltar
      </BackButton>
    </Form>
  );
};
