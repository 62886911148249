import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AiFillLike } from 'react-icons/ai';
import { FaCommentDots, FaHeart } from 'react-icons/fa';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import socketio from 'socket.io-client';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';

import { Container, GradientBackground, Title, Spinner, Text } from './styles';

const FrequentlyAskedQuestions = () => {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    await api
      .get('/terms-of-use')
      .then((response) => {
        setData(response.data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setData(error.response?.data?.message || error.toString());
      });
  };

  const renderText = () => {
    if (loading) {
      return <Spinner />;
    }

    return <Text>{data}</Text>;
  };

  return (
    <Container>
      <GradientBackground />
      <Header />
      <Content>
        <Title>Termos de Uso e Privacidade</Title>
        {renderText()}
      </Content>
      <Footer />
    </Container>
  );
};

export default FrequentlyAskedQuestions;
