import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 9.3875rem 0 3.65625rem;

  position: relative;

  background: rgb(132, 48, 202);
  background: var(--purple-gradient);

  .faq__icon {
    width: 3.855rem;
    height: 3.13625rem;

    margin: 0 auto;
  }

  .faq__title {
    width: fit-content;
    margin: 1rem auto 0;

    display: block;

    font-family: 'Aller-Light';
    font-size: 2.3125rem;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark70);
  }

  .faq__b-title {
    width: fit-content;
    margin: 0 auto;

    display: block;

    font-family: 'Aller-Bold';
    font-size: 4.3125rem;
    text-align: center;
    color: var(--green24);
    transform: rotate(-2deg);
    text-transform: lowercase;
  }

  .faq__slider {
    width: 100%;
  }

  .faq__slider-item {
    width: 95%;
    min-height: fit-content;
    height: 15.875rem;
    margin: 3.74375rem 0 2.9375rem;
    opacity: 0;

    transition: opacity 0.3s;
  }

  .faq__slider-article {
    width: 97%;
    max-width: 60.5rem;
    min-height: 100%;
    height: fit-content;
    margin: 0 auto;

    padding: 1em 0;

    display: flex;
    align-items: center;

    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);

    border-radius: 8px;
  }

  .faq__slider-article__content {
    flex: 10;
    height: fit-content;
  }

  .faq__slider-article__question {
    width: fit-content;
    margin: 0 auto;
  }

  .faq__slider-article__question-container {
    padding: 0 2em;
  }

  .faq__slider-article__question {
    display: inline-block;

    font-family: 'Aller-regular';
    font-size: 1.6rem;
    color: var(--green00);
  }

  .faq__slider-article__commentary {
    width: 100%;

    min-height: 5.875rem;

    padding: 15px 0;

    font-family: 'Aller-Light';
    font-size: 1.375rem;
    font-weight: lighter;
  }

  .slick-slider {
    position: relative;
  }

  .slick-current {
    .faq__slider-item {
      position: relative;
      opacity: 1;
    }
  }

  .slick-list {
    min-height: 350px;
  }

  .slick-arrow {
    width: 1.078125rem;

    position: absolute;
    z-index: 10;

    &:before {
      display: none;
    }
  }

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    right: 15px;
  }
`;
