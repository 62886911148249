import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-left: 34px;

  > img {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    margin-left: 0px;
  }
`;

export const NewNotificationIcon = styled.img`
  position: absolute;
  right: 2px;
  top: 6px;
`;
