import React, { useState } from 'react';
import api from '../../../../services/api';

import Content from '../../../../components/Content';
import Calendar from '../../../../components/Calendar';

import './calendar.css';

import calendar from '../../../../assets/home/calendar.png';

import {
  Container,
  GradientBackground,
  Description,
  TitleCalendar,
} from './styles';

const PlaylistOfTheMonth = ({
  data,
  calendarAppointments,
  showAllAppointments,
}) => {
  const renderTitle = () => {
    const { title } = data.playlist;
    const pipeIndex = title.lastIndexOf('|');
    const month = title.substring(0, pipeIndex);
    const theme = title.substring(pipeIndex + 1, title.length);
    return (
      <TitleCalendar>
        {month} <div>{theme}</div>
      </TitleCalendar>
    );
  };

  return (
    <Container id="playlistOfTheMonth">
      <GradientBackground />
      <Content>
        <Description>
          <img src={calendar} alt="Ícone de um calendário" />
          <div>
            Playlist <span>DO MÊS</span>
          </div>
          <p>Playlists com um foco diferente a cada mês!</p>
          {renderTitle()}
        </Description>
        <Calendar
          data={calendarAppointments}
          showAllAppointments={showAllAppointments}
          showHour={false}
        />
      </Content>
    </Container>
  );
};

export default PlaylistOfTheMonth;
