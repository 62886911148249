import FooterLogo from '../../assets/icons/logo.svg';

import { Container } from './styles';

export function Footer() {
  return (
    <Container>
      <div className="footer__links-container">
        <a
          className="footer__link"
          href="https://yogaco.com.br/#/perguntas-frequentes"
        >
          PERGUNTAS FREQUENTES
        </a>
        <a
          className="footer__link"
          href="https://yogaco.com.br/#/termos-de-uso-e-privacidade"
        >
          TERMOS E CONDIÇÕES DE USO
        </a>
        <a
          className="footer__link"
          href="https://yogaco.com.br/#/termos-de-uso-e-privacidade"
        >
          POLÍTICA DE PRIVACIDADE
        </a>
      </div>
      <img
        src={FooterLogo}
        alt="Yoga co."
        className="footer__logo-image"
      />
    </Container>
  );
}
