import { RecordedContent } from './organisms/RecordedContent/RecordedContent';
import { PlanBenefits } from './organisms/PlanBenefits/PlanBenefits';
import { YogaCalendar } from './organisms/YogaCalendar/YogaCalendar';
import { AppAbout } from './organisms/AppAbout/AppAbout';
import { CommunityRating } from './organisms/CommunityRating/CommunityRating';
import { Footer } from './organisms/Footer/Footer';
import { Container } from './styles';
import { Hero } from './organisms/Hero/Hero';
import { PlanOffers } from './organisms/PlanOffers/PlanOffers';
import { Faq } from './organisms/Faq/Faq';
import Header from '../../components/Header';

export const NewHomePage = () => {
  return (
    <>
      <Header showShopLink={true} />
      <Container>
        <Hero />
        <PlanBenefits />
        {/* <RecordedContent /> */}
        <CommunityRating />
        <YogaCalendar />
        <AppAbout />
        <PlanOffers greenBackground />
        <Faq />
        <CommunityRating showButton greenBackground />
        <PlanOffers showButton />
      </Container>
      <Footer />
    </>
  );
};
