import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';
import LoadModal from '../../components/LoadModal';
import NoAccessModal from '../../components/NoAccessModal';
import Footer from '../../components/Footer';

import greenBigDiary from '../../assets/myDiary/greenBigDiary.png';
import yellowBigDiary from '../../assets/myDiary/yellowBigDiary.png';
import purpleBigDiary from '../../assets/myDiary/purpleBigDiary.png';

import greenDiary from '../../assets/myDiary/green.png';
import yellowDiary from '../../assets/myDiary/yellow.png';
import purpleDiary from '../../assets/myDiary/purple.png';

import {
  Container,
  Row,
  BigDiary,
  Description,
  ChooseDiaryColor,
  ListOfDiaries,
  Diary,
  GreenBackground,
  YellowBackground,
  PurpleBackground,
  Tooltip,
} from './styles';

const CreateMyDiary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.User.id);
  const [diaryColorChosen, setDiaryColorChosen] = useState('');
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(true);
  const [errorMensage, setErrorMensage] = useState('');

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const fetchIfUserHasDiaryColor = async () => {
    const userHasDiaryColor = await api
      .get(`/users/${idUser}`)
      .then((response) => {
        if (response.data.diary_color) {
          return true;
        }

        return false;
      })
      .catch((error) => {
        return false;
      });

    return userHasDiaryColor;
  };

  const handleUserHasAccess = async () => {
    setShowLoadModal(true);

    const userHasAccess = await fetchIfUserHasPlanActive();

    if (!userHasAccess) {
      setShowLoadModal(false);
      setShowNoAccessModal(true);
      return;
    }

    const userHasDiaryColor = await fetchIfUserHasDiaryColor();

    setShowLoadModal(false);

    if (userHasDiaryColor) {
      history.push('/meu-diario');
    }
  };

  const saveDiaryColor = async () => {
    setShowLoadModal(true);
    await api
      .patch(`/users/diary-color`, {
        diary_color: diaryColorChosen,
      })
      .then((response) => {
        setShowLoadModal(false);
        history.push('/meu-diario');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleError = async (error) => {
    setShowLoadModal(false);
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setErrorMensage(message);
  };

  const handleDiaryColor = () => {
    if (diaryColorChosen) {
      saveDiaryColor();
    } else {
      setErrorMensage('Escolha a cor do seu diário');
    }
  };

  useEffect(() => {
    handleUserHasAccess();
  }, []);

  const renderBackgroundColor = () => {
    if (diaryColorChosen === 'green') {
      return <GreenBackground />;
    } else if (diaryColorChosen === 'yellow') {
      return <YellowBackground />;
    } else if (diaryColorChosen === 'purple') {
      return <PurpleBackground />;
    }
  };

  const renderBigDiary = () => {
    if (diaryColorChosen === 'green' || diaryColorChosen === '') {
      return <BigDiary src={greenBigDiary} alt="Ícone de um diário" />;
    } else if (diaryColorChosen === 'yellow') {
      return <BigDiary src={yellowBigDiary} alt="Ícone de um diário" />;
    } else if (diaryColorChosen === 'purple') {
      return <BigDiary src={purpleBigDiary} alt="Ícone de um diário" />;
    }
  };

  const renderIconDiary = () => {
    if (diaryColorChosen === 'green' || diaryColorChosen === '') {
      return <img src={greenBigDiary} alt="Ícone de um diário" />;
    } else if (diaryColorChosen === 'yellow') {
      return <img src={yellowBigDiary} alt="Ícone de um diário" />;
    } else if (diaryColorChosen === 'purple') {
      return <img src={purpleBigDiary} alt="Ícone de um diário" />;
    }
  };

  const renderIconBack = () => {
    if (diaryColorChosen === '' || diaryColorChosen === 'yellow') {
      return <IoMdArrowBack size={30} color="#168A2C" />;
    } else if (diaryColorChosen === 'green' || diaryColorChosen === 'purple') {
      return <IoMdArrowBack size={30} color="#fff" />;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return <NoAccessModal show={showNoAccessModal} blockedPage={true} />;
    }
  };

  const renderTooltip = () => {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  };

  return (
    <Container>
      {renderBackgroundColor()}
      <Header />
      <Content>
        {renderIconBack()}
        <Row>
          {renderBigDiary()}
          <Description diaryColorChosen={diaryColorChosen}>
            <h1>
              <span>meu</span>
              Diario
            </h1>
            <p>
              Aqui você poderá anotar como está se sentindo, escrever sobre seu
              dia a dia, colocando citações, tipos de meditações aprendidas ou
              simplesmente escrever sobre o que você quiser.
            </p>
            {renderIconDiary()}
          </Description>
          <ChooseDiaryColor>
            <div>Escolha a cor do seu Diário</div>
            <div>A cor escolhida será o tema do seu Diário e Perfil.</div>
            <ListOfDiaries diaryColorChosen={diaryColorChosen}>
              <Diary
                src={greenDiary}
                color="green"
                diaryColorChosen={diaryColorChosen}
                alt="Ícone do diário da cor verde"
                onClick={() => setDiaryColorChosen('green')}
              />
              <Diary
                src={yellowDiary}
                color="yellow"
                diaryColorChosen={diaryColorChosen}
                alt="Ícone do diário da cor amarela"
                onClick={() => setDiaryColorChosen('yellow')}
              />
              <Diary
                src={purpleDiary}
                color="purple"
                diaryColorChosen={diaryColorChosen}
                alt="Ícone do diário da cor roxa"
                onClick={() => setDiaryColorChosen('purple')}
              />
            </ListOfDiaries>
            {renderTooltip()}
            <Button onClick={() => handleDiaryColor()}>Quero esse</Button>
          </ChooseDiaryColor>
        </Row>
      </Content>
      <Footer />
      {renderLoadModal()}
      {renderNoAccessModal()}
    </Container>
  );
};

export default CreateMyDiary;
