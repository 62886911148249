import { useHistory } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';

import './modal.css';

import back from '../../assets/noAccessModal/back.png';

import { Button, LoginButton, LoginText, Row, BackButton } from './styles';

const LoadModal = ({ show, setShow = () => {}, blockedPage = false }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (blockedPage) {
      history.goBack();
    } else {
      setShow(false);
    }
  };

  return (
    <Modal
      id="modal-not-access"
      show={show}
      onHide={() => setShow(false)}
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Button onClick={() => history.push('/escolha-um-plano')}>
          Assine agora para ter acesso
        </Button>

        <LoginText>
          Ou, se você já é assinante,
          <LoginButton onClick={() => history.push('/login')}>
            Faça Login
          </LoginButton>
          para continuar
        </LoginText>

        <Row>
          <img
            onClick={() => handleGoBack()}
            src={back}
            alt="Ícone de fechar o aviso e voltar para a página"
          />
          <BackButton onClick={() => handleGoBack()}>Voltar</BackButton>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LoadModal;
