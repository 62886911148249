import Card from '../Card';
import cardExample from '../../../../../../assets/profile/card-example.png';

import api from '../../../../../../services/api';

import { Container } from './styles';

const ListCards = ({ cards, setCardAsDefault }) => {
  const renderCards = () => {
    return cards.map((card) => {
      const activeClass = card.is_active ? 'active' : '';
      return (
        <div
          className={`list-cards__card ${activeClass}`}
          onClick={() => setCardAsDefault(card.id)}
        >
          <Card creditCard={card} />
        </div>
      );
    });
  };

  return (
    <Container className="list-cards">
      {cards.length === 0 ? (
        <img src={cardExample} alt="Exemplo de cartão de crédito" />
      ) : (
        <>{renderCards()}</>
      )}
    </Container>
  );
};

export default ListCards;
