import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 10px;
  text-align: center;

  > img {
    margin-top: 20px;
    cursor: pointer;
  }

  @media (max-width: 1090px) {
    width: 94%;
    max-height: 94vh;
    height: 100%;
    overflow: auto;
  }
`;

export const Row = styled.div`
  display: flex;

  > label {
    font-family: 'Roboto-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    margin-left: 8px;
  }

  @media (max-width: 1090px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.6rem;
  color: #165011;
`;

export const Form = styled.form`
  width: 600px;

  > div:nth-of-type(4) {
    width: 64%;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    background-color: #19b441;
    margin-top: 40px;
  }

  @media (max-width: 1270px) {
    width: 420px;
  }

  @media (max-width: 1090px) {
    width: 100%;

    > div {
      width: 100%;
    }

    > div:nth-of-type(4) {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;

  > div:nth-of-type(1) {
    width: 54%;
  }

  > div:nth-of-type(2) {
    width: 28%;
    margin-left: 28px;
  }

  @media (max-width: 1090px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
      margin-left: 0px;
    }
  }
`;

export const AddButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #19b441;
  text-align: center;

  > span {
    display: block;
    font-family: 'Aller-Light';
    font-size: 3rem;
    color: #fff;
    margin-left: -4px;
    margin-top: -3px;
  }
`;

export const AddCard = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  margin-left: 10px;
`;

export const CardContainer = styled.div`
  width: 600px;

  > div {
    align-items: center;
  }
`;

export const CreditCardBanners = styled.img`
  margin-right: -210px;
  margin-top: 20px;

  @media (max-width: 1090px) {
    margin-right: 0px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: left;

  @media (max-width: 1090px) {
    text-align: center;
  }
`;
