import Carousel from '../Carousel';

import { Title } from './styles';

const FavoriteMeditations = ({ podcasts, loading, search }) => {
  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const renderPodcasts = () => {
    let podcastsFiltered = [];
    if (search.trim() !== '') {
      let title;
      let searchFormatted = formatString(search);
      podcasts.map((item) => {
        if (item.post.type === 'podcast_series') {
          title = formatString(item.post.podcast_series.title);
        } else {
          title = formatString(item.post.episode.name);
        }

        if (title.indexOf(searchFormatted) >= 0) {
          podcastsFiltered.push(item);
        }
      });
    } else {
      podcastsFiltered = podcasts;
    }

    if (podcastsFiltered.length > 0 || loading) {
      return (
        <>
          <Title>Seus podcasts favoritos</Title>
          <Carousel
            podcastOrMeditation={true}
            route="podcast/aula"
            lessons={podcasts}
            loading={false}
          />
        </>
      );
    }
  };

  return <>{renderPodcasts()}</>;
};

export default FavoriteMeditations;
