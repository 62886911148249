import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Editor from './components/Editor';
import NoAccessModal from '../../components/NoAccessModal';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';
import Footer from '../../components/Footer';

import editDiaryGreen from '../../assets/myDiary/editDiaryGreen.png';
import editDiaryYellow from '../../assets/myDiary/editDiaryYellow.png';
import editDiaryPurple from '../../assets/myDiary/editDiaryPurple.png';

import {
  Container,
  PurpleBackground,
  YellowBackground,
  GreenBackground,
  IconDiary,
  Row,
} from './styles';

const EditMyDiary = () => {
  const idUser = useSelector((state) => state.User.id);
  const { idNote } = useParams();
  const history = useHistory();
  const [diaryColor, setDiaryColor] = useState('');
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState();

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const fillFields = (note) => {
    document.getElementById('title-note').value = note.title;
    document.getElementById('description-note').value = note.description;
  };

  const fetchNote = async () => {
    if (idNote) {
      setShowLoadModal(true);
      await api
        .get(`/diaries/${idNote}`)
        .then((response) => {
          setShowLoadModal(false);
          fillFields(response.data);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }
    setShowLoadModal(false);
    setErrorMensage(message);
    setShowMessageModal(true);
  };

  const handleUserAccess = async () => {
    const userHasPlanActive = await fetchIfUserHasPlanActive();

    if (!userHasPlanActive) {
      setShowNoAccessModal(true);
      return;
    }
  };

  const fetchDiaryColor = async () => {
    await api
      .get(`/users/${idUser}`)
      .then((response) => {
        if (response.data.diary_color) {
          setDiaryColor(response.data.diary_color);
        } else {
          history.push('/criar-meu-diario');
          return;
        }
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    handleUserAccess();
    fetchDiaryColor();
    fetchNote();
    window.scrollTo(0, 0);
  }, []);

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return <NoAccessModal show={showNoAccessModal} blockedPage={true} />;
    }
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro"
          description={errorMensage}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderButtons = () => {
    if (idNote) {
      return (
        <>
          <Button onClick={() => updateNote()}>Editar</Button>
          <Button onClick={() => deleteNote()}>Excluir</Button>
        </>
      );
    }

    return <Button onClick={() => saveNote()}>Salvar</Button>;
  };

  const saveNote = async () => {
    const title = document.getElementById('title-note').value;
    const description = document.getElementById('description-note').value;

    if (!title.trim()) {
      setErrorMensage('Digite o título corretamente');
      setShowMessageModal(true);
      return;
    }

    if (!description.trim()) {
      setErrorMensage('Digite a descrição corretamente');
      setShowMessageModal(true);
      return;
    }

    setShowLoadModal(true);
    await api
      .post(`/diaries`, {
        title,
        description,
      })
      .then((response) => {
        setShowLoadModal(false);
        history.push('/meu-diario');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const updateNote = async () => {
    const title = document.getElementById('title-note').value;
    const description = document.getElementById('description-note').value;

    if (!title.trim()) {
      setErrorMensage('Digite o título corretamente');
      setShowMessageModal(true);
      return;
    }

    if (!description.trim()) {
      setErrorMensage('Digite a descrição corretamente');
      setShowMessageModal(true);
      return;
    }

    setShowLoadModal(true);
    await api
      .put(`/diaries/${idNote}`, {
        title,
        description,
      })
      .then((response) => {
        setShowLoadModal(false);
        history.push('/meu-diario');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteNote = async () => {
    setShowLoadModal(true);
    await api
      .delete(`/diaries/${idNote}`)
      .then((response) => {
        setShowLoadModal(false);
        history.push('/meu-diario');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const renderBackgroundColor = () => {
    if (diaryColor === 'green') {
      return <GreenBackground />;
    } else if (diaryColor === 'yellow') {
      return <YellowBackground />;
    } else if (diaryColor === 'purple') {
      return <PurpleBackground />;
    }
  };

  const renderIconDiary = () => {
    if (diaryColor === 'green') {
      return <IconDiary src={editDiaryGreen} alt="Ícone de um diário aberto" />;
    } else if (diaryColor === 'yellow') {
      return (
        <IconDiary src={editDiaryYellow} alt="Ícone de um diário aberto" />
      );
    } else if (diaryColor === 'purple') {
      return (
        <IconDiary src={editDiaryPurple} alt="Ícone de um diário aberto" />
      );
    }
  };

  return (
    <Container>
      {renderBackgroundColor()}
      <Header />
      <Content>
        {renderIconDiary()}
        <Input id="title-note" placeholder="Digite o título" />
        <Editor />
        <Row>{renderButtons()}</Row>
      </Content>
      <Footer />
      {renderNoAccessModal()}
      {renderLoadModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default EditMyDiary;
