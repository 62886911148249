import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 9.3875rem 0 3.65625rem;

  position: relative;

  background: rgb(132, 48, 202);
  background: linear-gradient(
    0deg,
    rgba(132, 48, 202, 0.35898109243697474) 0%,
    rgba(234, 246, 234, 0.09) 42%,
    rgba(255, 252, 252, 0.03) 65%,
    rgba(255, 255, 255, 0) 100%
  );

  .community-rating__icon {
    width: 3.855rem;
    height: 3.13625rem;

    margin: 0 auto;
  }

  .community-rating__title {
    width: fit-content;
    margin: 1rem auto 0;

    display: block;

    font-family: 'Aller-Light';
    font-size: 2.3125rem;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark70);
  }

  .community-rating__b-title {
    width: fit-content;
    margin: 0 auto;

    display: block;

    font-family: 'Aller-Bold';
    font-size: 4.3125rem;
    text-align: center;
    color: var(--green24);
    transform: rotate(-2deg);
  }

  .community-rating__slider-item {
    width: 100%;
    min-height: fit-content;
    height: 15.875rem;
    margin: 3.74375rem 0 2.9375rem;
  }

  .slick-dots {
    margin-top: 2.9375rem;
  }

  .community-rating__slider-dot {
    width: 1.3125rem;
    height: 1.3125rem;

    border-radius: 50%;
    background-color: #fff;
  }

  .slick-active {
    .community-rating__slider-dot {
      width: 1.3125rem;
      height: 1.3125rem;

      border-radius: 50%;
      background-color: var(--purpleA1);
    }
  }

  .community-rating__slider-article {
    width: 97%;
    max-width: 60.5rem;
    min-height: 100%;
    height: fit-content;
    margin: 0 auto;
    padding: 1.6rem 0;

    display: flex;
    align-items: center;

    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  }

  .community-rating__slider-article__image-wrapper {
    flex: 3;
    height: 10.6125rem;
  }

  .community-rating__slider-article__image {
    width: 85%;
    height: auto;
    margin: 0 auto;

    aspect-ratio: 1 / 1;

    object-fit: cover;
    border-radius: 50%;
  }

  .community-rating__slider-article__content {
    flex: 10;
    height: fit-content;
    /* margin-top: 0.5rem; */
  }

  .community-rating__slider-article__user {
    /* width: fit-content;
    margin: 0 auto; */
    display: none;
  }

  .community-rating__slider-article__username-container {
  }

  .community-rating__slider-article__username {
    display: inline-block;

    font-family: 'Aller-Bold';
    font-size: 1.5625rem;
    text-transform: uppercase;
    color: var(--green16);
  }

  .community-rating__slider-article__since {
    display: inline-block;

    font-family: 'Aller-Light';
    font-size: 1.3125rem;
    text-transform: uppercase;
    color: var(--green16);
  }

  .community-rating__slider-article__commentary {
    width: 90%;
    max-width: 40.8125rem;
    height: fit-content;
    min-height: 5.875rem;
    margin: 1.5625rem auto 0;

    font-family: 'Aller-Light';
    font-size: 1.375rem;
    font-weight: lighter;
    text-transform: uppercase;
  }
`;
