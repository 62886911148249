import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import LoadModal from '../../../../../../components/LoadModal';
import MessageModal from '../../../../../../components/MessageModal';
import NoAccessModal from '../../../../../../components/NoAccessModal';

import api from '../../../../../../services/api';

import userPhotoGeneric from '../../../../../../assets/header/user.png';

import { Container } from './styles';

const AddCommentReply = ({
  idComment,
  idRootComment,
  userName,
  loadListComment,
  setVisible,
}) => {
  const user = useSelector((state) => state.User);
  const userPhoto = user.avatar_url;

  const [content, setContent] = useState(`${userName}, `);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!user.accessToken || !user.id) {
      setShowNoAccessModal(true);
      return;
    }

    if (content.trim() === '') {
      setError('Digite um comentário corretamente');
      setShowMessageModal(true);
      setTimeout(function () {
        setShowMessageModal(false);
      }, 2000);
      return;
    }

    let comment_id;
    if (idRootComment) {
      comment_id = idRootComment;
    } else {
      comment_id = idComment;
    }

    setShowLoadModal(true);
    await api
      .post('/comment-responses', {
        comment_id,
        content,
      })
      .then((response) => {
        setShowLoadModal(false);
        loadListComment(true, false);
        setContent(`${userName}, `);
        setVisible(false);
      })
      .catch((error) => handleError(error));
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    setShowLoadModal(false);

    if (userHasPlanActive) {
      setError(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  const renderShowLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  function renderUserPhoto() {
    if (userPhoto === '' || userPhoto === null || userPhoto === undefined) {
      return <img src={userPhotoGeneric} alt="Foto de perfil" />;
    }
    return <img src={userPhoto} alt="Foto de perfil" />;
  }

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return (
        <NoAccessModal
          show={showNoAccessModal}
          setShow={setShowNoAccessModal}
        />
      );
    }
  };

  return (
    <Container onSubmit={(event) => handleSubmit(event)}>
      {renderUserPhoto()}
      <Input
        autoFocus
        value={content}
        onInput={(event) => setContent(event.target.value)}
      />
      <Button>Responder</Button>
      {renderShowLoadModal()}
      {renderMessageModal()}
      {renderNoAccessModal()}
    </Container>
  );
};

export default AddCommentReply;
