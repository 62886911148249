import styled from 'styled-components';

export const Container = styled.button`
  width: 2.25rem;
  height: 1.3125rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;

  .mobile-menu-button__stick{
    width: 100%;
    height: 0.3125rem;

    display: inline-block;

    background: var(--green00);
  }

  @media (min-width: 768px) {
    display: none;
  }
`
