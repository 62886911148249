import Content from '../../../../components/Content';

import logo from '../../../../assets/favorites/logo.png';

import { Container, GradientBackground } from './styles';

const Logo = () => {
  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo do Yoga Co." />
        <h1>Minhas aulas</h1>
      </Content>
      <GradientBackground />
    </Container>
  );
};

export default Logo;
