import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  height: fit-content;
  padding: 3.6375rem 0 4.44375rem;

  background: rgb(191,255,193);
  background: linear-gradient(0deg, rgba(191,255,193,1) 0%, rgba(255,255,255,0) 35%);

  .footer__links-container{
    height: fit-content;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6875rem;
  }

  .footer__link{
    height: fit-content;

    color: var(--grey9E);
    text-decoration: none;
    text-align: center;
    font-size: 0.75rem;
    font-family: "Aller-Bold";
    letter-spacing: 0.15rem;
  }

  .footer__logo-image{
    width: 14.29625rem;
    height: 2.2425rem;
    margin: 2.8125rem auto 0;

    display: block;
  }
`;
