import styled from 'styled-components';

export const Container = styled.div`
  > img {
    display: block;
    margin: 10px auto;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.6rem;
    color: #939393;
    background-color: #fff;
  }

  @media (max-width: 1260px) {
    > button {
      margin: 40px auto;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 560px) {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #19b441;
  text-align: center;

  > span {
    display: block;
    font-family: 'Aller-Light';
    font-size: 3rem;
    color: #fff;
  }
`;

export const AddCard = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #165011;
  margin-left: 10px;
`;

export const CreditCardBanners = styled.img`
  margin-right: -210px;
  margin-top: 20px;

  @media (max-width: 1260px) {
    margin-right: 0px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const TooltipPaymentOption = styled(Tooltip)`
  max-width: 400px;
  text-align: center;
  margin: 20px auto 0px auto;
`;

export const GiftedRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const IWasPresented = styled.div`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #61e171;
  border-radius: 10px;
  outline: 0;
  background-color: #fff;

  width: 100%;
  max-width: 500px;
  height: 120px;

  margin: 0 auto;
  padding: 20px;

  > img {
    width: 80px;
    margin-right: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    min-width: 260px;

    font-family: 'Aller-Regular';
    font-size: 24px;
    color: #707070;

    > div {
      > div {
        margin: 6px 0px 0px 0px;
        width: 200px;
        height: 40px;
      }
      > button {
        font-family: 'Aller-Bold';
        font-size: 18px;
        color: #fff;
        width: 40px;
        height: 40px;
        background-color: #7fc655;
        border-radius: 10px;
        border: 0px;
        margin-left: 20px;
        margin-top: 6px;
        outline: 0;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 766px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;

    > img {
      display: none;
    }
  }
`;
