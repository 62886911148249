import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 2.29375rem 0 0;

  display: flex;
  flex-direction: column;

  background: rgb(72, 226, 91);
  background: linear-gradient(
    0deg,
    rgba(72, 226, 91, 0.5242471988795518) 0%,
    rgba(161, 237, 169, 0.3981967787114846) 18%,
    rgba(234, 246, 234, 0.09) 42%,
    rgba(255, 252, 252, 0.03) 65%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    max-width: 100%;
  }

  @media (min-width: 968px) {
    flex-direction: row;
  }

  .main-content__black {
    height: fit-content;
    padding: 0 0 6.66875rem;

    @media (min-width: 968px) {
      flex: 1;
    }
  }

  .main-content__black__icon {
    width: 3.076875rem;
    height: 2.50375rem;
    margin: 0 auto;
  }

  .main-content__black__title {
    width: fit-content;
    height: fit-content;
    margin: 1.3rem auto 0;

    color: var(--green0F);
    text-align: center;
    font-family: 'Aller-Bold';
    font-size: 4.875rem;
    line-height: 4.6875rem;
  }

  .main-content__black__card {
    width: calc(100% - 3.4875rem);
    height: 6rem;
    padding: 0 6rem;
    margin: 0.76875rem 0 0;

    color: var(--green24);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    font-size: 4.3125rem;
    line-height: 5rem;
    text-align: end;
  }

  .main-content__black__tag {
    width: 27.5625rem;
    height: 3.9375rem;
    margin: 2.26875rem auto 0;

    display: block;

    border-radius: 2.4375rem;
    background-color: #fff;
    color: var(--green16);
    text-align: center;
    text-transform: uppercase;
    line-height: 3.9375rem;
    font-size: 1.3125rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .main-content__black__text {
    width: 100%;
    max-width: 30rem;
    height: 4.9375rem;
    margin: 2rem auto 0;

    text-align: center;
    text-transform: uppercase;
    font-size: 1.5625rem;
    font-family: 'Aller-Light';
    line-height: 2.2rem;
  }

  .main-content__black__text-b {
    color: var(--green2F);
    font-family: 'Aller-Bold';
  }

  .main-content__plan {
    height: 42rem;

    position: relative;

    overflow: hidden;

    @media (min-width: 968px) {
      flex: 1;
    }
  }

  .main-content__logo {
    width: 100%;
    max-width: 38.144375rem;
    aspect-ratio: 610.31 / 496.52;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .main-content__annual-card {
    position: absolute;
    top: 2rem;
    left: 7.5rem;
  }

  .main-content__monthly-tag {
    position: absolute;
    top: 23rem;
    left: 2rem;
  }

  .main-content__host {
    width: 70%;
    max-width: 25.218125rem;
    aspect-ratio: 403.49 / 550.13;

    position: absolute;
    right: -3rem;
    bottom: -1rem;
  }
`;
