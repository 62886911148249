import { useHistory } from 'react-router-dom';
import logo from '../../assets/footer/logo.png';

import { Container, GradientBackground, Menu, Logo, CopyRight } from './styles';

import Content from './components/Content';

const Footer = () => {
  const history = useHistory();
  return (
    <Container>
      <Content>
        <Menu>
          {/* <ul>
            <li>PERGUNTAS FREQUENTES</li>
            <li>TERMOS E CONDIÇÕES DE USO</li>
            <li>POLÍTICA DE PRIVACIDADE</li>
          </ul> */}
          <ul>
            <li onClick={() => history.push('/perguntas-frequentes')}>
              PERGUNTAS FREQUENTES
            </li>
            <li onClick={() => history.push('/termos-de-uso-e-privacidade')}>
              TERMOS DE USO E PRIVACIDADE
            </li>
          </ul>
        </Menu>
        <Logo
          onClick={() => history.push('/')}
          src={logo}
          alt="Logo do Yoga CO."
        />
      </Content>
      <GradientBackground />
      <CopyRight>Copyright © 2021 YOGA CO.</CopyRight>
    </Container>
  );
};

export default Footer;
