import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import PlanCard from '../PlanCard';

import { Container, Spinner } from './styles';

const ListPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState();
  const [plans, setPlans] = useState([{ id: '', name: '', price: 0 }]);
  const [showLoad, setShowLoad] = useState(false);

  const loadPlans = async () => {
    setShowLoad(true);

    try {
      const response = await api.get('/plan-types?isVisible=true');
      setPlans(response.data);
    } catch (error) {}

    setShowLoad(false);
  };

  function renderPlans() {
    if (showLoad) {
      return <Spinner />;
    }

    const listPlansComponent = plans.map((plan) => (
      <PlanCard
        data={plan}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    ));

    return listPlansComponent;
  }

  useEffect(() => {
    loadPlans();
  }, []);

  return <Container>{renderPlans()}</Container>;
};

export default ListPlans;
