import axios from 'axios';
import Store from '../redux';
import { useHistory } from 'react-router-dom';
import refreshToken from '../utils/refreshToken';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: 'http://localhost:3371/',
});

api.interceptors.request.use(async (config) => {
  const token = Store.getState().User.accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
