import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: #165011;
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #48e25b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const Text = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 1.6rem;
  color: #202125;
  margin: 40px 0px;
  width: 100%;
  overflow: auto;
  text-align: left;
  white-space: break-spaces;
`;
