import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../../services/api';
import emailValidation from '../../../../utils/emailValidation';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import TooltipClipboard from '../../../../components/TooltipClipboard';

import iconBack from '../../../../assets/profile/icon-back.png';
import share from '../../../../assets/giftFriend/share.png';

import {
  Container,
  Row,
  Description,
  Disclaimer,
  Form,
  RowShareButton,
  Tooltip,
  DescriptionAfterTheCodeHasBeenGenerated,
} from './styles';

const GenerateCodeModal = ({
  setVisible,
  creditCardToken,
  setShowLoadModal,
}) => {
  const id = useSelector((state) => state.User.id);

  const history = useHistory();
  const componentRef = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [showTooltipClipboard, setShowTooltipClipboard] = useState(false);
  const [textCopyCode, setTextCopyCode] = useState('Copiar');
  const [codeWasGenerated, setCodeWasGenerated] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    loadUserInformations();
  }, []);

  const generateCode = async (event) => {
    event.preventDefault();

    const plan_type_id = localStorage.getItem('YogaCO@ChoosedPlan');

    // if (name.trim() === '') {
    //   setErrorMessage(
    //     'Digite o nome da pessoa que irá receber o presente corretamente',
    //   );

    //   return;
    // }

    // if (!emailValidation(email)) {
    //   setErrorMessage(
    //     'Digite o e-mail da pessoa que irá receber o presente corretamente',
    //   );

    //   return;
    // }

    setErrorMessage('');
    setShowLoadModal(true);

    await api
      .post('/gift-code', {
        plan_type_id,
        // name,
        // email,
        credit_card_token: creditCardToken,
        set_as_default: 'false',
        payable_with: 'credit_card',
      })
      .then((response) => {
        setShowLoadModal(false);
        setCode(response.data.code);
        setCodeWasGenerated(true);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  const handleClick = (e) => {
    if (!componentRef.current.contains(e.target)) {
      return;
    }
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  };

  function copyToClipboard() {
    const link = code;
    navigator.clipboard.writeText(link).then(function () {
      setShowTooltipClipboard(true);
      if (textCopyCode === 'Copiar') {
        setTextCopyCode('Código copiado');
      }
    });
  }

  const renderGenerateCodeButton = () => {
    if (!codeWasGenerated) {
      return <Button>Gerar Código</Button>;
    }
  };

  const renderDescriptionAfterGenerateCode = () => {
    if (codeWasGenerated) {
      return (
        <DescriptionAfterTheCodeHasBeenGenerated>
          Parabéns, o código do seu presente acabou de ser gerado.
          <br />
          Copie o código e mande para a pessoa que vai ser presenteada!
          <br />
          <Button
            onClick={() => {
              copyToClipboard();
              history.push('/');
            }}
          >
            Voltar para a tela inicial
          </Button>
        </DescriptionAfterTheCodeHasBeenGenerated>
      );
    }
  };

  const loadUserInformations = async () => {
    if (!id.trim()) {
      return;
    }

    setShowLoadModal(true);

    try {
      const response = await api.get(`/users/${id}`);

      response && setEmail(response.data.email);
    } catch (error) {
      setErrorMessage(
        'Houve um erro de comunicação com nossa base de dados. Por favor tente mais tarde',
      );
    } finally {
      setShowLoadModal(false);
    }
  };

  return (
    <Container ref={componentRef}>
      <Row>
        <Description>
          <div>Estamos quase lá!</div>
          <p>
            Compartilhe o código do Yoga Co. que geraremos para você aqui em
            baixo e presenteie alguém querido com um dos nossos planos anual ou
            mensal
          </p>
          <Disclaimer>
            compartilhe esse código apenas com a pessoa presenteada, o plano
            presenteado é totalmente individual.
          </Disclaimer>
        </Description>
        <Form onSubmit={(event) => generateCode(event)}>
          {/* <Input
            placeholder="Nome do(a) presenteado(a)"
            value={name}
            onInput={(event) => setName(event.target.value)}
          /> */}

          {renderErrorMessage()}
          {renderGenerateCodeButton()}
          <RowShareButton>
            <Input value={code} disabled />
            <Button type="button" onClick={() => copyToClipboard()}>
              <div>{textCopyCode}</div>
            </Button>
          </RowShareButton>
        </Form>
      </Row>
      {renderDescriptionAfterGenerateCode()}
    </Container>
  );
};

export default GenerateCodeModal;
