import React, { useEffect, useState, memo } from 'react';

import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import { Container, Spinner, Title, Text } from './styles';

const LatestLessons = ({ exclusiveLessonsFromChannel, showSpinner }) => {
  return (
    <Container>
      <Content>
        <Text>Aulas do Canal da Pri</Text>
        <Slider3D
          route="aula"
          lessons={exclusiveLessonsFromChannel}
          loading={showSpinner}
        />
      </Content>
    </Container>
  );
};

export default memo(LatestLessons);
