import { Select } from 'antd';

import { Container } from './styles';

const FilterSelect = ({ setFilterOption }) => {
  const { Option } = Select;

  function handleChange(value) {
    setFilterOption(value);
  }

  return (
    <Container>
      <Select defaultValue="Todos" onChange={handleChange} bordered={false}>
        <Option value="Todos">Todos</Option>
        <Option value="Séries">Séries</Option>
        <Option value="Aulas">Aulas</Option>
        <Option value="Meditações">Meditações</Option>
        <Option value="Podcast">Podcast</Option>
        <Option value="Concluídos">Concluídos</Option>
      </Select>
    </Container>
  );
};

export default FilterSelect;
