import visa from '../../../../../../assets/profile/visa-logo.png';

import { Container, Row, Informations } from './styles';

const Card = ({ creditCard }) => {
  return (
    <Container className="card">
      <Informations className="card__informations">
        <Row>
          <span>{creditCard.display_number.substring(0, 4)}-</span>
          <span>{creditCard.display_number.substring(5, 9)}-</span>
          <span>{creditCard.display_number.substring(10, 14)}-</span>
          <span>{creditCard.display_number.substring(15, 19)}</span>
        </Row>
        <Row>
          <span>{creditCard.holder_name}</span>
          <span>
            {creditCard.month.toString() + '/' + creditCard.year.toString()}
          </span>
        </Row>
      </Informations>
    </Container>
  );
};

export default Card;
