import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 0px;
  }
  h1 {
    font-family: 'Bartolomeo-Regular';
    font-size: 4rem;
    color: #165011;
    font-weight: normal;
  }
`;

export const GradientBackground = styled.div`
 position: absolute;
    bottom: -30px;
    width: 100%;
    height: 60px;
    z-index: -1;
    background: linear-gradient(
0deg
, rgba(133,50,202,1) -100%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 80% );
}
`;
