import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { Container } from './_planOffers';

export const PlanOffers = ({ showButton, greenBackground }) => {
  return (
    <Container
      className={`plan-offers ${
        greenBackground && 'plan-offers--green-background'
      }`}
    >
      <h2 className="plan-offers__title">
        Tenha acesso a séries e aulas incríveis no valor de mais de{' '}
        <b>R$ 50 mil reais! </b>
      </h2>

      <span className="plan-offers__call-to-action">
        Escolha o melhor plano para você
      </span>

      <div className="plan-offers__cards-wrapper">
        <div className="plan-offers__plan-card">
          <div className="plan-offers__price">
            <div className="plan-offers__price-tag" />
            <span>
              R$ 47,90
              <small className="plan-offers__small"> /mês</small>
            </span>
          </div>
          <div className="plan-offers__plan-name">
            <small>Plano</small>
            mensal
          </div>
        </div>

        <div className="plan-offers__plan-card plan-offers__plan-card--yearly">
          <div className="plan-offers__price plan-offers__price--yearly">
            <div className="plan-offers__price-tag" />
            <span>
              R$ 479,90
              <small className="plan-offers__small"> /ano</small>
            </span>
            <small className="plan-offers__small--free">+2 meses grátis</small>
          </div>
          <div className="plan-offers__plan-name">
            <small>Plano</small>
            anual
          </div>

          <div className="plan-offers__advantage-tag">
            <span>Menos de</span> R$ 40 por mês
            <small>O melhor custo benefício</small>
          </div>
        </div>
      </div>

      <p className="plan-offers__paragraph">
        Isso mesmo! Ao invés de comprar tudo separado você ganha acesso à
        conteúdo de <b>qualidade </b>de forma acessível por uma fração do preço.
        Tudo na ponta dos dedos feito com muito amor, do meu coração para o seu.
      </p>

      {showButton && greenBackground && (
        <PrimaryButton href={'escolha-um-plano'}>
          Experimente grátis por 7 dias
        </PrimaryButton>
      )}

      {showButton && !greenBackground && (
        <SecondaryButton href={'escolha-um-plano'}>
          Experimente grátis por 7 dias
        </SecondaryButton>
      )}

      {!showButton && <span className="plan-offers__invite">Vamos?</span>}
    </Container>
  );
};
