import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';
import logoutUserAutomatically from '../../utils/logoutUserAutomatically';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import LogoWithLegend from '../../assets/login/logo-with-legend.png';

import {
  Container,
  Row,
  Form,
  Logo,
  GradientBackground,
  Tooltip,
  SunflowerStudent,
} from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  useEffect(() => {
    if (logoutUserAutomatically()) {
      dispatch({ type: 'yoga-adm/user/logout' });
      history.push('/login');
    }

    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userData.email.trim() || !userData.password.trim()) {
      setErrorMessage('Preencha os campos corretamente');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    try {
      const response = await api.post('/sessions', userData);
      dispatch({ type: 'yoga-adm/user/get-user-data', payload: response.data });
      history.push('/');
    } catch (error) {
      const message = error.response.data?.message;
      setErrorMessage(message);
    }

    setShowLoadModal(false);
  };

  const handleForgotMyPassword = async (event) => {
    if (!userData.email.trim() || !emailValidation(userData.email)) {
      setErrorMessage(
        'Preencha o seu e-mail corretamente para que possamos enviar o e-mail de recuperação de senha.',
      );
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    try {
      const response = await api.post('/password/forgot', {
        email: userData.email,
      });
      setShowMessageModal(true);
    } catch (error) {
      const message = error.response.data?.message;
      setErrorMessage(message);
    }

    setShowLoadModal(false);
  };

  const handleStudentGirassol = async (event) => {
    if (!userData.email.trim() || !emailValidation(userData.email)) {
      setErrorMessage(
        'Preencha o campo de e-mail com o seu e-mail usado na plataforma do Girassol.',
      );
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    try {
      const response = await api.post('/girassol-user/send-email', {
        email: userData.email,
      });
      setShowMessageModal(true);
    } catch (error) {
      const message = error.response.data?.message;
      setErrorMessage(message);
    }

    setShowLoadModal(false);
  };

  function renderTooltip() {
    if (errorMessage) {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Fique atento ao seu email"
          description="Enviaremos para o seu email o link de redefinição de senha, certifique-se de procurar também na caixa de spam, caso não ache na caixa de entrada"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  function emailValidation(email) {
    let usuario = email.substring(0, email.indexOf('@'));
    let dominio = email.substring(email.indexOf('@') + 1, email.length);
    if (
      usuario.length >= 1 &&
      dominio.length >= 3 &&
      usuario.search('@') == -1 &&
      dominio.search('@') == -1 &&
      usuario.search(' ') == -1 &&
      dominio.search(' ') == -1 &&
      dominio.search('.') != -1 &&
      dominio.indexOf('.') >= 1 &&
      dominio.lastIndexOf('.') < dominio.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  const removeWhiteSpace = (string) => {
    return string.replace(/\s/g, '');
  };

  return (
    <Container>
      <Header />
      <Row>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <label>Faça Login</label>
          <Input
            type="email"
            placeholder="Seu email"
            required
            value={userData.email}
            onInput={(event) =>
              setUserData({
                ...userData,
                email: removeWhiteSpace(event.target.value),
              })
            }
          />
          <Input
            type="password"
            placeholder="Sua Senha"
            required
            onInput={(event) =>
              setUserData({ ...userData, password: event.target.value })
            }
          />
          <span onClick={() => handleForgotMyPassword()}>
            Esqueci minha senha
          </span>
          {renderTooltip()}
          <Button>Entrar</Button>
          <SunflowerStudent>
            <span>É aluno Girassol? Digite seu e-mail e</span>
            <span onClick={() => handleStudentGirassol()}>Clique aqui</span>
          </SunflowerStudent>
          <Row>
            <span>Ainda não tem uma conta? </span>
            <span>
              <Link to="/escolha-um-plano">Cadastre-se</Link>
            </span>
          </Row>
        </Form>
        <Logo>
          <GradientBackground />
          <img src={LogoWithLegend} alt="Logo do Yoga CO." />
        </Logo>
      </Row>
      <Footer />
      <LoadModal show={showLoadModal} />
      {renderMessageModal()}
    </Container>
  );
};

export default Login;
