import styled from 'styled-components';

export const Container = styled.section`
  &.hero {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2em 15px;
    min-height: calc(90vh - 4em);

    background: var(--green-gradient);

    .hero__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 15px;

      width: 100%;
      max-width: 1300px;
    }

    .hero__caption {
      position: relative;
      z-index: 1;
      margin-right: -94%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      width: 100%;
      min-width: 260px;
      max-width: 500px;

      min-height: 330px;

      animation: hero_slide 0.7s ease-in-out forwards;
      animation-delay: 2.5s;
    }

    .hero__title {
      display: inline-flex;
      flex-direction: column;

      font-size: 2.5rem;
      color: var(--green24);
      text-align: center;
      font-family: 'Bartolomeo-Regular';

      background: #fff;

      .hero__title-b {
        opacity: 0;

        font-size: 4rem;
        font-weight: bold;

        animation: rendering 0.3s ease-in forwards;
        animation-delay: 0.3s;
      }

      .hero__title-b--second {
        opacity: 0;

        font-size: 4rem;
        font-weight: bold;

        animation: rendering 0.4s ease-in forwards;
        animation-delay: 0.6s;
      }
    }

    .hero__title-message {
      opacity: 0;

      animation: rendering 0.5s ease-in forwards;
      animation-delay: 1s;
    }

    .hero__description {
      opacity: 0;

      color: var(--dark06);
      font-size: 1.1rem;
      text-align: center;
      text-transform: uppercase;
      font-family: 'Aller-Light';
      letter-spacing: 2px;

      animation: rendering 0.5s ease-in forwards;
      animation-delay: 3s;
    }

    .hero__video-wrapper {
      display: flex;
      align-items: center;

      width: 100%;
      max-width: 700px;
    }

    .hero__video {
      opacity: 0;

      position: relative;
      width: 100%;

      padding-top: 56.25%;
      overflow: hidden;

      animation: rendering 0.5s ease-in forwards;
      animation-delay: 3.5s;

      iframe {
        position: absolute;
        inset: 0;

        width: 100%;
        height: 100%;

        border-radius: 8px;
      }
    }

    .primary-anchor {
      opacity: 0;

      max-width: 330px;

      animation: rendering 0.5s ease-in forwards;
      animation-delay: 3.3s;
    }
  }
`;
