import styled, { css } from 'styled-components';

export const Container = styled.section``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

export const BigDiary = styled.img`
  height: 300px;
  margin-left: -200px;

  @media (max-width: 1480px) {
    display: none;
  }
`;

export const Description = styled.div`
  max-width: 500px;
  h1 {
    font-family: 'Bartolomeo-Regular';
    font-size: 6rem;
    color: ${(props) =>
      props.diaryColorChosen === 'purple' || props.diaryColorChosen === 'yellow'
        ? '#fff'
        : '#165011'};
    position: relative;
    width: fit-content;
    span {
      font-family: 'Aller-Bold';
      font-size: 3rem;
      position: absolute;
      left: 35%;
      top: 0px;
    }
  }

  p {
    font-family: 'Aller-Light';
    font-size: 1.4rem;
    color: #5c5c5c;
    margin-top: 40px;
  }

  > img {
    display: none;
    height: 200px;
  }

  @media (max-width: 1480px) {
    > img {
      display: block;
    }
  }

  @media (max-width: 1120px) {
    max-width: 100%;
    text-align: center;

    h1 {
      margin: 0 auto;
    }

    > img {
      display: none;
    }
  }
`;

export const ChooseDiaryColor = styled.div`
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  text-align: center;
  max-width: 1400px;
  height: fit-content;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 1.8rem;
    color: #165011;
  }

  > div:nth-of-type(2) {
    font-family: 'Aller-Light';
    font-size: 1.4rem;
    color: #5c5c5c;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.8rem;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1120px) {
    margin-top: 40px;
  }

  @media (max-width: 610px) {
    padding: 20px;
  }
`;

export const ListOfDiaries = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px 0px;
`;

export const Diary = styled.img`
  width: 110px;
  transition: all 1s;
  cursor: pointer;

  ${(props) =>
    props.color === props.diaryColorChosen &&
    css`
      transform: scale(1.2);
    `}

  @media (max-width: 460px) {
    width: auto;
    height: 120px;
  }

  @media (max-width: 340px) {
    height: 100px;
  }
`;

export const GreenBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
export const YellowBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(250, 207, 88, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const PurpleBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(132, 48, 202, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
  margin-bottom: 20px;
`;
