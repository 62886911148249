import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 10;
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  transition: all 1s;
  opacity: ${(props) => (props.showComponent ? 1 : 0)};
  pointer-events: ${(props) => (props.showComponent ? 'auto' : 'none')};

  > p {
    font-family: 'Aller-Light';
    font-size: 1.2rem;
    color: #313131;
    text-align: center;
  }

  > button {
    display: block;
    font-family: 'Aller-Regular';
    color: #fff;
    background-color: #a16ccd;
    border: 0px;
    padding: 6px 30px;
    border-radius: 6px;
    margin: 0px auto;
  }

  @media (max-width: 1024px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 444px) {
    width: 90%;
  }
`;
