import styled from 'styled-components';

export const Container = styled.section`
  &.community-rating {
    width: 100%;
    height: fit-content;

    padding: 2em 0;

    position: relative;

    background: rgb(132, 48, 202);
    background: var(--purple-gradient);

    &.community-rating--green-background {
      background: var(--green-gradient);

      .slick-active {
        .community-rating__slider-dot {
          background-color: var(--green24);
        }
      }
    }

    .community-rating__icon {
      width: 3.855rem;
      height: 3.13625rem;

      margin: 0 auto;
    }

    .community-rating__title {
      width: fit-content;
      margin: 1rem auto 0;

      display: block;

      font-family: 'Aller-Light';
      font-size: 2.3125rem;
      text-align: center;
      text-transform: uppercase;
      color: var(--dark70);
    }

    .community-rating__b-title {
      width: fit-content;
      margin: 0 auto;

      display: block;

      font-family: 'Bartolomeo-regular';
      font-weight: normal;
      font-size: 4.3125rem;
      text-align: center;
      color: var(--green24);
      transform: rotate(-2deg);
      text-transform: lowercase;
    }

    .community-rating__slider-item {
      width: 100%;
      min-height: fit-content;
      height: 15.875rem;
      margin: 3.74375rem 0 2.9375rem;
    }

    .slick-dots {
      margin: 2rem 0;
    }

    .community-rating__slider-dot {
      width: 1.3125rem;
      height: 1.3125rem;

      border-radius: 50%;
      background-color: #fff;

      box-shadow: 0 3px 6px rgb(0 0 0/16%);
    }

    .slick-active {
      .community-rating__slider-dot {
        width: 1.3125rem;
        height: 1.3125rem;

        border-radius: 50%;
        background-color: var(--purpleA1);
      }
    }

    .community-rating__slider-article {
      width: 97%;
      max-width: 60.5rem;
      min-height: 100%;
      height: fit-content;
      margin: 0 auto;
      padding: 1.6rem 0;

      display: flex;
      align-items: center;

      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);

      border-radius: 8px;
    }

    .community-rating__slider-article__image-wrapper {
      flex: 3;
      height: 10.6125rem;
    }

    .community-rating__slider-article__image {
      width: 85%;
      height: auto;
      margin: 0 auto;

      aspect-ratio: 1 / 1;

      object-fit: cover;
      border-radius: 50%;
    }

    .community-rating__slider-article__content {
      flex: 10;
      height: fit-content;
      /* margin-top: 0.5rem; */
      padding: 0 1em;
    }

    .community-rating__slider-article__user {
      /* width: fit-content;
      margin: 0 auto;

      text-align: center; */
    }

    .community-rating__slider-article__username-container {
    }

    .community-rating__slider-article__username {
      display: inline-block;

      font-family: 'Aller-Bold';
      font-size: 1.5625rem;
      text-transform: uppercase;
      color: var(--green16);
    }

    .community-rating__slider-article__since {
      display: inline-block;

      font-family: 'Aller-Light';
      font-size: 1.3125rem;
      text-transform: uppercase;
      color: var(--green16);
    }

    .community-rating__slider-article__commentary {
      width: 90%;
      max-width: 40.8125rem;
      height: fit-content;

      min-height: 5.875rem;
      margin: 1.5625rem 0;

      font-family: 'Aller-Light';
      font-size: 1.375rem;
      font-weight: lighter;
      text-transform: uppercase;
    }

    .primary-anchor {
      margin: 2em auto 15px;
      max-width: 500px !important;
    }

    .secondary-anchor {
      margin: 2em auto 15px;
      max-width: 500px !important;
    }
  }
`;
