import CommunityUser from '../../../assets/images/community-icon.png'

export const items = [
  {
    id: 'CR0002',
    image: CommunityUser,
    name: "Elizabeth S.",
    commentary: "Que aula incrível!! Eu amei!! Amei!! Numa escala de 0 à 100 eu amei 500! Quero mais Hatha assim, tradicional!!! Por favor! e muita Gratidão!! Namasté"
  },
  {
    id: 'CR0003',
    image: CommunityUser,
    name: "Camila P.",
    commentary: "Adorei que eu agendei essa aula, aí veio a notificação do app me avisar!! Aula maravilhosa! Amo Power yoga. Suei aqui 😃 gratidão por mais uma prática! 🙏"
  },
  {
    id: 'CR0004',
    image: CommunityUser,
    name: "Tha E.",
    commentary: "A Yoga Co. é incrível! Conteúdo excelente por preço justo e feito com muito amor ❤"
  },
  {
    id: 'CR0005',
    image: CommunityUser,
    name: "Sandra E.",
    commentary: "Assinei o APP e estou totalmente E N C A N T A D A. Gratidão: comigo, por me proporcionar esses momentos só meus - e a você, por sua didática e simpatia 🙏"
  },
  {
    id: 'CR0006',
    image: CommunityUser,
    name: "Jéssica",
    commentary: "Me sentindo renovada, consigo respirar melhor e pensar com mais clareza, gratidão por tanto 💖 namastê 🙏😘"
  }
]
