import styled from 'styled-components';

export const Container = styled.div`
  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #b3b1b1;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: 'Aller-Light';
    font-size: 2rem;
    color: #a8a9a8;
    margin: 0px 20px;
  }

  > span:nth-of-type(2) {
    font-size: 2.4rem;
    color: #24b424;
    border-style: solid;
    padding: 10px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
  }
`;
