import Check from '../../../assets/icons/check-icon.svg';
import Star from '../../../assets/icons/star-icon.svg';

export const items = [
  {
    icon: Check,
    text: 'Série exclusiva para iniciantes',
  },
  {
    icon: Check,
    text: 'Série sobre respiração yogi',
  },
  {
    icon: Check,
    text: 'Série de introdução à filosofia yogi',
  },
  {
    icon: Check,
    text: 'Série para gestantes',
  },
  {
    icon: Check,
    text: 'Série para co-criação',
  },
  {
    icon: Check,
    text: '+ 16 aulas exclusivas de 60min',
  },
  {
    icon: Check,
    text: '+ 80 episódios de podcast exclusivo',
  },
  {
    icon: Star,
    text: 'Bônus de 6 meditações exclusivas',
  },
  {
    icon: Star,
    text: '+ de 65h de conteúdo exclusivo para você!',
  },
];
