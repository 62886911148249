import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -140px;

  > div {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #b3b1b1;
    margin: 40px auto;
    text-align: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: 'Aller-Light';
    font-size: 2rem;
    color: #a8a9a8;
    margin: 0px 20px;
  }

  > span:nth-of-type(2) {
    font-size: 2.4rem;
    color: #24b424;
    border-style: solid;
    padding: 10px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
  }
`;

export const ListLessons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const BackgroundSearch = styled.div`
  background-color: #fff;

  > div > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #b3b1b1;
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 40px auto;
`;
