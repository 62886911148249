import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

import { Spinner } from './styles';

const LoadModal = ({ show }) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        <Spinner />
      </Modal.Body>
    </Modal>
  );
};

export default LoadModal;
