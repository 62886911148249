import styled from "styled-components";

export const Container = styled.nav`
  width: 100%;
  max-width: 25.875rem;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .nav__link{
    color: var(--dark06);
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  @media (max-width: 768px){
    display: none;
  }
`
