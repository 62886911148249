import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  width: 100%;
  background-color: #fff;
  transition: all 1s;
  min-height: 435px;
  @media (max-width: 420px) {
    min-height: 360px;
  }
  @media (max-width: 380px) {
    min-height: 332px;
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-top: 40px;
    padding-bottom: 40px;

    @keyframes rendering {
      from {
        transform: scale(0);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    > div.slick-slider {
      animation: rendering 1s ease-in;
      transition: all 1s;
      width: 100%;
    }
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: 'Aller-Light';
  font-size: 1.6rem;
  color: #000;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin: 20px 0px;
`;

export const ExclusiveContent = styled.div`
  width: 280px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #fff;
  padding: 4px;
  background: rgb(126, 197, 84);
  background: linear-gradient(
    90deg,
    rgba(126, 197, 84, 1) 0%,
    rgba(162, 231, 123, 1) 50%
  );
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  > div:nth-of-type(1) {
    font-family: 'Aller-Light';
    font-size: 2.4rem;
    color: #000000;
  }

  > div:nth-of-type(2) {
    font-family: 'Bartolomeo-Regular';
    font-size: 2.8rem;
    color: #24b424;
    margin: 10px 0px 10px 20px;
  }
`;

export const Description = styled.div`
  font-family: 'Aller-Light';
  font-size: 2rem;
  color: #5c5c5c;
`;

export const ContainerSlider = styled.div`
  animation: rendering 1s;
  width: 100%;
  transition: all 1s;
  min-height: 435px;

  > div {
    display: flex;
    margin: 0px auto;
    padding-top: 20px;
    padding-bottom: 20px;

    @keyframes rendering {
      from {
        transform: scale(0);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    div.slick-slider {
      width: 800px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      > div.slick-list {
        width: 100%;
      }
    }
  }

  @media (max-width: 1220px) {
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    > div {
      div.slick-slider {
        width: 600px;
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      div.slick-slider {
        width: 400px;
      }
    }
  }

  @media (max-width: 420px) {
    min-height: 360px;
    > div {
      div.slick-slider {
        width: 300px;
      }
    }
  }
  @media (max-width: 380px) {
    min-height: 332px;
  }
`;

export const TextMostRecentEpisodes = styled.div`
  font-family: 'Aller-Light';
  font-size: 1.8rem;
`;

export const ContainerLatestSeries = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;
`;
