import styled from 'styled-components';

export const Container = styled.section`
  position: relative;

  > div {
    > button {
      font-size: 1.8rem;
      margin: 60px auto 0px auto;
      width: 600px;
    }

    > img {
      display: block;
      margin: 40px auto 0px auto;
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    text-align: center;

    > div > div:nth-of-type(4) {
      justify-content: center;
    }
  }

  @media (max-width: 766px) {
    > div > button {
      width: 100%;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 300px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const FreeTrialMessage = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.3rem;
  color: #165011;
  padding: 10px;
  max-width: 280px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  background-color: #fff;
  border-radius: 40px;
  text-align: center;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  display: flex;

  img {
    height: 130px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    span {
      display: block;
    }

    span:nth-of-type(1) {
      font-family: 'Bartolomeo-Regular';
      font-size: 3rem;
      color: #165011;
    }

    span:nth-of-type(2) {
      font-family: 'Aquawax-Regular';
      font-size: 2rem;
      color: #707070;
    }
  }
`;

export const Description = styled.div`
  margin-top: 20px;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    color: #9a9a9a;
    margin-top: 40px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    > div:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
`;

export const Benefits = styled.div`
  display: flex;
  margin: 10px 0px;

  > div {
    display: flex;
    align-items: center;

    > img {
      width: 20px;
    }

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.4rem;
      color: #165011;
      margin: 0px 10px;
    }
  }

  @media (max-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: 640px) {
    margin: 0px auto;
    flex-direction: column;
    align-items: flex-start;
    width: 320px;

    > div {
      margin: 10px 0px;
    }
  }
`;

export const Gift = styled.div`
  margin-top: 20px;
  > div:nth-of-type(1) {
    font-family: 'Aller-Light';
    font-size: 1.8rem;
    color: #5c5c5c;
  }
`;

export const GiftForFriend = styled.button`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #61e171;
  border-radius: 10px;
  outline: 0;
  background-color: #fff;
  padding: 20px;
  height: 120px;
  margin-top: 20px;

  > img {
    width: 80px;
    margin-right: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Bartolomeo-Regular';
    font-size: 2.4rem;
    color: #165011;

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.8rem;
      color: #707070;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
    height: 220px;
    padding: 10px;

    > img {
      margin-right: 0px;
    }
  }
`;

export const IWasPresented = styled.div`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #61e171;
  border-radius: 10px;
  outline: 0;
  background-color: #fff;
  padding: 20px;
  height: 120px;
  margin-top: 20px;
  margin-left: 20px;

  > img {
    width: 80px;
    margin-right: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Aller-Regular';
    font-size: 1.8rem;
    color: #707070;

    > div {
      > div {
        margin: 6px 0px 0px 0px;
        width: 200px;
        height: 40px;
      }
      > button {
        font-family: 'Aller-Bold';
        font-size: 1.2rem;
        color: #fff;
        width: 40px;
        height: 40px;
        background-color: #7fc655;
        border-radius: 10px;
        border: 0px;
        margin-left: 20px;
        margin-top: 6px;
        outline: 0;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 766px) {
    width: 100%;
    justify-content: center;
    margin-left: 0px;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
    height: 220px;
    padding: 10px;

    > img {
      margin-right: 0px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-family: 'Aller-Light';
  font-size: 1.6rem;
  color: #5c5c5c;
  margin-top: 20px;
`;

export const TextListPlans = styled.p`
  font-family: 'Aller-Bold';
  font-size: 1.8rem;
  color: #165011;
  margin-bottom: -10px;
`;
