import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 4.83125rem 0 4.9625rem;

  background: rgb(132, 48, 202);
  background: var(--green-gradient);

  .recorded-content__title {
    width: 90%;
    max-width: 57.5625rem;
    margin: 0 auto;

    color: var(--dark70);
    text-align: center;
    text-transform: uppercase;
    font-family: 'Aller-Light';
    font-size: 1.875rem;
    letter-spacing: 1.5px;
  }

  .recorded-content__title-b {
    color: var(--green24);
    text-transform: lowercase;
    font-family: 'Bartolomeo-Regular';
    font-weight: normal;
    font-size: 4.8125rem;
  }

  .recorded-content__slider {
    width: 100%;
    height: fit-content;
    margin: 3.83125rem auto 0;

    position: relative;
  }

  .recorded-content__slider-item {
    width: 27.47625rem;
    margin-bottom: 0.4rem;

    border: 0;
    border-radius: 8px;

    outline: none;

    aspect-ratio: 560 / 315;
    transform: scale(0.75);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .slick-current {
    .recorded-content__slider-item {
      position: relative;
      z-index: 999 !important;

      transform: scale(1.1);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);

      border-radius: 8px;
    }
  }

  .slick-arrow {
    width: 1.078125rem;

    position: absolute;
    z-index: 10;

    &:before {
      display: none;
    }
  }

  .slick-prev {
    left: 5.2875rem;
  }

  .slick-next {
    right: 5.2875rem;
  }

  .primary-anchor {
    max-width: 540px;
    margin: 2em auto;
  }

  iframe {
    border-radius: 8px;
  }
`;
