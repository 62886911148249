import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;

  @media (max-width: 560px) {
    padding: 20px 10px;
  }
`;
