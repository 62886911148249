import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import Content from '../../components/Content';
import ListPlans from '../../components/ListPlans';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import check from '../../assets/changePlan/check.png';
import backIcon from '../../assets/choosePlan/backIcon.png';
import gift from '../../assets/giftFriend/gift.png';

import {
  Container,
  GradientBackground,
  FreeTrialMessage,
  Title,
  Description,
  Benefits,
  Gift,
  GiftForFriend,
  IWasPresented,
  Row,
  Tooltip,
  SubTitle,
  TextListPlans,
} from './styles';

const GiftFriend = () => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const [errorMensage, setErrorMensage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('YogaCO@ChoosedPlan');
  }, []);

  const handleClickButton = () => {
    const plan = localStorage.getItem('YogaCO@ChoosedPlan');

    if (plan === null || plan === '') {
      setErrorMensage('Escolha um plano para poder prosseguir');
    } else if (
      user.id === undefined ||
      user.accessToken === undefined ||
      user.externalID === undefined ||
      user.id === '' ||
      user.accessToken === '' ||
      user.externalID === ''
    ) {
      history.push('/login');
    } else {
      history.push('/pagamento-presenteie-um-amigo');
    }
  };

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  return (
    <Container>
      <GradientBackground />
      <Header />
      <Content>
        <Title>
          <img src={gift} alt="Ícone de um presente" />
          <div>
            <span>Presenteie</span>
            <span>UM AMIGO</span>
          </div>
        </Title>

        <SubTitle>
          Compartilhe o código do Yoga Co. que geraremos para você aqui em baixo
          e presenteie alguém querido com um dos nossos planos anual ou mensal
        </SubTitle>

        <TextListPlans>Qual plano deseja presentear?</TextListPlans>

        <ListPlans />

        <Description>
          <Benefits>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ de 300 aulas do canal da Pri</span>
            </div>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ de 50 aulas exclusivas</span>
            </div>
          </Benefits>

          <Benefits>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ 30 Podcasts</span>
            </div>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ 10 meditações</span>
            </div>
          </Benefits>
        </Description>

        {renderErrorMensage()}
        <Button onClick={() => handleClickButton()}>Presentear Plano</Button>
        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
      </Content>
      <Footer />
    </Container>
  );
};

export default GiftFriend;
