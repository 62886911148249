import styled from 'styled-components';

export const Container = styled.button`
  &.secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    width: 100%;
    max-width: 500px;

    padding: 0px 10px;
    margin: 15px auto;

    background-color: var(--purpleA1);
    box-shadow: 0px 0px 6px rgb(0 0 0 /16%);
    border-radius: 16px;
    border: 0px;

    outline: 0;
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #fff;
    cursor: pointer;

    transition: all 0.5s;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const Anchor = styled.a`
  &.secondary-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    width: 100%;
    max-width: 500px;

    padding: 0px 10px;
    margin: 15px auto;

    background-color: var(--purpleA1);

    box-shadow: 0px 0px 6px rgb(0 0 0 /16%);
    border-radius: 16px;

    outline: 0;
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #fff !important;
    cursor: pointer;

    transition: all 0.5s;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const IconLeft = styled.img`
  margin-right: 10px;
`;
