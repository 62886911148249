import * as Types from './types';

const INITIAL_STATE = {
  id: '',
  accessToken: '',
  refreshToken: '',
  externalID: '',
  avatar_url: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.GET_USER_DATA:
      return {
        id: payload.user.id,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        externalID: payload.user.external_id,
        avatar_url: payload.user.avatar_url,
      };
    case Types.GET_USER_PROFILE_PHOTO:
      return {
        ...state,
        avatar_url: payload.avatar_url,
      };
    case Types.GET_NEW_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      };
    case Types.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
