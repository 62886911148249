import styled from 'styled-components';

export const CardContainer = styled.div`
  font-family: 'Aller-Regular';
  display: flex;
  margin: 2em 0;
  animation: rendering 0.5s;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Thumbnail = styled.div`
  img {
    width: 400px;
    border-radius: 6px;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    margin: 0 auto;
  }

  @media (max-width: 460px) {
    img {
      width: 100%;
    }
  }
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 2%;
  h1 {
    font-size: 2rem;
    color: #165011;
    cursor: pointer;
  }
  .ant-progress {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .ant-progress-outer {
      padding-right: 0px !important;
    }
    .ant-progress-inner {
      border-radius: 0;
      background-color: #eae8e8;
      .ant-progress-bg {
        height: 5px;
        border-radius: 0;
        background-color: #61e171;
      }
    }
    .ant-progress-text {
      color: #b3b1b1;
      margin-left: 0;
      font-size: 1.6rem;
      padding: 15px 0;
    }
  }
`;

export const Info = styled.div`
  b {
    display: flex;
    img {
      margin-right: 15px;
      width: 50px;
      height: 50px;
    }
  }
  span {
    img {
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
    font-size: 1.6rem;
    color: #b3b1b1;
  }

  h1 {
    margin-left: 20px;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    b {
      img {
        display: none;
      }

      h1 {
        width: 100%;
        margin-left: 0px;
      }

      > div:nth-of-type(1) {
        display: none;
      }
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  img {
    cursor: pointer;
    margin: 0 30px 50px;
    width: 50px;
    height: auto;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    margin: 10px 0px 20px 0px;

    img {
      height: 30px;
    }

    img:nth-of-type(1) {
      margin-right: 20px;
    }

    img:nth-of-type(2) {
      margin-right: 0px;
    }
  }
`;
