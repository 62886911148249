import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Input from '../../components/Input';
import MyNotes from './components/MyNotes';
import NoAccessModal from '../../components/NoAccessModal';
import MessageModal from '../../components/MessageModal';

import greenDiary from '../../assets/myDiary/green.png';
import yellowDiary from '../../assets/myDiary/yellow.png';
import purpleDiary from '../../assets/myDiary/purple.png';
import heart from '../../assets/home/heart.png';
import searchIcon from '../../assets/home/search.png';

import {
  Container,
  Row,
  AddNote,
  SearchContainer,
  Title,
  PurpleBackground,
  GreenBackground,
  YellowBackground,
} from './styles';

const MyDiary = () => {
  const history = useHistory();
  const idUser = useSelector((state) => state.User.id);
  const [myNotes, setMyNotes] = useState([]);
  const [myNotesSearched, setMyNotesSearched] = useState([]);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState();
  const [diaryColor, setDiaryColor] = useState('');

  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const fetchMyNotes = async () => {
    await api
      .get('/diaries?limit=99999')
      .then((response) => setMyNotes(response.data.diaries))
      .catch((error) => handleError(error));
  };

  const fetchDiaryColor = async () => {
    await api
      .get(`/users/${idUser}`)
      .then((response) => {
        if (response.data.diary_color) {
          setDiaryColor(response.data.diary_color);
        } else {
          history.push('/criar-meu-diario');
          return;
        }
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    if (userHasPlanActive) {
      setErrorMensage(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  const handleSearch = () => {
    const value = document.getElementById('search-diary').value;
    const formattedSearch = formatString(value);

    if (value.trim()) {
      let notes = [];
      for (let note of myNotes) {
        const formattedNote = formatString(note.title);
        if (formattedNote.indexOf(formattedSearch) >= 0) {
          notes.push(note);
        }
      }
      setMyNotesSearched(notes);
    } else {
      setMyNotesSearched([]);
    }
  };

  useEffect(() => {
    fetchDiaryColor();
    fetchMyNotes();
  }, []);

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return <NoAccessModal show={showNoAccessModal} blockedPage={true} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro"
          description={errorMensage}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderBackgroundColor = () => {
    if (diaryColor === 'green') {
      return <GreenBackground />;
    } else if (diaryColor === 'yellow') {
      return <YellowBackground />;
    } else if (diaryColor === 'purple') {
      return <PurpleBackground />;
    }
  };

  const renderIconDiary = () => {
    if (diaryColor === 'green') {
      return <img src={greenDiary} alt="Ícone de adicionar nota no diário" />;
    } else if (diaryColor === 'yellow') {
      return <img src={yellowDiary} alt="Ícone de adicionar nota no diário" />;
    } else if (diaryColor === 'purple') {
      return <img src={purpleDiary} alt="Ícone de adicionar nota no diário" />;
    }
  };

  return (
    <Container>
      {renderBackgroundColor()}
      <Header />
      <Content>
        <IoMdArrowBack
          onClick={() => history.goBack()}
          cursor="pointer"
          size={30}
          color="#fff"
        />
        <Row>
          <AddNote
            onClick={() => history.push('/editar-meu-diario')}
            diaryColor={diaryColor}
          >
            {renderIconDiary()}
            <div>+</div>
          </AddNote>
          <SearchContainer>
            <Title diaryColor={diaryColor}>
              <span>meu</span>Diário
            </Title>
            <Input
              id="search-diary"
              placeholder="Buscar por títulos"
              iconLeft={heart}
              iconRight={searchIcon}
              onClickIconRight={() => handleSearch()}
              onBlur={() => handleSearch()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </SearchContainer>
        </Row>
      </Content>
      <MyNotes myNotes={myNotes} myNotesSearched={myNotesSearched} />
      {renderNoAccessModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default MyDiary;
