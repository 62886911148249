import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  width: 100%;
  background-color: ${(props) =>
    props.type === 'SÉRIES' ? '#f2f4f7' : '#FFF'};
  transition: all 1s;

  > div {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-top: 40px;
    padding-bottom: 40px;

    @keyframes rendering {
      from {
        transform: scale(0);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    > div.slick-slider {
      animation: rendering 1s ease-in;
      transition: all 1s;
      width: 100%;
    }
  }
`;

export const ListCards = styled.div`
  display: flex;
  max-width: 640px;
  overflow: hidden;
`;

export const Card = styled.img`
  width: 300px;
  height: auto;
  margin: 0px 0px;
  border-radius: 20px;
  cursor: pointer;
`;

export const ListCardsWithouSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 0 20px;
  > img {
    margin: 0 20px;
  }

  @media (max-width: 1060px) {
    > img {
      margin: 20px 20px;
    }
  }

  @media (max-width: 720px) {
    > img {
      width: 270px;
    }
    margin: 0px;
  }
`;

export const ExclusiveContent = styled.div`
  width: 280px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: #fff;
  padding: 4px;
  background: rgb(126, 197, 84);
  background: linear-gradient(
    90deg,
    rgba(126, 197, 84, 1) 0%,
    rgba(162, 231, 123, 1) 50%
  );
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  > div:nth-of-type(1) {
    font-family: 'Aller-Light';
    font-size: 2.4rem;
    color: #000000;
  }

  > div:nth-of-type(2) {
    font-family: 'Bartolomeo-Regular';
    font-size: 2.8rem;
    color: #24b424;
    margin: 10px 0px 10px 20px;
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;
