import styled from 'styled-components';

export const Container = styled.div`
  > div {
    width: 620px;
  }

  @media (max-width: 1024px) {
    > div {
      width: 100%;
    }
  }
`;
