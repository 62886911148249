import React, { useState, useEffect } from 'react';
import { Calendar as CalendarComponent } from 'react-calendar';
import Lessons from './components/Lessons';

import './calendar.css';

import { Container, ClassDescription, Hour } from './styles';

const Calendar = ({
  data,
  backgroundColor = '',
  setCurrentDate = () => {},
  showAllAppointments = true,
  showHour = true,
  ...props
}) => {
  const [date, setDate] = useState(new Date());
  const [detailsOfScheduledLessons, setDetailsOfScheduledLessons] = useState(
    [],
  );

  const monthArray = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  );

  function markTheDaysWithClassesScheduledInTheCalendar() {
    for (let item of data) {
      for (let lesson of item.appointments) {
        if (
          showAllAppointments ||
          (!showAllAppointments && lesson.created_by === 'adm')
        ) {
          let date = new Date(lesson.date);
          let childElement = document.querySelector(
            `[aria-label='${date.getDate()} de ${
              monthArray[date.getMonth()]
            } de ${date.getFullYear()}']`,
          );

          const checkIfElementExists = document.querySelector(
            `div#date-${lesson.date.substring(0, 10)}`,
          );
          if (childElement && !checkIfElementExists) {
            let parentElement = childElement.parentNode;
            let circle = document.createElement('div');
            circle.id = 'date-' + lesson.date.substring(0, 10);
            circle.style =
              'background-color: #b100ff; border-radius: 50%; height: 8px; width: 8px; margin: 0 auto;';
            parentElement.append(circle);
          }
        }
      }
    }
  }

  const handleDate = (value) => {
    const selectedDate = new Date(value);

    setDate(value);
    setCurrentDate(value);

    const selectedDateString =
      selectedDate.getFullYear() +
      '/' +
      selectedDate.getMonth() +
      '/' +
      selectedDate.getDate();
    let lessonsSheduled = [];

    for (let item of data) {
      // const appointmentDate = new Date(item.date);
      // const appointmentDateString =
      //   appointmentDate.getFullYear() +
      //   '/' +
      //   appointmentDate.getMonth() +
      //   '/' +
      //   appointmentDate.getDate();
      // if (selectedDateString === appointmentDateString) {

      for (let scheduled of item.appointments) {
        const appointmentDate = new Date(scheduled.date);
        const appointmentDateString =
          appointmentDate.getFullYear() +
          '/' +
          appointmentDate.getMonth() +
          '/' +
          appointmentDate.getDate();

        if (
          showAllAppointments ||
          (!showAllAppointments && scheduled.created_by === 'adm')
        ) {
          if (selectedDateString === appointmentDateString) {
            lessonsSheduled.push(scheduled);
          }
        }
      }
      //break;
      // }
    }
    setDetailsOfScheduledLessons(lessonsSheduled);
  };

  const renderDescriptionLessons = () => {
    if (detailsOfScheduledLessons.length > 0) {
      return <Lessons data={detailsOfScheduledLessons} showHour={showHour} />;
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector(
      '.react-calendar__navigation__prev-button',
    );
    prevButton.onclick = () =>
      setTimeout(function () {
        markTheDaysWithClassesScheduledInTheCalendar();
      }, 100);
    const nextButton = document.querySelector(
      '.react-calendar__navigation__next-button',
    );
    nextButton.onclick = () =>
      setTimeout(function () {
        markTheDaysWithClassesScheduledInTheCalendar();
      }, 100);
  }, [data]);

  useEffect(() => {
    markTheDaysWithClassesScheduledInTheCalendar();
  }, [data]);

  useEffect(() => {
    handleDate(date);
  }, [data]);

  return (
    <Container
      backgroundColor={backgroundColor}
      detailsOfScheduledLessons={detailsOfScheduledLessons}
    >
      <CalendarComponent
        onChange={(value) => handleDate(value)}
        value={date}
        {...props}
      />
      {renderDescriptionLessons()}
    </Container>
  );
};

export default Calendar;
