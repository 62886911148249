import styled from 'styled-components';

export const Content = styled.div``;

export const SeriesSection = styled.div`
  margin: 3em 0;
`;

export const LessonsSection = styled.div`
  margin: 3em 0;
`;

export const PodcastsSection = styled.div`
  margin: 3em 0;
`;

export const PodcastPlaylistSection = styled.div`
  margin: 3em 0;
`;

export const MeditationSection = styled.div`
  margin: 3em 0;
`;
