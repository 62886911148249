import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';
import Logo from './components/Logo';
import Content from '../../components/Content';
import Search from './components/Search';
import Carousel from './components/Carousel';
import FavoriteSeries from './components/FavoriteSeries';
import FavoriteLessons from './components/FavoriteLessons';
import FavoriteMeditations from './components/FavoriteMeditations';
import FavoritePodcasts from './components/FavoritePodcasts';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';

import { Container, Title } from './styles';

const Favorites = () => {
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    series: [],
    lessons: [],
    podcasts: [],
    meditations: [],
    podcast_series: [],
    meditation_series: [],
  });
  const [search, setSearch] = useState('');

  const fetchFavorites = async () => {
    setLoading(true);
    await api
      .get('/favorites')
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  return (
    <Container id="favorites">
      <Header />
      <Logo />
      <Content>
        <Search search={search} setSearch={setSearch} />
        <FavoriteSeries
          series={data.series}
          loading={loading}
          search={search}
        />
        <FavoriteLessons
          lessons={data.lessons}
          pathname="/"
          setShowLoadModal={setShowLoadModal}
          search={search}
        />
        <FavoriteMeditations
          meditations={[...data.meditations, ...data.meditation_series]}
          loading={loading}
          search={search}
        />
        <FavoritePodcasts
          podcasts={[...data.podcasts, ...data.podcast_series]}
          loading={loading}
          search={search}
        />
      </Content>
      <Footer />
      {renderLoadModal()}
    </Container>
  );
};

export default Favorites;
