import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';

import MessageModal from '../MessageModal';
import NoAccessModal from '../NoAccessModal';

import api from '../../services/api';

import { Container } from './styles';

const CheckIfClassCompleted = ({
  completed,
  post_id,
  setShowLoadModal,
  videoFinish = false,
}) => {
  const user = useSelector((state) => state.User);
  const location = useLocation();
  const { pathname } = location;
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoAcessModal, setShowNoAccessModal] = useState(false);

  useEffect(() => {
    setIsCompleted(completed);
  }, [completed]);

  useEffect(() => {
    if (videoFinish) {
      setIsCompleted(true);
    }
  }, [videoFinish]);

  const updateIsCompleted = async () => {
    if (user.id.trim() === '' || user.accessToken.trim() === '') {
      setShowNoAccessModal(true);
      return;
    }

    let route = '';

    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      route = '/episodes';
    } else {
      route = '/lessons';
    }

    setShowLoadModal(true);

    await api
      .post(`${route}/${post_id}/history`, {
        completed: !isCompleted,
      })
      .then((response) => {
        setShowLoadModal(false);
        setIsCompleted(!isCompleted);
      })
      .catch((error) => handleError(error));
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    setShowLoadModal(false);

    if (userHasPlanActive) {
      setError(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  const renderIconFinished = () => {
    if (isCompleted) {
      return <HiCheck color="#fff" size={30} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAcessModal) {
      return (
        <NoAccessModal show={showNoAcessModal} setShow={setShowNoAccessModal} />
      );
    }
  };

  return (
    <>
      <Container
        assistedClass={isCompleted}
        onClick={() => updateIsCompleted()}
      >
        {renderIconFinished()}
      </Container>
      {renderMessageModal()}
      {renderNoAccessModal()}
    </>
  );
};

export default CheckIfClassCompleted;
