import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import PlanCard from '../../components/PlanCard';
import AddCreditCard from './components/AddCreditCard';
import MessageModal from '../../components/MessageModal';
import FormDataCreditCard from './components/FormDataCreditCard';
import LoadModal from '../../components/LoadModal';
import Footer from '../../components/Footer';

import { Container, PlanDescription, PlanContainer } from './styles';

const Payment = () => {
  const history = useHistory();

  const monthArray = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'Maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  );

  const [plan, setPlan] = useState({ id: '', name: '', price: 0 });
  const [plans, setPlans] = useState([]);
  const [showModalPlanMessage, setShowModalPlanMessage] = useState(false);
  const [showFormDataCreditCard, setShowFormDataCreditCard] = useState(false);
  const [showModalSuccessPayment, setShowModalSuccessPayment] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [userHasPaymentOptions, setUserHasPaymentOptions] = useState(false);
  const [paymentOptionChosen, setPaymentOptionChosen] = useState('');
  const [informations, setInformations] = useState({
    number: '',
    name: '',
    duedate: '',
  });

  useEffect(() => {
    loadPlanSelected();
    window.scrollTo(0, 0);
  }, []);

  async function loadPlanSelected() {
    const planID = localStorage.getItem('YogaCO@ChoosedPlan');
    if (planID === null) {
      history.push('/escolha-um-plano');
      return;
    }

    setShowLoadModal(true);

    try {
      const response = await api.get(`/plan-types?isVisible=true`);
      setPlans(response.data);

      let foundPlan = false;
      response.data.map((plan) => {
        if (plan.id === planID) {
          foundPlan = true;
          setPlan(plan);
          if (plan.name === 'Plano Anual') {
            setUserHasPaymentOptions(true);
          }
        }
      });

      if (!foundPlan) {
        history.push('/escolha-um-plano');
      }
    } catch (error) {}

    setShowLoadModal(false);
  }

  function renderPlanContainer() {
    if (plan.name === 'Plano Mensal') {
      return (
        <PlanContainer>
          <PlanCard data={plan} />
          <span
            onClick={() => {
              plans.map((plan) => {
                if (plan.name === 'Plano Anual') {
                  setPlan(plan);
                  setUserHasPaymentOptions(true);
                }
              });

              setShowModalPlanMessage(true);
              setShowFormDataCreditCard(false);
            }}
          >
            Trocar para plano Anual
          </span>
        </PlanContainer>
      );
    }

    return (
      <PlanContainer>
        <PlanCard data={plan} />
        <span
          onClick={() => {
            plans.map((plan) => {
              if (plan.name === 'Plano Mensal') {
                setPlan(plan);
                setUserHasPaymentOptions(false);
              }
            });
            setShowModalPlanMessage(true);
            setShowFormDataCreditCard(false);
          }}
        >
          Trocar para plano Mensal
        </span>
      </PlanContainer>
    );
  }

  function renderPlanDescription() {
    if (!showFormDataCreditCard) {
      let date = new Date();
      date.setDate(date.getDate() + 7);

      if (plan.name === 'Plano Mensal') {
        return (
          <PlanDescription>
            <div>Escolha uma forma de pagamento</div>
            <p>
              Adicione uma forma de pagamento para iniciar o teste gratuito. Não
              haverá cobrança se você cancelar antes do dia {date.getDate()} de{' '}
              {monthArray[date.getMonth()]} de {date.getFullYear()}.
            </p>
            <br />
            <p>
              Não se preocupe: te avisaremos 2 dias antes do seu período de
              teste terminar.
            </p>
            <br />
            <p>
              O teste gratuito de 7 dias é válido somente na primeira
              assinatura.
            </p>
          </PlanDescription>
        );
      }

      return (
        <PlanDescription>
          <div>Escolha uma forma de pagamento</div>
          <p>
            Adicione uma forma de pagamento para iniciar o teste gratuito. Não
            haverá cobrança se você cancelar antes do dia {date.getDate()} de{' '}
            {monthArray[date.getMonth()]} de {date.getFullYear()}.
          </p>
          <br />
          <p>
            Não se preocupe: te avisaremos 2 dias antes do seu período de teste
            terminar.
          </p>
          <br />
          <p>
            O teste gratuito de 7 dias é válido somente na primeira assinatura.
          </p>
        </PlanDescription>
      );
    }
  }

  function renderModalPlanMessage() {
    if (showModalPlanMessage) {
      if (plan.name === 'Plano Mensal') {
        return (
          <MessageModal
            title="Você escolheu o pagamento Mensal."
            description="Você escolheu o Plano Mensal,  este plano é recorrente,
        caso escolha pagar com cartão de crédito, esse pagamento
        será cobrado automaticamente todo mês.

        Mas não se preocupe, você poderá cancelar sua
        assinatura a qualquer momento!"
            showOkButton={true}
            showVisible={showModalPlanMessage}
            setVisible={setShowModalPlanMessage}
          />
        );
      }

      return (
        <MessageModal
          title="Você escolheu o pagamento Anual a vista."
          description="Esta cobrança será anual, ou seja,
      nesta mesma data porém no ano que vem você receberá
      novamente  a cobrança de R$ 299,90 para continuar
      aproveitando nosso conteúdo da Yoga Co.

      Mas não se preocupe, você poderá cancelar sua
      assinatura a qualquer momento!"
          showOkButton={true}
          showVisible={showModalPlanMessage}
          setVisible={setShowModalPlanMessage}
        />
      );
    }
  }

  function handleNumberCreditCard(event) {
    const number = event.target.value;
    if (number.length <= 16) {
      setInformations({
        ...informations,
        number,
      });
    }
  }

  function renderFormDataCreditCard() {
    if (showFormDataCreditCard) {
      return (
        <FormDataCreditCard
          setShowFormDataCreditCard={setShowFormDataCreditCard}
          setShowLoadModal={setShowLoadModal}
          setShowModalSuccessPayment={setShowModalSuccessPayment}
          setInformations={setInformations}
          informations={informations}
          handleNumberCreditCard={handleNumberCreditCard}
          plan={plan}
          paymentOptionChosen={paymentOptionChosen}
          userHasPaymentOptions={userHasPaymentOptions}
        />
      );
    }
  }

  function renderModalSuccessPayment() {
    if (showModalSuccessPayment) {
      return (
        <MessageModal
          type="success"
          iconColor="green"
          title="Prontinho!"
          subtitle="Seu pagamento foi aprovado"
          setVisible={setShowModalSuccessPayment}
        />
      );
    }
  }

  return (
    <Container>
      <Header />

      <Content>
        {renderPlanContainer()}

        {renderPlanDescription()}

        {renderFormDataCreditCard()}

        <AddCreditCard
          plan={plan}
          userHasPaymentOptions={userHasPaymentOptions}
          paymentOptionChosen={paymentOptionChosen}
          setShowModalSuccessPayment={setShowModalSuccessPayment}
          setShowLoadModal={setShowLoadModal}
          setShowFormDataCreditCard={setShowFormDataCreditCard}
          informations={informations}
        />
      </Content>

      <Footer />

      {renderModalPlanMessage()}

      {renderModalSuccessPayment()}

      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Payment;
