import React, { useState, useEffect } from 'react';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import ListCard from './components/ListCard';
import SerieCard from './components/SerieCard';
import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import {
  Container,
  Pagination,
  NextClass,
  ListLessons,
  BackgroundSearch,
  Spinner,
} from './styles';

const ResultsFound = ({
  loadSearch,
  searchedLessons,
  searchedSeries,
  searchedPodcastSeries,
  searchedMeditationSeries,
  searchedPodcasts,
  searchedMeditations,
}) => {
  function renderNotFound() {
    if (loadSearch) {
      return <Spinner />;
    }
    if (
      searchedLessons.length === 0 &&
      searchedSeries.length === 0 &&
      searchedPodcastSeries.length === 0 &&
      searchedMeditationSeries.length === 0 &&
      searchedPodcasts.length === 0 &&
      searchedMeditations.length === 0
    ) {
      return <div>Nenhum resultado encontrado</div>;
    }
  }

  function renderLessons() {
    if (loadSearch) {
      return;
    }

    const listLessons = searchedLessons.map((item) => (
      <ListCard route="/aula" lesson={item} />
    ));
    return (
      searchedLessons.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedLessons.length}{' '}
              {searchedLessons.length === 1
                ? 'Aula encontrada'
                : 'Aulas encontradas'}
            </div>
            <ListLessons>{listLessons}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  function renderSeries() {
    if (loadSearch) {
      return;
    }

    const listSeries = searchedSeries.map((item) => (
      <ListCard route="/serie" lesson={item} />
    ));
    return (
      searchedSeries.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedSeries.length}{' '}
              {searchedSeries.length === 1
                ? 'Série encontrada'
                : 'Série encontradas'}
            </div>
            <ListLessons>{listSeries}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  function renderPodcastSeries() {
    if (loadSearch) {
      return;
    }

    const listSeries = searchedPodcastSeries.map((item) => (
      <ListCard route="/podcast" lesson={item} />
    ));
    return (
      searchedPodcastSeries.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedPodcastSeries.length}{' '}
              {searchedPodcastSeries.length === 1
                ? 'Série de podcast encontrada'
                : 'Séries de podcasts encontradas'}
            </div>
            <ListLessons>{listSeries}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  function renderMeditationSeries() {
    if (loadSearch) {
      return;
    }

    const listSeries = searchedMeditationSeries.map((item) => (
      <ListCard route="/meditacao" lesson={item} />
    ));
    return (
      searchedMeditationSeries.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedMeditationSeries.length}{' '}
              {searchedMeditationSeries.length === 1
                ? 'Série de meditação encontrada'
                : 'Séries de meditações encontradas'}
            </div>
            <ListLessons>{listSeries}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  function renderPodcasts() {
    if (loadSearch) {
      return;
    }

    const listSeries = searchedPodcasts.map((item) => (
      <ListCard route="/podcast/aula" lesson={item} />
    ));
    return (
      searchedPodcasts.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedPodcasts.length}{' '}
              {searchedPodcasts.length === 1
                ? 'Podcast encontrado'
                : 'Podcasts encontrados'}
            </div>
            <ListLessons>{listSeries}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  function renderMeditations() {
    if (loadSearch) {
      return;
    }

    const listSeries = searchedMeditations.map((item) => (
      <ListCard route="meditacao/aula" lesson={item} />
    ));
    return (
      searchedMeditations.length > 0 && (
        <BackgroundSearch>
          <Content>
            <div>
              {searchedMeditations.length}{' '}
              {searchedMeditations.length === 1
                ? 'Meditação encontrada'
                : 'Meditações encontradas'}
            </div>
            <ListLessons>{listSeries}</ListLessons>
          </Content>
        </BackgroundSearch>
      )
    );
  }

  return (
    <Container>
      {renderNotFound()}
      {renderLessons()}
      {renderSeries()}
      {renderPodcasts()}
      {renderMeditations()}
      {renderPodcastSeries()}
      {renderMeditationSeries()}
    </Container>
  );
};

export default ResultsFound;
