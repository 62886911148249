import { useHistory } from 'react-router-dom';
import { Container, Anchor, IconLeft } from './_primaryButton';

export const PrimaryButton = ({ children, iconLeft, href, ...props }) => {
  const history = useHistory();

  function renderIconLeft() {
    if (iconLeft) {
      return <IconLeft src={iconLeft} />;
    }
  }

  return (
    <>
      {!href && (
        <Container className="primary-button" {...props}>
          {renderIconLeft()}
          {children}
        </Container>
      )}

      {href && (
        <Anchor
          onClick={() => history.push(href)}
          className="primary-anchor"
          {...props}
        >
          {renderIconLeft()}
          {children}
        </Anchor>
      )}
    </>
  );
};
