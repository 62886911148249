import { Container, IconLeft } from './styles';

const Button = ({ children, iconLeft, ...props }) => {
  function renderIconLeft() {
    if (iconLeft) {
      return <IconLeft src={iconLeft} />;
    }
  }

  return (
    <Container className="common-button" {...props}>
      {renderIconLeft()}
      {children}
    </Container>
  );
};

export default Button;
