import React, { useState } from 'react';

import Input from '../../../../../../components/Input';
import MessageModal from '../../../../../../components/MessageModal';

import visa from '../../../../../../assets/profile/visa-logo.png';
import mastercard from '../../../../../../assets/profile/mastercard.png';
import elo from '../../../../../../assets/profile/elo.png';
import hipercard from '../../../../../../assets/profile/hipercard.png';

import { Container, Row, AddButton, AddCard, Informations } from './styles';

const Card = ({ informations }) => {
  const [creditCardBannerSelected, setCreditCardBannerSelected] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  function renderCreditCardBanner() {
    const { number } = informations;

    const firstNumber = number[0];
    const firstTwoNumbers = number.substring(0, 2);
    const firstFiveNumbers = number.substring(0, 5);
    const firstSixNumbers = number.substring(0, 6);

    if (firstNumber === '4') {
      if (!creditCardBannerSelected) setCreditCardBannerSelected(true);
      return <img src={visa} alt="Ícone da Visa" />;
    }

    if (
      firstTwoNumbers === '51' ||
      firstTwoNumbers === '52' ||
      firstTwoNumbers === '53' ||
      firstTwoNumbers === '54' ||
      firstTwoNumbers === '55'
    ) {
      if (!creditCardBannerSelected) setCreditCardBannerSelected(true);
      return <img src={mastercard} alt="Ícone da MasterCard" />;
    }

    if (firstFiveNumbers === '20032') {
      if (!creditCardBannerSelected) setCreditCardBannerSelected(true);
      return <img src={elo} alt="Ícone da Elo" />;
    }

    if (firstSixNumbers === '606282') {
      if (!creditCardBannerSelected) setCreditCardBannerSelected(true);
      return <img src={hipercard} alt="Ícone da HiperCard" />;
    }

    if (creditCardBannerSelected) setCreditCardBannerSelected(false);
    return <Input disabled />;
  }

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          type="success"
          title="Prontinho!"
          subtitle="Cartão foi adicionado com sucesso"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  return (
    <Container>
      <Row onClick={() => setShowMessageModal(true)}>
        {/* <AddButton>
          <span>+</span>
        </AddButton>
        <AddCard>Adicionar Cartão</AddCard> */}
      </Row>
      <Informations creditCardBannerSelected={creditCardBannerSelected}>
        <Row>{renderCreditCardBanner()}</Row>
        <Row>
          <Input value={informations.number.substring(0, 4)} disabled />
          <Input value={informations.number.substring(4, 8)} disabled />
          <Input value={informations.number.substring(8, 12)} disabled />
          <Input value={informations.number.substring(12, 16)} disabled />
        </Row>
        <Row>
          <Input value={informations.name} disabled />
          <Input value={informations.duedate} disabled />
        </Row>
      </Informations>
      {renderMessageModal()}
    </Container>
  );
};

export default Card;
