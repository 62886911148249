import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #19b441;
  background-color: ${(props) => (props.checked ? '#19b441' : ' #fff')};
  transition: 0.2s all;
  cursor: pointer;

  img {
    width: 20px;
    display: ${(props) => (props.checked ? 'block' : 'none')};
  }
`;
