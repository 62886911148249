import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AiFillLike } from 'react-icons/ai';
import { FaCommentDots, FaHeart } from 'react-icons/fa';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import socketio from 'socket.io-client';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';

import notificationIcon from '../../assets/notifications/notification-icon.png';

import {
  Container,
  GradientBackground,
  Title,
  ListNotifications,
  Notification,
  UserPhotoContainer,
  IconContainer,
  Informations,
  Time,
  Spinner,
  ShowMore,
} from './styles';

const FrequentlyAskedQuestions = () => {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [showSpinnerLoading, setShowSpinnerLoading] = useState(false);
  const [
    showSpinnerLoadingMoreNotifications,
    setShowSpinnerLoadingMoreNotifications,
  ] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async (addPage = false) => {
    let route;
    if (addPage) {
      setPage(page + 1);
      route = `/notifications?page=${page + 1}&limit=20`;
      setShowSpinnerLoadingMoreNotifications(true);
    } else {
      route = `/notifications?page=${page}&limit=20`;
      setShowSpinnerLoading(true);
    }

    await api
      .get(route)
      .then((res) => {
        setTotal(res.data.total);
        if (addPage) {
          setNotifications([...notifications, ...res.data.results]);
          setShowSpinnerLoadingMoreNotifications(false);
        } else {
          setNotifications(res.data.results);
          setShowSpinnerLoading(false);
        }
      })
      .catch((err) => {
        setShowSpinnerLoading(false);
        setShowSpinnerLoadingMoreNotifications(false);
      });
  };

  const handleClickNotification = (notification) => {
    if (notification.post_type === 'lesson') {
      history.push(`/aula/${notification.post_id}`);
    } else if (notification.episode_type === 'meditation') {
      history.push(`/meditacao/aula/${notification.post_id}`);
    } else {
      history.push(`/podcast/aula/${notification.post_id}`);
    }
  };

  const renderIcon = (notification) => {
    if (notification.type === 'like') {
      if (notification.is_admin) {
        return <FaHeart size={10} />;
      }
      return <AiFillLike size={10} />;
    } else {
      return <FaCommentDots size={10} />;
    }
  };

  const handleMoreNotifications = () => {
    fetchNotifications(true);
  };

  const renderNotifications = () => {
    if (showSpinnerLoading) {
      return <Spinner />;
    }

    return notifications.map((notification) => (
      <Notification
        key={notification.id}
        onClick={() => handleClickNotification(notification)}
      >
        <UserPhotoContainer>
          <img src={notification.avatar_url} alt="Foto de perfil do usuário" />
          <IconContainer>{renderIcon(notification)}</IconContainer>
        </UserPhotoContainer>
        <Informations>
          <span>{notification.name}</span> {notification.content}
        </Informations>
        <Time>
          {formatDistance(new Date(notification.created_at), new Date(), {
            locale: ptBR,
          })}
        </Time>
      </Notification>
    ));
  };

  const renderShowMoreNotifications = () => {
    if (
      notifications.length < total &&
      !showSpinnerLoading &&
      !showSpinnerLoadingMoreNotifications
    ) {
      return (
        <ShowMore onClick={() => handleMoreNotifications()}>
          Carregar mais
        </ShowMore>
      );
    }
  };

  const renderSpinnerMoreNotifications = () => {
    if (showSpinnerLoadingMoreNotifications) {
      return <Spinner />;
    }
  };

  return (
    <Container>
      <GradientBackground />
      <Header />
      <Content>
        <Title>
          <img src={notificationIcon} alt="Ícone de notificações" />
          Notificações
        </Title>
        <ListNotifications>
          {renderNotifications()}
          {renderShowMoreNotifications()}
          {renderSpinnerMoreNotifications()}
        </ListNotifications>
      </Content>
      <Footer />
    </Container>
  );
};

export default FrequentlyAskedQuestions;
