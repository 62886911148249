import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import imgBackground from '../../assets/congratulations/img-background.png';

import { Container, Informations } from './styles';

const Congratulations = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <div>
        <Informations>
          <div>
            <h1>Parabéns</h1>
            <p>
              Nós somos muito gratos por você estar aqui. É um prazer imenso
              poder te guiar nessa jornada e te mostrar o poder que o yoga tem
              de transformar e mudar nossas vidas para melhor.
            </p>
            <span>
              DO MEU CORAÇÃO <br /> PARA O SEU
            </span>
            <span>Yoga Co.</span>
          </div>

          <Button onClick={() => history.push('/')}>Vamos começar!</Button>
        </Informations>
        <img
          src={imgBackground}
          alt="Imagem da Priscila com a camisa do Yoga Co."
        />
      </div>
      <Footer />
    </Container>
  );
};

export default Congratulations;
