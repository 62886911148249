import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SideBar from '../SideBar';
import Content from './components/Content';
import NotificationBell from './components/NotificationBell';

import logo from '../../assets/header/logo.png';
import user from '../../assets/header/user.png';
import logoMobile from '../../assets/header/logo-mobile.png';

import {
  Container,
  MenuIcon,
  Row,
  Options,
  Item,
  Logo,
  Button,
  UserPhoto,
  ContainerOnlyAppearsVersionMobile,
} from './styles';

const Header = ({ showShopLink }) => {
  const history = useHistory();
  const [showSideBar, setShowSideBar] = useState(false);
  const token = useSelector((state) => state.User.accessToken);
  const userPhoto = useSelector((state) => state.User.avatar_url);

  function renderUserPhoto() {
    if (userPhoto === '' || userPhoto === null || userPhoto === undefined) {
      return <UserPhoto src={user} alt="Foto do usuário" />;
    }
    return <UserPhoto src={userPhoto} alt="Foto do usuário" />;
  }

  function renderLoginButton() {
    if (!token) {
      return (
        <Button>
          <Link to="/login">Login, a casa é sua</Link>
        </Button>
      );
    }
  }

  return (
    <Container>
      <SideBar visible={showSideBar} setVisible={setShowSideBar} />
      <Content>
        <Row>
          <MenuIcon onClick={() => setShowSideBar(true)}>
            <div></div>
            <div></div>
            <div></div>
          </MenuIcon>
          <Logo
            onClick={() => history.push('/')}
            src={logo}
            alt="Logo do Yoga CO."
          />
          <ContainerOnlyAppearsVersionMobile onClick={() => history.push('/')}>
            <img src={logoMobile} alt="Logo do Yoga CO." />
            <span>yoga co.</span>
          </ContainerOnlyAppearsVersionMobile>
        </Row>

        <Options>
          <Item>
            <Link to="/sobre-nos">SOBRE NÓS</Link>
          </Item>
          <Item>
            <Link to="/perguntas-frequentes">PERGUNTAS FREQUENTES</Link>
          </Item>
          {showShopLink && (
            <Item>
              <Link to="/escolha-um-plano">LOJA</Link>
            </Item>
          )}
        </Options>
        <Row>
          {renderLoginButton()}
          <NotificationBell />
          <Link to="/perfil">{renderUserPhoto()}</Link>
        </Row>
      </Content>
    </Container>
  );
};

export default Header;
