import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import { Modal } from 'react-bootstrap';
import { TimePicker } from 'antd';
import moment from 'moment';

import api from '../../services/api';

import Calendar from '../Calendar';
import Button from '../Button';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import closeIcon from '../../assets/class/closeIcon.png';

import './modal.css';

import { Content, Form, Title, Row } from './styles';

const Scheduling = ({ type, show, setShow, postId }) => {
  const [appointments, setAppointments] = useState({});
  const [chosenDate, setChosenDate] = useState(new Date());
  const [chosenHour, setChosenHour] = useState('12:00:00');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [titleMessageModal, setTitleMessageModal] = useState('');
  const [typeMessageModal, setTypeMessageModal] = useState('');

  const loadPlaylistOfTheMonth = async () => {
    await api.get('/student-appointments').then((response) => {
      setAppointments(response.data);
    });
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title={titleMessageModal}
          type={typeMessageModal}
          setVisible={setShowMessageModal}
          iconColor="green"
        />
      );
    }
  };

  const renderCalendar = () => {
    if (JSON.stringify(appointments) !== '{}') {
      return (
        <Calendar
          data={appointments}
          backgroundColor="#F2F4F7"
          setCurrentDate={setChosenDate}
        />
      );
    }
  };

  useEffect(() => {
    loadPlaylistOfTheMonth();
  }, []);

  const format = 'HH:mm';

  const handleChangeHour = (value) => {
    const date = new Date(value);

    const hour =
      (date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours()) +
      ':' +
      (date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes()) +
      ':00';
    setChosenHour(hour);
  };

  const formatDate = () => {
    const month = chosenDate.getMonth() + 1;
    const formatedDate =
      chosenDate.getFullYear() +
      '-' +
      (month.toString().length === 1 ? '0' + month : month) +
      '-' +
      (chosenDate.getDate().toString().length === 1
        ? '0' + chosenDate.getDate()
        : chosenDate.getDate()) +
      'T';
    return formatedDate;
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const date = formatDate();

    setShowLoadModal(true);
    let dateFormated = new Date(date + chosenHour);

    await api
      .post('/student-appointments', {
        date: dateFormated.toISOString(),
        post_id: postId,
      })
      .then((response) => {
        setTitleMessageModal(
          type === 'aula'
            ? 'Aula agendada com sucesso!'
            : 'Série agendada com sucesso!',
        );
        setTypeMessageModal('success');
        setShowMessageModal(true);

        setTimeout(function () {
          setShow(false);
        }, 2000);
      })
      .catch((error) => {
        setTitleMessageModal(error.response.data.message);
        setTypeMessageModal('fail');
        setShowMessageModal(true);
      });
    setShowLoadModal(false);
  };

  return (
    <Modal id="scheduling" show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <Content>
          <Title>Escolha o dia em que deseja agendar esta {type}: </Title>
          <Row>
            {renderCalendar()}
            <Form onSubmit={(e) => handleSubmit(e)}>
              <p>Escolha o horário em que a {type} deve ser agendada: </p>
              <TimePicker
                onChange={(value) => handleChangeHour(value)}
                defaultValue={moment('12:00', format)}
                showNow={false}
                placeholder={'Selecione a hora'}
                format={format}
              />
              <Button>Agendar {type}</Button>
            </Form>
          </Row>
          <img
            src={closeIcon}
            alt="Ícone de fechar o modal"
            onClick={() => setShow(false)}
          />
        </Content>
      </Modal.Body>
      {renderLoadModal()}
      {renderMessageModal()}
    </Modal>
  );
};

export default Scheduling;
