import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  list-style: none;

  img {
    height: 30px;
    margin-right: 10px;
  }

  > li:nth-of-type(2) img {
    height: 20px;
  }

  li {
    display: flex;
    align-items: center;
  }

  @media (min-width: 440px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Player = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.6rem;
  color: #165011;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;

  img {
    height: 30px;
    margin-right: 20px;
  }

  @media (max-width: 350px) {
    width: 160px;
  }
`;
