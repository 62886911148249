import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;

  > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1200px) {
    > div:nth-of-type(2) {
      flex-direction: column;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(133, 50, 202, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const Description = styled.div`
  > img {
    height: 60px;
  }

  > div:nth-of-type(1) {
    font-family: 'Bartolomeo-Regular';
    font-size: 4rem;
    color: #2fb72f;
    margin-top: 20px;

    > span {
      font-family: 'Aller-Light';
      font-size: 2.4rem;
      color: #707070;
      margin-left: 10px;
    }
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 2rem;
    color: #5c5c5c;
    margin-top: 20px;
  }

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const TitleCalendar = styled.div`
  font-family: 'Aller-Bold';
  font-size: 2rem;
  width: fit-content;
  padding: 10px 40px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) -50%,
    rgba(159, 86, 220, 1) 100%
  );
  border-radius: 20px;
  color: #fff;
  text-align: center;

  > div {
    font-family: 'Bartolomeo-Regular';
    font-size: 3rem;
    color: #fff;
    margin-top: -30px;
  }

  @media (max-width: 1200px) {
    margin: 0px auto 20px auto;
  }
`;
