import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import MessageModal from '../../../../components/MessageModal';

import { Container, Description, Information, Circle, Hour } from './styles';

const List = ({ data, showHour = true }) => {
  const history = useHistory();
  const [showMessageModal, setShowMessageModal] = useState(false);

  const renderHour = (item) => {
    if (showHour) {
      const date = new Date(item.date);
      return (
        <Hour>
          {date.getHours().toString().length === 1
            ? `0${date.getHours()}`
            : date.getHours()}
          :
          {date.getMinutes().toString().length === 1
            ? `0${date.getMinutes()}`
            : date.getMinutes()}
        </Hour>
      );
    }
  };

  const handleClickDescription = (item) => {
    let is_published;
    let id;
    let { type } = item.post;

    if (type === 'podcast' || type === 'meditation' || type === 'episode') {
      is_published = item.post.episode.is_published;
      id = item.post.episode.id;
    } else if (type === 'lesson') {
      is_published = item.post.lesson.is_published;
      id = item.post.lesson.id;
    } else if (
      type === 'series' ||
      type === 'podcast_series' ||
      type === 'meditation_series'
    ) {
      is_published = item.post.series.is_published;
      id = item.post.series.id;
    }

    if (is_published) {
      let route;
      if (type === 'podcast') {
        route = '/podcast/aula/';
      } else if (type === 'meditation') {
        route = '/meditacao/aula/';
      } else if (type === 'lesson') {
        route = '/aula/';
      } else if (type === 'podcast_series') {
        route = '/podcast/';
      } else if (type === 'meditation_series') {
        route = '/meditacao/';
      } else if (type === 'series') {
        route = '/serie/';
      }

      history.push(`${route}${id}`);
    } else {
      setShowMessageModal(true);
      setTimeout(function () {
        setShowMessageModal(false);
      }, 2000);
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title={`A aula ainda não foi publicada`}
          description={`A aula será publicada no dia e data marcada no calendário.`}
          setVisible={() => {}}
        />
      );
    }
  };

  return (
    <Container>
      {data.map((item) => {
        let lesson = '';

        let { type } = item.post;

        if (type === 'podcast' || type === 'meditation' || type === 'episode') {
          lesson = item.post.episode.name;
        } else if (type === 'lesson') {
          lesson = item.post.lesson.name;
        } else if (
          type === 'series' ||
          type === 'podcast_series' ||
          type === 'meditation_series'
        ) {
          lesson = item.post.series.title;
        }

        return (
          <Description onClick={() => handleClickDescription(item)}>
            <Information>
              <Circle />
              {lesson}
            </Information>
            {renderHour(item)}
            {renderMessageModal()}
          </Description>
        );
      })}
    </Container>
  );
};

export default List;
