import React, { useEffect, useState, memo } from 'react';

import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import { Container, Spinner, TitleContainer, ExclusiveContent } from './styles';

const ExclusiveSeries = ({ exclusiveLessons, showSpinner }) => {
  return (
    <Container>
      <Content>
        <ExclusiveContent>CONTEÚDO EXCLUSIVO</ExclusiveContent>
        <TitleContainer>
          <div>AULAS</div>
          <div>Exclusivas</div>
        </TitleContainer>
        <Slider3D
          route="aula"
          lessons={exclusiveLessons}
          loading={showSpinner}
        />
      </Content>
    </Container>
  );
};

export default memo(ExclusiveSeries);
