import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1366px;
  margin: 0px auto;
  width: 100%;
  padding: 20px;

  @media (max-width: 560px) {
    padding: 20px 10px;
  }
`;
