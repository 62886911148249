import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 5.2875rem 0 3.3875rem;

  position: relative;
  z-index: -1;
  overflow: hidden;

  background: rgb(72, 226, 91);
  background: var(--green-gradient);

  .plan-benefits__background {
    position: absolute;
    bottom: -17rem;
    left: -1rem;
    right: -1rem;
    z-index: -1;
    img {
      width: 100%;
    }
  }

  .plan-benefits__icon {
    width: 3.855rem;
    height: 3.13625rem;
    margin: 0 auto;
  }

  .plan-benefits__title {
    width: 90%;
    max-width: fit-content;
    height: fit-content;
    margin: 1rem auto 0;

    font-size: 2.2rem;
    color: var(--dark70);
    letter-spacing: 1.5px;
  }

  .plan-benefits__subtitle {
    width: 90%;
    max-width: 800px;
    height: fit-content;

    margin: 2.25rem auto 0;

    color: var(--dark70);
    font-size: 1.7rem;
    font-family: 'Aller-Light';
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
  }

  .plan-benefits__card {
    width: 90%;
    max-width: 40rem;
    height: fit-content;
    padding: 1.5rem 0 3.8375rem;
    margin: 4.55rem auto 0;

    background-color: #fff;
    border-radius: 3.375rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .plan-benefits__card__ul {
    width: 85%;
    margin: 0 auto;

    list-style: none;
  }

  .plan-benefits__card__li {
    height: 2.6rem;

    display: flex;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .plan-benefits__card__image {
    width: 2.470625rem;
    height: 2.470625rem;
    margin-right: 0.9625rem;
  }

  .plan-benefits__card__text {
    width: fit-content;
    height: 100%;

    display: block;

    font-family: 'Aller-Light';
    color: var(--green16);
    font-size: 1.75rem;
  }
`;
