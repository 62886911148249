import Question from '../Question';

import { Container } from './styles';

const ListQuestions = ({ data }) => {
  const renderQuestions = () => {
    if (data.length === 0) {
      return <p>Nenhuma pergunta foi encontrada</p>;
    }

    return data.map((item) => (
      <Question title={item.question} answer={item.answer} />
    ));
  };

  return <Container>{renderQuestions()}</Container>;
};

export default ListQuestions;
