import React, { useEffect, useState, memo } from 'react';

import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import { Container, Spinner, TitleContainer, ExclusiveContent } from './styles';

const ExclusiveSeries = ({ exclusiveSeries, showSpinner }) => {
  const withdrawSpecificSeries = (item) => {
    if (item.id !== process.env.REACT_APP_SINGLE_LESSONS_EXCLUSIVES) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Content>
        <ExclusiveContent>CONTEÚDO EXCLUSIVO</ExclusiveContent>
        <TitleContainer>
          <div>SÉRIES</div>
          <div>Exclusivas</div>
        </TitleContainer>
        <Slider3D
          route="serie"
          lessons={exclusiveSeries.filter(withdrawSpecificSeries).slice(0, 19)}
          loading={showSpinner}
        />
      </Content>
    </Container>
  );
};

export default memo(ExclusiveSeries);
