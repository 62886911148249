import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 9998;

  .mobile-nav__nav{
    width: 100%;
    height: fit-content;

    display: flex;

    position: fixed;
    top: 5.4075rem;
    z-index: 2;

    background-color: var(--green6E);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    ${({ show }) => show
      ? `
        animation: fadein 0.3s ease forwards;
      `
      : `
        animation: fadeout 0.3s ease forwards;
      `
    }
  }


  .mobile-nav_unordered-list{
    width: 95%;
    height: 22rem;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    list-style: none;
  }

  .mobile-nav_list-item{
    width: 100%;
    height: fit-content;
    padding: 1rem 0;

    text-align: center;
  }

  .mobile-nav__anchor{
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover{
      color: var(--purpleA1);
    }
  }

  .mobile-nav_background{
    width: 100vw;
    height: 100vh;

    display: block;

    position: fixed;
    top: 16rem;
    z-index: 1;

    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );

    ${({ show }) => show
      ? `
        animation: fadein 0.3s ease forwards;
      `
      : `
        animation: fadeout 0.3s ease forwards;
      `
    }
  }
`
