import { useHistory } from 'react-router-dom';

import numberLessons from '../../../../../../../../assets/favorites/number-lessons.png';
import duration from '../../../../../../../../assets/favorites/duration.png';
import playerIcon from '../../../../../../../../assets/favorites/player-icon.png';

import { Container, Player } from './styles';

const Description = ({ podcastOrMeditation, route }) => {
  const history = useHistory();

  return (
    <>
      {podcastOrMeditation ? (
        <Player onClick={() => history.push(route)}>
          <img src={playerIcon} alt="Ícone de player" />
          Executar
        </Player>
      ) : (
        <Container>
          <li>
            <img src={numberLessons} alt="Ícone do número de aulas" />
            25 aulas
          </li>
          <li>
            <img src={duration} alt="Ícone da duração em horas da série" />
            Duração: 150 horas
          </li>
        </Container>
      )}
    </>
  );
};

export default Description;
