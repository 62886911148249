import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Calendar from '../../components/Calendar';
import List from './components/List';
import Logo from './components/Logo';
import Footer from '../../components/Footer';

import { Container, Title, Events } from './styles';

const MyCalendar = () => {
  const [playlistData, setPlaylistData] = useState({});
  const [lessonScheduledForToday, setLessonScheduledForToday] = useState({});
  const [lessonsScheduledByStudent, setLessonsScheduledByStudent] = useState(
    {},
  );
  const [appointments, setAppointments] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const loadAppointments = async () => {
    await api.get('/student-appointments').then((response) => {
      setAppointments(response.data);
      handleStudentAppointments(response.data);
    });
  };

  const renderPlaylistOfTheMonth = () => {
    if (JSON.stringify(appointments) !== '{}') {
      return <Calendar data={appointments} setCurrentDate={setCurrentDate} />;
    }
  };

  const handleStudentAppointments = async (data) => {
    if (JSON.stringify(data) !== '{}') {
      let studentLessons = [];
      const currentDateString =
        currentDate.getFullYear() +
        '/' +
        currentDate.getMonth() +
        '/' +
        currentDate.getDate();
      data.map((item) => {
        item.appointments.map((appointment) => {
          const date = new Date(appointment.date);
          const appointmentDataString =
            date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
          if (
            currentDateString === appointmentDataString &&
            appointment.created_by === 'student'
          ) {
            studentLessons.push(appointment);
          }
        });
      });
      setLessonsScheduledByStudent(studentLessons);
    }
  };

  const renderStudentAppointments = () => {
    if (JSON.stringify(lessonsScheduledByStudent) !== '{}') {
      return <List data={lessonsScheduledByStudent} />;
    }
  };

  const loadCurrentLessonPlaylist = () => {
    if (JSON.stringify(appointments) !== '{}') {
      let lessonsToday = [];
      const today = new Date();
      const todayString =
        today.getFullYear() + '/' + today.getMonth() + '/' + today.getDate();

      for (let appointment of appointments) {
        const date = new Date(appointment.appointments[0].date);
        const dateString =
          date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
        if (todayString === dateString && appointment.created_by === 'adm') {
          lessonsToday.push(...appointment.appointments);
        }
      }

      setLessonScheduledForToday(lessonsToday);
    }
  };

  const renderCurrentLessonPlaylist = () => {
    if (JSON.stringify(lessonScheduledForToday) !== '{}') {
      return <List data={lessonScheduledForToday} showHour={false} />;
    }
  };

  useEffect(() => {
    loadAppointments();
  }, []);

  useEffect(() => {
    loadCurrentLessonPlaylist();
  }, [appointments]);

  useEffect(() => {
    handleStudentAppointments(appointments);
  }, [currentDate]);

  return (
    <Container>
      <Header />
      <Logo />
      <Content>
        {renderPlaylistOfTheMonth()}
        <Events>
          <Title>Hoje</Title>
          {renderCurrentLessonPlaylist()}
          <Title>Agendados</Title>
          {renderStudentAppointments()}
        </Events>
      </Content>
      <Footer />
    </Container>
  );
};

export default MyCalendar;
