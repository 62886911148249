import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import LoadModal from '../../../../components/LoadModal';

import api from '../../../../services/api';

import editIcon from '../../../../assets/profile/edit.png';

import { Container, Tooltip } from './styles';

const PersonalData = ({ userData, setUserData }) => {
  const idUser = useSelector((state) => state.User.id);

  const [showLoadModal, setShowLoadModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');

  const updateEmailAndNameUser = async (event) => {
    event.preventDefault();

    if (!userData.email.trim() || !userData.name.trim()) {
      setErrorMensage('Preencha os campos corretamente');
      return;
    }

    setErrorMensage('');
    setShowLoadModal(true);

    try {
      const response = await api.put(`/users/${idUser}`, {
        name: userData.name,
        email: userData.email,
      });
      const newName = response.data.name;
      const newEmail = response.data.email;
      setUserData({ ...userData, name: newName, email: newEmail });
    } catch (error) {
      setErrorMensage(error.response.data.message);
    }

    setShowLoadModal(false);
  };

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  useEffect(() => {}, []);

  return (
    <Container onSubmit={(event) => updateEmailAndNameUser(event)}>
      <Input
        type="email"
        value={userData.email}
        iconRight={editIcon}
        onInput={(event) =>
          setUserData({ ...userData, email: event.target.value })
        }
        required
      />
      <Input
        value={userData.name}
        iconRight={editIcon}
        onInput={(event) =>
          setUserData({ ...userData, name: event.target.value })
        }
        required
      />
      {renderErrorMensage()}
      <Button>Salvar informações</Button>
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default PersonalData;
