import Slider from 'react-slick';

import { items } from './utils/sliderContent';
import { settings } from './utils/sliderSettings';

import Icon from '../../assets/icons/yoga-icon.svg';

import { Container } from './_faq';

export function Faq() {
  return (
    <Container>
      <div className="faq__icon">
        <img src={Icon} alt="Yoga Co." />
      </div>
      <h2 className="faq__title">Perguntas frequentes</h2>

      <div>
        <Slider {...settings}>
          {items.map((item) => (
            <div key={item.id}>
              <div className="faq__slider-item">
                <article className="faq__slider-article">
                  <div className="faq__slider-article__content">
                    <div className="faq__slider-article__question-container">
                      <span className="faq__slider-article__question">
                        {item.question}
                      </span>{' '}
                      <p className="faq__slider-article__commentary">
                        "{item.answer}"
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
}
