import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import api from '../../../../services/api';

import { useHistory } from 'react-router-dom';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';

import { Form, Title, InputRow, Tooltip, Row, BackButton } from './styles';

const FormDataCreditCard = ({
  setShowLoadModal,
  setShowModalSuccessPayment,
  setInformations,
  informations,
  handleNumberCreditCard,
  plan,
  setShowFormDataCreditCard,
  paymentOptionChosen,
  userHasPaymentOptions,
}) => {
  const externalID = useSelector((state) => state.User.externalID);

  const testMode = process.env.REACT_APP_IUGU_TEST_MODE;

  const history = useHistory();
  const [errorMensage, setErrorMensage] = useState('');
  const [recurringPayment, setRecurringPayment] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  const createCardCredit = async () => {
    setShowLoadModal(true);

    const name = informations.name;
    const nameSplited = name.split(' ');
    const primaryName = nameSplited[0];
    let surname = '';
    for (let index = 1; index < nameSplited.length; index++) {
      surname += nameSplited[index];
      if (index < nameSplited.length - 1) {
        surname += ' ';
      }
    }

    try {
      await window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT);

      if (testMode === 'true') {
        console.log('Iugu is currently on test mode');
        window.Iugu.setTestMode(true);
      }

      const cc = window.Iugu.CreditCard(
        informations.number,
        informations.duedate.substring(0, 2),
        informations.duedate.substring(3, 7),
        primaryName,
        surname,
        informations.cvv,
      );

      if (!window.Iugu.utils.validateCreditCardNumber(informations.number)) {
        setErrorMensage('Preencha um número de cartão de crédito válido');
        setShowLoadModal(false);
        return;
      }

      const brand = window.Iugu.utils.getBrandByCreditCardNumber(
        informations.number,
      );

      if (!brand) {
        setErrorMensage('Não suportamos essa bandeira de cartão de crédito');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateCVV(informations.cvv, brand)) {
        setErrorMensage('Preencha um CVV válido');
        setShowLoadModal(false);
        return;
      }

      if (!window.Iugu.utils.validateExpirationString(informations.duedate)) {
        setErrorMensage(
          'Preencha o campo “data de vencimento” no seguinte formato: MM/AAAA',
        );
        setShowLoadModal(false);
        return;
      }

      setErrorMensage('');

      window.Iugu.createPaymentToken(cc, function (response) {
        if (response.id) {
          saveCreditCard(response.id);
        } else {
          const genericError = 'verifique os dados e tente novamente';

          if (response.errors)
            return setErrorMensage(`Cartão inválido, ${genericError}`);

          const errorKeys = Object.keys(response.errors);
          const displayError = errorKeys[0];

          const error = {
            verification_value:
              'verifique se o CVV foi preenchido corretamente',
            number: 'verifique se o número foi preenchido corretamente',
            expiration: 'verifique se a data de expiração está correta',
            last_name: 'verifique se o sobrenome foi preenchido',
            first_name:
              'veirifique se o primeiro nome foi preenchido corretamente',
          };

          setErrorMensage(
            `Cartão inválido, ${error[displayError] || genericError}`,
          );
        }
      });
    } catch (error) {
      setErrorMensage('Erro ao cadastrar seu cartão de crédito');
    } finally {
      setShowLoadModal(false);
    }
  };

  const saveCreditCard = async (tokenPayment) => {
    try {
      const response = await api.post(`/cards`, {
        description: 'Meu cartão de crédito',
        token: tokenPayment,
        // set_as_default: recurringPayment.toString(),
        set_as_default: true,
      });

      if (response) {
        signPlan();
      }
    } catch (error) {
      setShowLoadModal(false);

      setErrorMensage('Erro ao cadastrar seu cartão de crédito');
    }
  };

  const signPlan = async () => {
    try {
      const response = await api.post('/plans', {
        plan_type_id: plan.id,
        payable_with: 'credit_card',
      });

      if (response) {
        setInformations({
          name: '',
          number: '',
          cvv: '',
          duedate: '',
        });

        setTimeout(function () {
          history.push('/parabens');
        }, 3000);
        setShowLoadModal(false);
        setShowModalSuccessPayment(true);
      }
    } catch (error) {
      setShowLoadModal(false);

      setErrorMensage(error.response.data?.message);
    }
  };

  const handleForm = async () => {
    await createCardCredit();
  };

  return (
    <Form>
      <Title>Adicione os dados do seu cartão</Title>
      <Input
        placeholder="Nome do Titular"
        onInput={(event) =>
          setInformations({ ...informations, name: event.target.value })
        }
      />
      <InputRow>
        <Input
          placeholder="Número do cartão"
          type="number"
          value={informations.number}
          onInput={(event) => handleNumberCreditCard(event)}
        />
        <Input
          placeholder="CVV"
          type="number"
          onInput={(event) =>
            setInformations({ ...informations, cvv: event.target.value })
          }
        />
      </InputRow>
      <Input
        placeholder="Mês/Ano de vencimento"
        onInput={(event) =>
          setInformations({ ...informations, duedate: event.target.value })
        }
        maxLength="7"
      />

      {renderErrorMensage()}
      <Button type="button" onClick={() => handleForm()}>
        Finalizar Compra
      </Button>
      <BackButton onClick={() => setShowFormDataCreditCard(false)}>
        Voltar
      </BackButton>
    </Form>
  );
};

export default FormDataCreditCard;
