import Carousel from '../Carousel';

import { Title } from './styles';

const FavoriteMeditations = ({ meditations, loading, search }) => {
  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/g, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/g, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/g, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/g, 'U');
    newValue = newValue.replace(/[Ç]/g, 'C');
    return newValue;
  };

  const renderMeditations = () => {
    let meditationsFiltered = [];
    if (search.trim() !== '') {
      let title;
      let searchFormatted = formatString(search);
      meditations.map((item) => {
        if (item.post.type === 'meditation_series') {
          title = formatString(item.post.podcast_series.title);
        } else {
          title = formatString(item.post.episode.name);
        }

        if (title.indexOf(searchFormatted) >= 0) {
          meditationsFiltered.push(item);
        }
      });
    } else {
      meditationsFiltered = meditations;
    }

    if (meditationsFiltered.length > 0 || loading) {
      return (
        <>
          <Title>Suas meditações favoritas</Title>
          <Carousel
            podcastOrMeditation={true}
            route="meditacao/aula"
            lessons={meditationsFiltered}
            loading={false}
          />
        </>
      );
    }
  };

  return <>{renderMeditations()}</>;
};

export default FavoriteMeditations;
