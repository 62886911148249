import styled from 'styled-components';

export const Container = styled.div`
  width: 17rem;
  height: auto;
  min-height: 23.1252rem;
  aspect-ratio: 20.25 / 27.54625;

  display: flex;
  flex-direction: column;

  border-radius: 0.875rem;
  background-color: transparent;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  ${({ small }) =>
    !small &&
    `
    @media (min-width: 1030px) {
      width: 20.25rem;
      height: auto;
      min-height: 27.54625rem;
    }
  `}

  .annual-plan-card__up {
    flex: 5;

    background: rgb(72, 226, 91);
    background: linear-gradient(
      180deg,
      rgba(72, 226, 91, 1) 33%,
      rgba(72, 226, 91, 0.5550595238095238) 100%
    );
  }

  .annual-plan-card__tag {
    width: 70%;
    max-width: 5.495625rem;
    height: fit-content;
    margin: 0 auto;
    padding: 0.65rem 0 1.14375rem;

    border-radius: 0 0 0.8125rem 0.8125rem;
    background-color: var(--green0F);
    color: #fff;
  }

  .annual-plan-card__tag-title {
    font-size: 0.8125rem;
    text-align: center;
    line-height: 0.75rem;
    color: #fff;
  }

  .annual-plan-card__tag-name {
    margin-top: 0.25625rem;

    font-size: 0.9rem;
    text-align: center;
    transform: rotate(-3deg);

    color: #fff;
  }

  .annual-plan-card__title {
    width: 60%;
    margin: 1rem auto;

    letter-spacing: 0.3rem;
    font-size: 2.4375rem;
    text-transform: uppercase;
    color: #fff;
    line-height: 2.8rem;

    ${({ small }) =>
      !small &&
      `
      @media (min-width: 1030px) {
        margin: 2.9rem auto 0;

        font-size: 1.6875rem;
        line-height: 3rem;
      }
    `}
  }

  .annual-plan-card__title-b {
    letter-spacing: 0;
    font-size: 4.669rem;
    text-transform: lowercase;

    ${({ small }) =>
      !small &&
      `
      @media (min-width: 1030px) {
        font-size: 5.5625rem;
      }
    `}
  }

  .annual-plan-card__down {
    flex: 4;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
  }

  .annual-plan-card__value {
    width: 60%;
    /* margin: 2.434567901234rem auto 0; */

    font-size: 1.2rem;

    ${({ small }) =>
      !small &&
      `
      @media (min-width: 1030px) {
        /* margin: 2.9rem auto 0; */
      }
    `}
  }

  .annual-plan-card__value-strong {
    width: 60%;
    /* margin: 0.4rem auto 0; */

    display: block;

    font-family: 'Aller-Bold';
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  .annual-plan-card__value-strong-b {
    color: var(--green2F);
  }
`;
