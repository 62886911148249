import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 13.83125rem 0 13.29375rem;

  position: relative;

  background: rgb(132, 48, 202);
  background: linear-gradient(
    0deg,
    rgba(132, 48, 202, 0.2) 0%,
    rgba(234, 246, 234, 0.09007352941176472) 25%,
    rgba(255, 252, 252, 0.028448879551820738) 67%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    max-width: 100%;
  }
  .black-friday__background-logo {
    width: 100%;
    max-width: 46.360625rem;
    aspect-ratio: 741.77 / 603.47;

    position: absolute;
    top: 4rem;
    left: -9rem;
    z-index: -1;
    opacity: 0.4;

    @media (min-width: 933px) {
      opacity: 1;
    }
  }

  .black-friday__plan-card {
    position: absolute;
    top: -5rem;
    right: 1rem;
    z-index: 10;

    @media (min-width: 933px) {
      top: 12rem;
      left: 18rem;
    }

    @media (min-width: 1030px) {
      top: 10rem;
      left: 20rem;
    }
  }

  .black-friday__article {
    width: 90%;
    margin: 0 auto;

    position: relative;

    @media (min-width: 933px) {
      width: calc(99% - 50rem);
      max-width: 30.3125rem;
      margin: 0 0 0 45rem;
    }
  }

  .black-friday__article-icon {
    width: 3.076875rem;
    height: 2.50375rem;
    margin: 0 auto;
  }

  .black-friday__article-title {
    width: fit-content;
    height: fit-content;
    margin: 1.3rem auto 0;

    color: var(--green0F);
    text-align: center;
    font-family: 'Aller-Bold';
    font-size: 4.875rem;
    line-height: 4.6875rem;
  }

  .black-friday__article-card {
    width: 100%;
    height: 6rem;

    position: relative;

    color: var(--green24);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    font-size: 4.3125rem;
    line-height: 5rem;
    text-align: center;

    @media (min-width: 933px) {
      min-width: 40.5625rem;
      padding: 0 6rem;

      position: absolute;
      right: 0;

      text-align: end;
    }
  }

  .black-friday__article-paragraph {
    width: 100%;
    max-width: 32.375rem;
    height: fit-content;
    margin: 3.20625rem auto 0;

    font-family: 'Aller-Light';
    font-size: 1.5625rem;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 933px) {
      margin: 8.423125rem auto 0;
    }
  }

  .black-friday__card {
    width: 80%;
    max-width: 44.25rem;
    height: fit-content;
    padding: 1.3125rem 0 1.5625rem;

    position: absolute;
    right: 0;
    bottom: -1.5rem;
    z-index: 10;

    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .black-friday__card-text {
    width: 90%;
    height: fit-content;
    min-height: 4.8125rem;
    margin: 0 auto;

    display: block;

    font-family: 'Aller-Light';
    font-size: 1.875rem;
    line-height: 2.3125rem;

    @media (min-width: 933px) {
      width: 70%;
    }
  }
`;
