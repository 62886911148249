import React, { useState, useEffect } from 'react';

import Content from '../../../../components/Content';
import Card from '../Card';
import { useSelector } from 'react-redux';

import { Container } from './styles';

const NextClass = ({
  currentLesson,
  lessonsOfSerie,
  pathname,
  setShowLoadModal,
  setShowNoAccessModal,
  checkIfUserHasAnActivePlan,
}) => {
  const serie = useSelector((state) => state.Serie);

  const [nextLesson, setNextLesson] = useState({});

  const loadNextClass = () => {
    let nextLesson = false;
    let haveNextLesson = false;
    lessonsOfSerie.map((item) => {
      if (nextLesson) {
        setNextLesson(item);
        nextLesson = false;
        haveNextLesson = true;
      }

      if (item.id === currentLesson.id) {
        nextLesson = true;
      }
    });

    if (!haveNextLesson) {
      setNextLesson({});
    }
  };

  useEffect(() => {
    loadNextClass();
  }, [currentLesson, lessonsOfSerie]);

  function renderNextClass() {
    if (JSON.stringify(nextLesson) !== '{}') {
      return (
        <Container>
          <Content>
            <div>A seguir</div>
            <Card
              lesson={nextLesson}
              pathname={pathname}
              setShowLoadModal={setShowLoadModal}
              setShowNoAccessModal={setShowNoAccessModal}
              checkIfUserHasAnActivePlan={checkIfUserHasAnActivePlan}
            />
          </Content>
        </Container>
      );
    }
  }

  return <>{renderNextClass()}</>;
};

export default NextClass;
