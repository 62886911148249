import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const Card = styled(Skeleton)`
  width: 300px;
  height: 200px;
  margin: 0px 0px;
  border-radius: 20px;
  cursor: pointer;
`;

export const ListCardsWithouSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 0 20px;
  > img {
    margin: 0 20px;
  }

  @media (max-width: 1060px) {
    > img {
      margin: 20px 20px;
    }
  }

  @media (max-width: 720px) {
    > img {
      width: 270px;
    }
    margin: 0px;
  }
`;
