import React, { useState, useEffect } from 'react';
import { Route as ReactDOMRoute, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { decode } from 'jsonwebtoken';
import { isBefore } from 'date-fns';
import refreshToken from '../utils/refreshToken';

const Route = ({
  isPrivateAndPublic = false,
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const user = useSelector((state) => state.User);
  const [renderRoute, setRenderRoute] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const verifyAccessToken = async () => {
    setRenderRoute(false);
    if (user.accessToken) {
      const { exp } = decode(user.accessToken);

      const currentDate = new Date();
      let expirationDate = new Date(exp * 1000);
      if (isBefore(expirationDate, currentDate)) {
        await refreshToken();
      }

      setRenderRoute(true);
    } else {
      setRenderRoute(true);
    }
  };

  useEffect(() => {
    verifyAccessToken();
  }, [rest.path]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivateAndPublic) {
          return renderRoute && <Component />;
        }

        if (
          isPrivate ===
          (user.id !== undefined &&
            user.accessToken !== undefined &&
            user.externalID !== undefined &&
            user.id !== '' &&
            user.accessToken !== '' &&
            user.externalID !== '')
        ) {
          return renderRoute && <Component />;
        }

        return (
          renderRoute && (
            <Redirect
              to={{
                pathname: isPrivate ? '/login' : '/home',
              }}
            />
          )
        );
      }}
    />
  );
};

export default Route;
