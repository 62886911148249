import CommunityUser from '../../../assets/images/community-icon.png';

export const items = [
  {
    id: 'Q0002',
    question:
      'Tenho curiosidade para saber mais sobre a filosofia yogi, tem algo aqui pra mim? ',
    answer:
      'Aqui você encontra de forma simples vídeos explicando conceitos básicos dessa prática milenar! Além de lista de recomendação de livros.',
  },
  {
    id: 'Q0003',
    question:
      'Quero saber saber se estou respirando certo e os tipos de respiração. A minha respiração pode mesmo mudar o meu dia e a minha vida?',
    answer:
      'Aqui você aprende pranayamas - técnicas de respiração yogi - poderosos que quando aplicados são garantia de mudar a sua vida. Tudo de forma simples e descomplicada na série “Descubra o poder da sua respiração”. ',
  },
  {
    id: 'Q0004',
    question:
      'Queria aulas mais longas com a Pri e com temas diversos. Encontro por aqui?',
    answer:
      'Perfeito! Pois já temos uma coleção de mais de 18 aulas que continua aumentando! Pois  todo mês a Pri adiciona uma NOVA aula de 60min.',
  },
  {
    id: 'Q0005',
    question: 'Posso favoritar aulas e séries?',
    answer:
      'Aqui você pode favoritar e até mesmo agendar as suas aulas favoritas e celebrar o seu progresso.',
  },
  {
    id: 'Q0006',
    question: 'Posso escutar o podcast enquanto faço outras coisas?',
    answer:
      'Com certeza! Escute o podcast lavando louça, indo para o trabalho ou até mesmo verificando o seu whatsapp.',
  },
];
