import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  max-width: 1366px;
  margin: 60px auto;
  width: 100%;
  padding: 20px 40px;

  @media (max-width: 560px) {
    padding: 20px 10px;
  }
`;
