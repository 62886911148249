import React, { useState, useEffect } from 'react';

import SerieCard from './components/SerieCard';
import LessonCard from './components/LessonCard';
import Carousel from './components/Carousel';
import PodcastListCard from './components/PodcastListCard';

import {
  Content,
  SeriesSection,
  LessonsSection,
  PodcastsSection,
  PodcastPlaylistSection,
  MeditationSection,
} from './styles';

const FilteredContent = ({
  filterOption,
  historic,
  setShowLoadModal,
  search,
}) => {
  const seriesNotToShow = [
    process.env.REACT_APP_SINGLE_LESSONS_EXCLUSIVES,
    process.env.REACT_APP_SINGLE_LESSONS_CHANNEL,
  ];

  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  function renderSeriesSection() {
    if (historic.series.length > 0) {
      return (
        <SeriesSection>
          {historic.series.map((serie) => {
            const searched = formatString(search);
            const serieName = formatString(serie.post.series.title);
            if (
              (filterOption === 'Concluídos' && !serie.completed) ||
              (search.trim() !== '' && serieName.indexOf(searched) < 0) ||
              seriesNotToShow.includes(serie.post.series.id)
            ) {
              return;
            }
            return <SerieCard serie={serie} />;
          })}
        </SeriesSection>
      );
    }
  }

  function renderLessonSection() {
    if (historic.lessons.length > 0) {
      return (
        <LessonsSection>
          {historic.lessons.map((lesson) => {
            const searched = formatString(search);
            const lessonName = formatString(lesson.post.lesson.name);

            if (
              (filterOption === 'Concluídos' && !lesson.completed) ||
              (search.trim() !== '' && lessonName.indexOf(searched) < 0)
            ) {
              return;
            }
            return (
              <LessonCard lesson={lesson} setShowLoadModal={setShowLoadModal} />
            );
          })}
        </LessonsSection>
      );
    }
  }

  function renderPodcastSection() {
    if (historic.podcasts.length > 0) {
      return (
        <PodcastsSection>
          <Carousel
            podcastOrMeditation={true}
            route="podcast/aula"
            lessons={historic.podcasts}
            loading={false}
            type="episode"
            search={search}
          />
        </PodcastsSection>
      );
    }
  }

  function renderPodcastPlaylistSection() {
    if (historic.podcastSeries.length > 0) {
      return (
        <PodcastPlaylistSection>
          {historic.podcastSeries.map((podcastSerie) => {
            const searched = formatString(search);
            const podcastName = formatString(
              podcastSerie.post.podcast_series.title,
            );

            if (
              (filterOption === 'Concluídos' && !podcastSerie.completed) ||
              (search.trim() !== '' && podcastName.indexOf(searched) < 0)
            ) {
              return;
            }

            return <PodcastListCard dataPodcastList={podcastSerie} />;
          })}
        </PodcastPlaylistSection>
      );
    }
  }

  function renderMeditationSection() {
    if (historic.meditations.length > 0) {
      return (
        <MeditationSection>
          <Carousel
            podcastOrMeditation={true}
            route="meditacao/aula"
            lessons={historic.meditations}
            loading={false}
            type="episode"
            search={search}
          />
        </MeditationSection>
      );
    }
  }

  function renderMeditationPlaylistSection() {
    if (historic.meditationSeries.length > 0) {
      return (
        <PodcastPlaylistSection>
          {historic.meditationSeries.map((meditationSerie) => {
            const searched = formatString(search);
            const meditationName = formatString(
              meditationSerie.post.podcast_series.title,
            );

            if (search.trim() !== '' && meditationName.indexOf(searched) < 0) {
              return;
            }

            return <PodcastListCard dataPodcastList={meditationSerie} />;
          })}
        </PodcastPlaylistSection>
      );
    }
  }

  function renderFilteredOption(option) {
    // if (option === 'Séries') {
    //   return renderSeriesSection();
    // }

    switch (option) {
      case 'Séries':
        return renderSeriesSection();
      case 'Aulas':
        return renderLessonSection();
      case 'Podcast':
        return (
          <>
            {renderPodcastSection()} {renderPodcastPlaylistSection()}
          </>
        );
      case 'Meditações':
        return (
          <>
            {renderMeditationSection()} {renderMeditationPlaylistSection()}
          </>
        );
      default:
        return (
          <>
            {renderSeriesSection()}
            {renderLessonSection()}
            {renderPodcastSection()}
            {renderPodcastPlaylistSection()}
            {renderMeditationSection()}
            {renderMeditationPlaylistSection()}
          </>
        );
    }
  }

  return <Content>{renderFilteredOption(filterOption)}</Content>;
};

export default FilteredContent;
